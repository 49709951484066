import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { isObject, isEmpty, trim } from 'lodash';

import { KEYS } from 'components/utils/keys';
import { isValid as isFieldValid } from 'components/utils/form-utils';
import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';

import * as styled from './styles';

class TagsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typed: '',
      items: props.value,
      focus: false,
    };
  }

  handleKeyUp(event) {
    switch (event.keyCode) {
      case KEYS.ENTER:
      case KEYS.COMMA:
        this.addItem();
        break;
      default:
        break;
    }
  }

  handleKeyPress(event) {
    if (event.nativeEvent.keyCode === KEYS.ENTER) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  updateValue(event) {
    const { max } = this.props;
    const { items } = this.state;

    if (items.length < max) {
      this.setState({ typed: event.target.value });
    }
  }

  addItem() {
    const { max, onChange } = this.props;
    const { typed, items } = this.state;
    const value = trim(typed, ' ,');

    if (items.length < max && !isEmpty(value) && !items.includes(value)) {
      const newItems = [...items, value];
      this.setState({ typed: '', items: newItems });
      onChange(newItems);
    }
  }

  removeItem(i) {
    const { onChange } = this.props;
    const { items } = this.state;
    items.splice(i, 1);

    this.setState({ items });
    onChange(items);
  }

  render() {
    const {
      intl,
      className,
      id,
      required,
      min,
      max,
      placeholder,
      isValid,
      shouldValidate,
    } = this.props;
    const { typed, items, focus } = this.state;

    const placeholderValue =
      (isObject(placeholder) && intl.formatMessage(placeholder)) ||
      placeholder ||
      '';
    const isInputValid = isValid
      ? isFieldValid({
          inputValue: items,
          inputType: 'tags',
          shouldValidate,
          required,
          min,
          max,
        })
      : isValid;

    return (
      <styled.InputContainer
        className={className}
        isValid={isInputValid}
        focus={focus}
      >
        {shouldValidate && !isInputValid && <ValidationErrorIndicator />}

        <styled.TagsList tags={items} onRemove={(i) => this.removeItem(i)} />

        <styled.Input
          id={id}
          value={typed}
          placeholder={placeholderValue}
          onChange={(event) => this.updateValue(event)}
          onKeyUp={(event) => this.handleKeyUp(event)}
          onKeyPress={(event) => this.handleKeyPress(event)}
          onFocus={() => this.setState({ focus: true })}
          onBlur={() => this.setState({ focus: false })}
        />
      </styled.InputContainer>
    );
  }
}

TagsComponent.propTypes = {
  intl: intlShape,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.array,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  isValid: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  onChange: PropTypes.func,
};

TagsComponent.defaultProps = {
  isValid: true,
};

export const Tags = injectIntl(TagsComponent);
