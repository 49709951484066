import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;

export const FiltersGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > * {
    margin-right: 1rem;
  }
`;
