import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { get } from 'lodash';

import { TableContextHeader } from 'components/structure/TableContextHeader';
import { PremiumFeatureBadge } from 'components/misc/Badge';

import { contextHeaderDefinition } from './utils/contextHeaderDefinition';
import i18n from './utils/i18n';

const currentHeaderDefinition = (
  intl,
  resource,
  filter,
  company,
  jobsCounters
) => {
  const companyName = get(company, 'name');
  const definition = contextHeaderDefinition(intl, resource, filter, {
    companyName,
    counters: jobsCounters,
  });
  const premiumFeatures = get(company, 'subscription_plan.features', {});
  const isPremium =
    definition.premiumFeature && !premiumFeatures[definition.premiumFeature];

  if (isPremium) {
    return {
      title: definition.title,
      icon: definition.icon,
      badges: [<PremiumFeatureBadge header type="feature" />],
      actionButtons: [
        {
          label: intl.formatMessage(i18n.buttonUpgradePro),
          icon: 'ShieldFill',
          color: 'primaryDark',
          path: '/subscription-plan',
        },
      ],
    };
  }

  return definition;
};

const ContextHeaderComponent = (props) => {
  const { intl, resource, filter, company, jobsCounters, children } = props;
  const definition = currentHeaderDefinition(
    intl,
    resource,
    filter,
    company,
    jobsCounters
  );

  return (
    <TableContextHeader
      contextHeaderDefinition={definition}
      menuContext={definition.menuContext}
    >
      {children}
    </TableContextHeader>
  );
};

ContextHeaderComponent.propTypes = {
  intl: intlShape,
  resource: PropTypes.string,
  filter: PropTypes.string,
  company: PropTypes.object,
  jobsCounters: PropTypes.object,
  children: PropTypes.node,
};

const withIntl = injectIntl;

export const ContextHeader = compose(withIntl)(ContextHeaderComponent);
