// App skeleton
import { App } from 'containers/App';

// Admin routes
import { AdminContainer } from 'containers/Admin';

// Employer routes
import { ATSContainer } from 'containers/Atsjobs';
import { RequisitionsContainer } from 'containers/Requisitions';
import { JobPostingContainer } from 'containers/JobPosting';
import { ImportedJobContainer } from 'containers/ImportedJob';
import { IntegrationsContainer } from 'containers/Integrations';
import { ReportingContainer } from 'containers/Reporting';

// Suppliers routes
import { MarketplaceContainer } from 'containers/Marketplace/MarketplaceJobs';
import { MarketplaceSuppliedCandidatesContainer } from 'containers/Marketplace/MarketplaceSuppliedCandidates';
import { MarketplaceJobContainer } from 'containers/MarketplaceJobs';
import { CandidatesContainer } from 'containers/Candidates';

// Shared private routes
import { DashboardContainer } from 'containers/Dashboard';
import { SettingsContainer } from 'containers/Settings';
import { YourAccountContainer } from 'containers/YourAccount';
import { CompanyProfileContainer } from 'containers/CompanyProfile';
import { UserProfileContainer } from 'containers/UserProfile';
import { WorkOrdersContainer } from 'containers/WorkOrders';
import { HotlistContainer } from 'containers/Hotlist';
import { PartnersContainer } from 'containers/Partners';
import { SubscriptionPlanContainer } from 'containers/SubscriptionPlan';
import { ClientsContainer } from 'containers/Clients';

// Shared public routes
import { LoginContainer } from 'containers/Login';
import { LogoutContainer } from 'containers/Logout';
import { SetPasswordContainer } from 'containers/SetPassword';
import { ForgotPasswordContainer } from 'containers/ForgotPassword';
import { ConfirmEmailContainer } from 'containers/ConfirmEmail';
import { SignUpContainer } from 'containers/SignUp';
import { SupplierHubContainer } from 'containers/SupplierHub';
import { CompanyProfilePublicContainer } from 'containers/CompanyProfile/Public';
import { NotFoundContainer } from 'containers/NotFound';
import { OnboardingContainer } from 'containers/Onboarding';

const dashboardRoutes = [
  {
    path: '/',
    component: DashboardContainer,
    exact: true,
  },
];

const clientsRoutes = [
  {
    path: '/clients',
    component: ClientsContainer,
  },
];

const partnersRoutes = [
  {
    path: '/partners',
    component: PartnersContainer,
    name: 'partners',
  },
];

const marketplaceJobs = [
  {
    path: '/marketplace',
    component: MarketplaceContainer,
    exact: true,
  },
  // TODO: these are redirects from old paths; remove when not needed anymore
  {
    path:
      '/marketplace/:type(all|partners|global)/(all|global|partners|communities)?',
    component: MarketplaceContainer,
  },
  {
    path:
      '/marketplace/:type(discover|saved|assigned)/(all|global|partners|communities)?',
    component: MarketplaceContainer,
  },
  {
    path: '/marketplace/supplied-candidates/',
    component: MarketplaceSuppliedCandidatesContainer,
  },
  {
    path: '/marketplace/:id',
    component: MarketplaceJobContainer,
  },
];

const supplierHubRoutes = [
  {
    path: '/public/vendor-hub',
    component: SupplierHubContainer,
  },
  {
    path: '/public/company/profile/:id',
    component: CompanyProfilePublicContainer,
  },
];

const candidatesRoutes = [
  {
    path: '/candidates',
    component: CandidatesContainer,
  },
];

const settingsRoutes = [
  {
    path: '/settings',
    component: SettingsContainer,
  },
];

const yourAccountRoutes = [
  {
    path: '/account',
    component: YourAccountContainer,
  },
];

const companyRoutes = [
  {
    path: '/partners/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/partners/requests/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/partners/hub/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/partners/blocked/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/partners/communities/members/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/company/profile/:tab(about|users)',
    component: CompanyProfileContainer,
  },
  {
    path: '/company/profile/:id/:tab(about|users)?',
    component: CompanyProfileContainer,
  },
  {
    path: '/company/profile',
    component: CompanyProfileContainer,
  },
];

const userRoutes = [
  {
    path: '/user/profile/:id?',
    component: UserProfileContainer,
  },
];

const jobsRoutes = [
  {
    path: '/job/imported/:integrationType/:id',
    component: ImportedJobContainer,
  },
  {
    path: '/job/:p1?/:p2?',
    component: JobPostingContainer,
  },
  {
    path: '/jobs',
    component: RequisitionsContainer,
  },
];

const atsRoutes = [
  {
    path: '/ats/:id',
    component: ATSContainer,
  },
];

const accountRoutes = [
  {
    path: '/login/:token?',
    component: LoginContainer,
  },
  {
    path: '/itserve',
    component: LoginContainer,
  },
  {
    path: '/logout',
    component: LogoutContainer,
  },
  {
    path: '/signup',
    component: SignUpContainer,
  },
  {
    path: '/forgotpassword',
    component: ForgotPasswordContainer,
  },
  {
    path: '/setpassword',
    component: SetPasswordContainer,
  },
  {
    path: '/resetpassword',
    component: SetPasswordContainer,
  },
  {
    path: '/onboarding/:step?',
    component: OnboardingContainer,
  },
];

const adminRoutes = [
  {
    path: '/admin',
    component: AdminContainer,
  },
];

const integrationsRoutes = [
  {
    path: '/integrations',
    component: IntegrationsContainer,
  },
];

const reportingRoutes = [
  {
    path: '/reporting',
    component: ReportingContainer,
  },
];

const workOrdersRoutes = [
  {
    path: '/work-orders',
    component: WorkOrdersContainer,
  },
];

const hotlistRoutes = [
  {
    path: '/hotlist',
    component: HotlistContainer,
  },
];

const subscriptionPlanRoutes = [
  {
    path: '/subscription-plan',
    component: SubscriptionPlanContainer,
  },
];

const confirmEmailRoutes = [
  {
    path: '/confirm-email',
    component: ConfirmEmailContainer,
  },
];

const rootRoutes = () => dashboardRoutes;

const notFoundRoute = [
  {
    component: NotFoundContainer,
  },
];

export const Routes = [
  {
    component: App,
    routes: [
      ...adminRoutes,
      ...accountRoutes,
      ...candidatesRoutes,
      ...confirmEmailRoutes,
      ...settingsRoutes,
      ...yourAccountRoutes,
      ...companyRoutes,
      ...userRoutes,
      ...jobsRoutes,
      ...atsRoutes,
      ...clientsRoutes,
      ...partnersRoutes,
      ...marketplaceJobs,
      ...integrationsRoutes,
      ...reportingRoutes,
      ...supplierHubRoutes,
      ...workOrdersRoutes,
      ...hotlistRoutes,
      ...subscriptionPlanRoutes,
      ...rootRoutes(),
      ...notFoundRoute,
      // NotFound must be very last route
      // which should only render, if there is no other match
    ],
  },
];
