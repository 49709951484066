import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

import { companyID } from 'utils/authorization/utils/auth';
import { Icon } from 'components/elements/icon';
import { RichCheckbox } from 'components/form/richCheckbox';
import { Button, BUTTON_SIZES } from 'components/elements/button';

import { InfoButton } from './infoButton';

import i18n from '../utils/i18n';
import { isAffiliated as isAffiliatedFn } from '../utils/helpers';
import * as styled from '../styles/affiliationButtons';

const PROFILE_COMPLETED = 100;

export class AffiliationButtons extends Component {
  renderAffiliateButton() {
    const { company, currentCompany, openModal } = this.props;
    const ctaInactive =
      get(currentCompany, 'profile_completion') !== PROFILE_COMPLETED;

    return (
      <styled.ButtonWithInfoWrapper>
        {ctaInactive && (
          <styled.InfoBox>
            <Icon icon="Info" />
            <span>
              <FormattedMessage {...i18n.infoRequestDisabled1} />
              <styled.SmartLink to="/company/profile">
                <FormattedMessage {...i18n.infoRequestDisabled2} />
              </styled.SmartLink>
              <FormattedMessage {...i18n.infoRequestDisabled3} />
            </span>
          </styled.InfoBox>
        )}
        <Button
          color="primaryDark"
          disabled={ctaInactive}
          onClick={() => openModal({ context: 'affiliate', company })}
        >
          {/** briefcase-plus */}
          <Icon icon="Briefcase" />
          <FormattedMessage {...i18n.buttonRequest} />
        </Button>
      </styled.ButtonWithInfoWrapper>
    );
  }

  renderRevokeButton(features = { employer: true, supplier: true }) {
    const { company, onRevokeAffiliation } = this.props;
    const { affiliation_request: request } = company;
    const checkboxProps = {
      disabled: true,
      context: { i18n: { name: company.name } },
    };
    let buttonText = <FormattedMessage {...i18n.buttonRequestSent} />;
    if (!features.employer) {
      buttonText = (
        <styled.Text>
          <Icon icon="UserArrowLeft" />
          <FormattedMessage {...i18n.buttonRequestFollowerSent} />
        </styled.Text>
      );
    } else if (!features.supplier) {
      buttonText = (
        <styled.Text>
          <Icon icon="UserArrowRight" />
          <FormattedMessage {...i18n.buttonRequestFollowingSent} />
        </styled.Text>
      );
    }

    return (
      <InfoButton icon="SignOut" color="blue" text={buttonText}>
        <styled.RequestWrapper>
          {features.supplier && (
            <RichCheckbox
              {...checkboxProps}
              icon="UserArrowLeft"
              value={request.supplier === 'requested'}
              check={request.supplier === 'requested' ? 'Check' : 'X'}
              label={i18n.labelFollowYou}
              placeholder={i18n.placeholderFollowYou}
            />
          )}
          {features.employer && (
            <RichCheckbox
              {...checkboxProps}
              icon="UserArrowRight"
              value={request.employer === 'requested'}
              check={request.employer === 'requested' ? 'Check' : 'X'}
              label={i18n.labelFollowThem}
              placeholder={i18n.placeholderFollowThem}
            />
          )}
          <Button
            size={BUTTON_SIZES.medium}
            color="mono"
            type="outline"
            onClick={() =>
              onRevokeAffiliation({ context: 'revoke-affiliation', request })
            }
          >
            <FormattedMessage {...i18n.buttonRequestRevoke} />
          </Button>
        </styled.RequestWrapper>
      </InfoButton>
    );
  }

  renderRespondButton(features = { employer: true, supplier: true }) {
    const { company, onRespondAffiliation } = this.props;
    const { affiliation_request: request } = company;
    const checkboxProps = {
      disabled: true,
      context: { i18n: { name: company.name } },
    };
    let buttonText = <FormattedMessage {...i18n.buttonRequestReceived} />;
    if (!features.employer) {
      buttonText = (
        <styled.Text>
          <Icon icon="UserArrowRight" />
          <FormattedMessage {...i18n.buttonRequestFollowerReceived} />
        </styled.Text>
      );
    } else if (!features.supplier) {
      buttonText = (
        <styled.Text>
          <Icon icon="UserArrowLeft" />
          <FormattedMessage {...i18n.buttonRequestFollowingReceived} />
        </styled.Text>
      );
    }

    return (
      <InfoButton icon="Received" color="blue" text={buttonText}>
        <styled.RequestWrapper>
          {features.employer && (
            <RichCheckbox
              {...checkboxProps}
              icon="UserArrowLeft"
              value={request.employer === 'requested'}
              check={request.employer === 'requested' ? 'Check' : 'X'}
              label={i18n.labelFollowYou}
              placeholder={i18n.placeholderFollowYou}
            />
          )}
          {features.supplier && (
            <RichCheckbox
              {...checkboxProps}
              icon="UserArrowRight"
              value={request.supplier === 'requested'}
              check={request.supplier === 'requested' ? 'Check' : 'X'}
              label={i18n.labelFollowThem}
              placeholder={i18n.placeholderFollowThem}
            />
          )}
          <Button
            size={BUTTON_SIZES.medium}
            color="primaryDark"
            onClick={() =>
              onRespondAffiliation({ context: 'respond-affiliation', request })
            }
          >
            <FormattedMessage {...i18n.buttonRequestView} />
          </Button>
        </styled.RequestWrapper>
      </InfoButton>
    );
  }

  renderAffiliationButtons() {
    const { company, openModal } = this.props;
    const { affiliated, affiliation_request: request } = company;

    const buttonFn = (feature) => (
      <InfoButton
        icon={affiliated[feature] ? 'Check' : 'X'}
        color={affiliated[feature] ? 'green' : 'grey'}
        text={
          <styled.Text>
            <Icon
              icon={`UserArrow${feature === 'employer' ? 'Right' : 'Left'}`}
            />
            <FormattedMessage
              {...i18n.features[feature][affiliated[feature]].buttonStatus}
            />
          </styled.Text>
        }
      >
        <styled.AffiliationInfo
          onClick={() =>
            openModal({
              context: affiliated[feature] ? 'remove-affiliation' : 'affiliate',
              features: { [feature]: true },
              company,
            })
          }
        >
          {affiliated[feature] && <Icon icon="UserMinus" />}
          {!affiliated[feature] && (
            <Icon
              icon={`UserArrow${feature === 'employer' ? 'Right' : 'Left'}`}
            />
          )}
          <styled.InfoDescription>
            <styled.InfoHeader>
              <FormattedMessage
                {...i18n.features[feature][affiliated[feature]].requestHeader}
              />
            </styled.InfoHeader>
            <styled.InfoText>
              <FormattedMessage
                {...i18n.features[feature][affiliated[feature]].requestText}
              />
            </styled.InfoText>
          </styled.InfoDescription>
        </styled.AffiliationInfo>
      </InfoButton>
    );

    const buttonSwitchFn = (feature) => {
      const opposite = feature === 'employer' ? 'supplier' : 'employer';
      if (
        !affiliated[feature] &&
        get(request, 'status') === 'pending' &&
        request.requester_id === companyID() &&
        request[feature]
      ) {
        return this.renderRevokeButton({ [feature]: true });
      }
      if (
        !affiliated[feature] &&
        get(request, 'status') === 'pending' &&
        request.receiver_id === companyID() &&
        request[opposite]
      ) {
        return this.renderRespondButton({ [opposite]: true });
      }
      return buttonFn(feature);
    };

    return (
      <styled.AffiliationButtons>
        {buttonSwitchFn('supplier')}
        {buttonSwitchFn('employer')}
      </styled.AffiliationButtons>
    );
  }

  render() {
    const { company } = this.props;
    const { affiliation_request: request } = company;
    const isAffiliated = isAffiliatedFn(company);

    if (
      !isAffiliated &&
      get(request, 'status') === 'pending' &&
      request.requester_id === companyID()
    ) {
      return this.renderRevokeButton();
    }
    if (
      !isAffiliated &&
      get(request, 'status') === 'pending' &&
      request.receiver_id === companyID()
    ) {
      return this.renderRespondButton();
    }
    if (isAffiliated) {
      return this.renderAffiliationButtons();
    }
    return this.renderAffiliateButton();
  }
}

AffiliationButtons.propTypes = {
  company: PropTypes.object.isRequired,
  currentCompany: PropTypes.object,
  openModal: PropTypes.func,
  onRevokeAffiliation: PropTypes.func,
  onRespondAffiliation: PropTypes.func,
};
