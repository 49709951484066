import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Form as FormBase } from 'components/structure/form/styles/form';
import { ButtonLink, buttonSizeMixin } from 'components/elements/styles/button';
import { BUTTON_SIZES } from 'components/elements/button';
import { SettingsContainer as SettingsContainerBase } from 'containers/Settings/styles';
import { Icon } from 'components/elements/icon';

export const Card = styled(FormBase)`
  padding: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

export const Container = styled.div`
  padding: 2rem 4rem;
  max-width: 58rem;
  border: ${({ hasBorder }) => (hasBorder ? 1 : 0)}px solid ${Colors.outline};
  border-radius: 0.3rem;

  & li span > span {
    color: ${Colors.bhOrange};
  }
`;

export const Header = styled.h2`
  margin: 0;
  height: 5.4rem;
  padding: 0 4rem;
  display: flex;
  align-items: center;
  color: ${Colors.tealDark};
  background-color: ${Colors.headerBG};

  & > span {
    margin-left: 2rem;
  }

  & > svg {
    margin-right: 1.2rem;

    path {
      fill: ${Colors.tealDark};
    }
  }

  & > a {
    margin-left: auto;
  }

  & > ${ButtonLink} {
    ${(props) => buttonSizeMixin({ ...props, size: BUTTON_SIZES.medium })};
  }
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  margin-top: ${({ margin = 2 }) => margin}rem;
`;

export const Logo = styled.div`
  display: flex;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0.4rem;
  border: 1px solid ${Colors.outline};
  border-radius: 0.3rem;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const SvgImage = styled(Icon)`
  width: 100%;
  height: 100%;
`;

export const SettingsContainer = styled(SettingsContainerBase)`
  max-width: 70rem;
`;
