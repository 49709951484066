import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { FormLabel as Label } from 'components/form/label/label';
import { InputText } from 'components/form/input/text';

import * as styled from './styles/fieldset';

export class DayMonthFieldset extends Component {
  // we're using leap year (2012) so that Feb 29 will be available
  daysInMonth = (month) => moment(`2012-${month || 1}`).daysInMonth();

  handleMonthChange = (value) => {
    const {
      fn,
      inputValue: { day },
    } = this.props;

    if (value < 1 || value > 12) {
      return;
    }

    const daysInMonth = this.daysInMonth(value);
    if (day > daysInMonth) {
      fn({ month: value || null, day: daysInMonth });
    } else {
      fn({ month: value || null, day });
    }
  };

  handleDayChange = (value) => {
    const {
      fn,
      inputValue: { month },
    } = this.props;

    if (value < 1 || value > this.daysInMonth(month)) {
      return;
    }

    fn({ month, day: value || null });
  };

  handleChange = (field, value) => {
    const cleanValue = parseInt(value.replace(/[^0-9]/g, ''), 10);

    if (field === 'month') {
      this.handleMonthChange(cleanValue);
    } else {
      this.handleDayChange(cleanValue);
    }
  };

  render() {
    const { className, context, shouldValidate, inputValue } = this.props;

    const { id, label, placeholderMonth, placeholderDay, required } = context;

    return (
      <styled.Fieldset className={className}>
        <Label value={label} elementToRefer={id} required={required} />
        <styled.Container>
          <InputText
            id={`${id}-month`}
            name={id}
            value={inputValue.month}
            placeholder={placeholderMonth}
            inputType="number"
            shouldValidate={shouldValidate}
            required={required}
            onChange={(value) => this.handleChange('month', value)}
          />
          <styled.DayMonthSeparator>/</styled.DayMonthSeparator>
          <InputText
            id={`${id}-day`}
            name={id}
            value={inputValue.day}
            placeholder={placeholderDay}
            inputType="number"
            shouldValidate={shouldValidate}
            required={required}
            onChange={(value) => this.handleChange('day', value)}
          />
        </styled.Container>
      </styled.Fieldset>
    );
  }
}

DayMonthFieldset.propTypes = {
  className: PropTypes.string,
  context: PropTypes.object,
  inputValue: PropTypes.object,
  shouldValidate: PropTypes.bool,
  fn: PropTypes.func,
};
