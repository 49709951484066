import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const Jobdiva = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={26} size={size}>
      <g fill={color}>
        <path d="m 5.604,24.710226 c -1.9,-0.21 -3.532,-1.055 -4.983,-2.59 l -0.621,-0.65 0.561,-0.531 c 0.3,-0.284 1.01,-0.95 1.564,-1.474 l 1.017,-0.95 0.681,0.658 c 0.509,0.501 0.823,0.74 1.257,0.958 0.531,0.262 0.636,0.284 1.197,0.284 0.554,0 0.636,-0.022 0.823,-0.187 0.292,-0.27 0.456,-0.703 0.546,-1.421 0.052,-0.412 0.082,-3.562 0.082,-9.0679997 v -8.439 h 4.347 l -0.03,9.1049997 c -0.03,9.696 -0.022,9.509 -0.389,10.773 -0.494,1.699 -1.93,3.023 -3.733,3.427 a 8.2,8.2 0 0 1 -2.32,0.104 z m 6.426,-0.599 c 0.038,-0.06 0.187,-0.254 0.33,-0.441 0.538,-0.704 0.905,-1.84 1.024,-3.158 l 0.053,-0.583 h 0.838 c 3.187,0 4.795,-0.621 5.947,-2.282 0.876,-1.272 1.265,-2.708 1.272,-4.653 0,-2.133 -0.501,-3.8009997 -1.548,-5.1179997 -1.175,-1.474 -2.843,-2.17 -5.522,-2.304 l -0.972,-0.045 v -4.19 l 0.71,0.023 c 2.746,0.082 4.9,0.524 6.36,1.302 2.446,1.309 4.436,4.16 5.072,7.287 1.182,5.7599997 -0.98,11.1839997 -5.274,13.2499997 -1.37,0.658 -2.896,0.89 -6.3,0.972 -1.758,0.037 -2.042,0.03 -1.99,-0.06 z" />
      </g>
    </IconBase>
  );
};

Jobdiva.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
