import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { get, isEmpty, compact } from 'lodash';

import { confirmAlert } from 'components/overlay/Confirm';
import { downloadFile } from 'utils/fileFunctions';
import { Overlay } from 'components/structure/overlay/overlay';
import { DropzoneUpload } from 'components/form/dropzoneUpload';
import { Icon } from 'components/elements/icon';
import { AssetViewer, canPreview } from 'containers/Assets/Viewer';
import { ButtonDropdownMenu } from 'components/navigation/ButtonDropdownMenu';
import { Highlight } from 'components/text/Highlight';
import { formatDate } from 'components/utils/date';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/documents';

const acceptedFormats = [
  'pdf',
  'doc',
  'image',
  'audio',
  'ebook',
  'calendar',
  'contact',
  'presentation',
  'project',
  'spreadsheet',
  'video',
  'web',
];

/* eslint react/no-did-update-set-state: 0 */
export class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newName: '',
      modal: null,
      doc: null,
      focused: false,
    };
  }

  componentDidUpdate() {
    const { focused } = this.state;
    if (this.inputRef && !focused) {
      this.inputRef.select();
      this.setState({ focused: true });
    }
  }

  handleCreateDocumentUpload = ({ file, name }) => {
    const { onUploadDocument } = this.props;

    return onUploadDocument({ file, name, type: 'private' });
  };

  handleDeleteDocument = (doc) => {
    const { intl, onDeleteDocument } = this.props;

    confirmAlert({
      title: intl.formatMessage(i18n.titleDeleteDocument),
      message: intl.formatMessage(i18n.subtitleDeleteDocument, {
        name: doc.name,
      }),
      confirmLabel: intl.formatMessage(i18n.buttonDelete),
      cancelLabel: intl.formatMessage(i18n.buttonCancel),
      onConfirm: () => onDeleteDocument(doc),
    });
  };

  handleEditDocumentName = (doc) => {
    this.setState({ modal: 'rename', newName: doc.name, focused: false, doc });
  };

  handleCloseModal = () => {
    this.setState({ modal: null, doc: null });
  };

  handleRenameDocument = (event) => {
    event.preventDefault();

    const { onRenameDocument } = this.props;
    const { doc, newName } = this.state;

    if (!isEmpty(newName)) {
      onRenameDocument({ id: doc.id, name: newName }).then(() => {
        this.setState({ modal: null, doc: null });
      });
    }
  };

  handlePreviewDocument = (doc) => {
    this.setState({ modal: 'preview', doc });
  };

  handleDownloadDocument = (doc) => {
    downloadFile(doc.asset.original_url, doc.name);
  };

  canEditDocument = (doc) => {
    const { currentUser } = this.props;

    return (
      get(currentUser, 'id') === doc.uploader.id ||
      ['Owner', 'Admin'].includes(get(currentUser, 'position'))
    );
  };

  avatarContext = ({
    uploader: { first_name: first = '', last_name: last = '' },
  }) => ({ initials: `${first[0]}${last[0]}` });

  renderNewForm() {
    return (
      <styledUI.Form>
        <DropzoneUpload
          acceptedFormats={acceptedFormats}
          maxFileSize={10 * 1024 * 1024}
          onChange={this.handleCreateDocumentUpload}
        />
      </styledUI.Form>
    );
  }

  renderModal() {
    const { modal, doc, newName } = this.state;

    switch (modal) {
      case 'rename':
        return (
          <Overlay closeOverlay={this.handleCloseModal}>
            <styledUI.ModalView>
              <styledUI.Form>
                <styledUI.FormTitle>
                  <FormattedMessage {...i18n.titleRenameDocument} />
                </styledUI.FormTitle>

                <styledUI.Label>
                  <FormattedMessage {...i18n.titleRenameDocument} />
                </styledUI.Label>
                <styledUI.Input
                  onInnerRef={(ref) => {
                    this.inputRef = ref;
                  }}
                  inputType="text"
                  placeholder=""
                  value={newName}
                  required
                  shouldValidate
                  onChange={(value) => this.setState({ newName: value })}
                />

                <styledUI.ButtonWrapper>
                  <styledUI.Submit
                    value={i18n.buttonSave}
                    onClick={this.handleRenameDocument}
                  />
                </styledUI.ButtonWrapper>
              </styledUI.Form>
            </styledUI.ModalView>
          </Overlay>
        );
      case 'preview':
        return <AssetViewer asset={doc} onClose={this.handleCloseModal} />;
      default:
        return null;
    }
  }

  renderDocumentRow = (doc) => {
    const {
      id,
      name,
      inserted_at: insertedAt,
      uploader: { first_name: first, last_name: last, id: uploaderId, color },
    } = doc;
    const extension = get(doc.asset.meta, 'original_extension', '')
      .replace('.', '')
      .toLowerCase();
    const canEdit = this.canEditDocument(doc);
    const previewEnabled = canPreview(extension);

    const menuItems = compact([
      previewEnabled && {
        text: i18n.buttonPreview,
        onClick: () => this.handlePreviewDocument(doc.asset),
        icon: 'Eye',
      },
      {
        text: i18n.buttonDownload,
        onClick: () => this.handleDownloadDocument(doc),
        icon: 'DownloadSimple',
      },
      canEdit && {
        text: i18n.buttonRename,
        onClick: () => this.handleEditDocumentName(doc),
        icon: 'PencilLine',
      },
      canEdit && {
        text: i18n.buttonDelete,
        onClick: () => this.handleDeleteDocument(doc),
        icon: 'X',
      },
    ]);

    return (
      <tr key={`document-${id}`}>
        <styledUI.Td>
          <Icon icon="FileText" size="large" />
        </styledUI.Td>
        <styledUI.Td color={previewEnabled ? 'primary' : null}>
          {previewEnabled ? (
            <styledUI.Button
              type="blank"
              onClick={() => this.handlePreviewDocument(doc.asset)}
            >
              {name}
            </styledUI.Button>
          ) : (
            name
          )}
        </styledUI.Td>
        <styledUI.Td>{`.${extension}`}</styledUI.Td>
        <styledUI.Td>
          <styledUI.User to={`/user/profile/${uploaderId}`}>
            <styledUI.Avatar
              size="mmsmall"
              color={color}
              context={this.avatarContext(doc)}
            />
            {`${first} ${last}`}
          </styledUI.User>
        </styledUI.Td>
        <styledUI.Td>{formatDate({ date: insertedAt })}</styledUI.Td>
        <styledUI.Td>
          <ButtonDropdownMenu items={menuItems} />
        </styledUI.Td>
      </tr>
    );
  };

  render() {
    const { documents } = this.props;

    return (
      <>
        <styled.InfoBox
          icon="WarningCircle"
          color="tealDark"
          backgroundColor="tealLighter"
          content={<Highlight type="bold" text={i18n.infoBoxDocuments} />}
        />
        <styled.Card bordered isSimple>
          <styled.Section
            title={<FormattedMessage {...i18n.titleDocuments} />}
            data-manual="profile.documents"
            tooltip={i18n.titleTooltipDocuments}
          >
            {this.renderNewForm()}
            {this.renderModal()}

            <styledUI.Table>
              <thead>
                <tr>
                  <styledUI.Th></styledUI.Th>
                  <styledUI.Th>
                    <FormattedMessage {...i18n.headerName} />
                  </styledUI.Th>
                  <styledUI.Th>
                    <FormattedMessage {...i18n.headerType} />
                  </styledUI.Th>
                  <styledUI.Th>
                    <FormattedMessage {...i18n.headerUploader} />
                  </styledUI.Th>
                  <styledUI.Th>
                    <FormattedMessage {...i18n.headerDate} />
                  </styledUI.Th>
                  <styledUI.Th></styledUI.Th>
                </tr>
              </thead>
              <tbody>{documents.map(this.renderDocumentRow)}</tbody>
            </styledUI.Table>
          </styled.Section>
        </styled.Card>
      </>
    );
  }
}

Documents.propTypes = {
  intl: intlShape,
  currentUser: PropTypes.object,
  documents: PropTypes.array,
  onUploadDocument: PropTypes.func,
  onRenameDocument: PropTypes.func,
  onDeleteDocument: PropTypes.func,
};
