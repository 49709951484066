export const MARKETPLACE_JOBS_REQUEST =
  'gustav/marketplace/MARKETPLACE_JOBS_REQUEST';
export const MARKETPLACE_JOBS_SUCCESS =
  'gustav/marketplace/MARKETPLACE_JOBS_SUCCESS';
export const MARKETPLACE_JOBS_ERROR =
  'gustav/marketplace/MARKETPLACE_JOBS_ERROR';

export const SAVE_JOB_REQUEST = 'gustav/marketplace/SAVE_JOB_REQUEST';
export const SAVE_JOB_SUCCESS = 'gustav/marketplace/SAVE_JOB_SUCCESS';
export const SAVE_JOB_ERROR = 'gustav/marketplace/SAVE_JOB_ERROR';

export const UNSAVE_JOB_REQUEST = 'gustav/marketplace/UNSAVE_JOB_REQUEST';
export const UNSAVE_JOB_SUCCESS = 'gustav/marketplace/UNSAVE_JOB_SUCCESS';
export const UNSAVE_JOB_ERROR = 'gustav/marketplace/UNSAVE_JOB_ERROR';

export const JOBS_COUNTERS_SUCCESS = 'gustav/marketplace/JOBS_COUNTERS_SUCCESS';

export const MARKETPLACE_FETCH_REQUEST =
  'gustav/jobs/MARKETPLACE_FETCH_REQUEST';
export const MARKETPLACE_FETCH_SUCCESS =
  'gustav/jobs/MARKETPLACE_FETCH_SUCCESS';
export const MARKETPLACE_FETCH_ERROR = 'gustav/jobs/MARKETPLACE_FETCH_ERROR';
