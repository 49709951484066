import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import i18n from './utils/i18n';
import * as styled from './styles';
import { StyleControl } from './styleControl';

export const BlockStyleControlsComponent = (props) => {
  const { editorState, intl } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const BLOCK_TYPES = useMemo(
    () => [
      {
        label: 'UL',
        style: 'unordered-list-item',
        icon: 'ListBullets',
        text: intl.formatMessage(i18n.bulletedList),
      },
      {
        label: 'OL',
        style: 'ordered-list-item',
        icon: 'ListNumbers',
        text: intl.formatMessage(i18n.orderedList),
      },
    ],
    []
  );

  return (
    <styled.ButtonSet onMouseDown={(event) => event.preventDefault()}>
      {BLOCK_TYPES.map((type) => (
        <StyleControl
          key={type.label}
          active={type.style === blockType}
          onToggle={props.onToggle}
          type={type}
        />
      ))}
    </styled.ButtonSet>
  );
};

BlockStyleControlsComponent.propTypes = {
  editorState: PropTypes.any.isRequired,
  onToggle: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export const BlockStyleControls = injectIntl(BlockStyleControlsComponent);
