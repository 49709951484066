import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  DocumentViewer,
  extension,
  canPreview,
  canConvert,
} from 'components/visual/documentViewer';

import { fetchAsset } from '../actions';

export { canPreview, canConvert };

export const AssetViewerComponent = (props) => {
  const {
    asset,
    modal,
    renderer,
    onClose,
    handleFetchAsset,
    enableZoom,
    enableDownload,
  } = props;
  const [newAsset, setAsset] = useState(asset);
  const onError = () => handleFetchAsset(asset.id).then(setAsset);
  const cachedAsset = sessionStorage.getItem(`asset:${asset.id}`);
  const useAsset = (cachedAsset && JSON.parse(cachedAsset)) || newAsset;

  useEffect(() => {
    if (canConvert(extension(asset.url))) {
      const timer = setInterval(() => {
        handleFetchAsset(asset.id).then((response) => {
          if (!canConvert(extension(response.url))) {
            setAsset(response);
            sessionStorage.setItem(
              `asset:${asset.id}`,
              JSON.stringify(response)
            );
            clearInterval(timer);
          }
        });
      }, 10000);

      return () => clearInterval(timer);
    }

    if (canPreview(asset.url)) {
      setAsset(asset);
      sessionStorage.setItem(`asset:${asset.id}`, JSON.stringify(asset));
    }

    return null;
  }, [asset]);

  return (
    <DocumentViewer
      url={useAsset.url}
      downloadUrl={useAsset.original_url}
      renderer={renderer}
      modal={modal}
      onError={onError}
      onClose={onClose}
      enableZoom={enableZoom}
      enableDownload={enableDownload}
    />
  );
};

AssetViewerComponent.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
    filename: PropTypes.string,
    url: PropTypes.string,
    original_url: PropTypes.string,
  }),
  renderer: PropTypes.shape({
    withScrollbars: PropTypes.bool,
    withBorder: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  modal: PropTypes.bool,
  onClose: PropTypes.func,
  handleFetchAsset: PropTypes.func,
  enableZoom: PropTypes.bool,
  enableDownload: PropTypes.bool,
};

AssetViewerComponent.defaultProps = {
  modal: true,
  renderer: {
    withScrollbars: true,
    withBorder: false,
  },
  enableDownload: true,
};

function mapDispatchToProps(dispatch) {
  return {
    handleFetchAsset: (id) => dispatch(fetchAsset(id)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export const AssetViewer = compose(withConnect)(AssetViewerComponent);
