import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { moneyShape } from 'components/utils/money';

import * as styled from './styles';

export const DynamicWidthInput = (props) => {
  const {
    className,
    placeholder,
    isValid,
    onKeyUp,
    onKeyPress,
    dataManual,
    size,
    value,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const measurer = useRef(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (value && measurer?.current) {
      const rect = measurer.current.getBoundingClientRect();
      setWidth(rect.width);
    }
  }, [value]);

  return (
    <>
      <styled.InputSizer ref={measurer}>{value}</styled.InputSizer>
      <styled.Input
        style={{ width }}
        className={className}
        value={value}
        placeholder={placeholder}
        isValid={isValid}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        data-manual={dataManual}
        size={size}
      />
    </>
  );
};

DynamicWidthInput.propTypes = {
  className: PropTypes.string,
  value: moneyShape,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default']),
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataManual: PropTypes.string,
};
