import styled from 'styled-components';

export const ImageWrapper = styled.div`
  height: 100%;
  overflow: ${({ enableZoom }) => (enableZoom ? 'hidden' : 'auto')};
`;

export const ImagePreview = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const Error = styled.div`
  font-size: 1.5rem;
  padding: 2rem;
  margin: 0 auto;
  text-align: center;
`;
