import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { injectReducer } from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';

import {
  get,
  find,
  compact,
  assign,
  keyBy,
  uniqBy,
  values,
  reject,
  isNil,
  trim,
  last,
  pick,
  debounce,
} from 'lodash';
import equal from 'fast-deep-equal';
import qs from 'qs';

import { userHasPermission, userIsAuthenticated } from 'utils/authWrapper';
import { companySelector } from 'containers/App/selectors';
import { fetchAllMembers } from 'containers/Settings/Members/actions';

import { jobsReducer as reducer } from './reducer';
import { jobsCountersSelector } from './selectors';

import { ContextHeader } from './ContextHeader';
import { Active } from './Active';
import { Closed } from './Closed';
import { Draft } from './Draft';
import { Imported } from './Imported';
import { Archived } from './Archived';
import { CandidatesList } from './CandidatesList/Loadable';
import { filtersDefinition } from './Filters/utils/filtersDefinition';

import { fetchCounters } from './actions';

class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resource: props.location.pathname.split('/')[2],
      filters: [],
      members: [],
      companyCounter: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const resource = props.location.pathname.split('/')[2];

    if (resource !== state.resource) {
      return { filters: [] };
    }
    return null;
  }

  componentDidMount() {
    const { location } = this.props;
    const filter = last(location.pathname.split('/'));

    this.onFetchCounters();

    if (['jobs', 'active', 'draft', 'closed', 'candidates'].includes(filter)) {
      this.onFetchMembers();
    }
  }

  /* eslint react/no-did-update-set-state: 0 */
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const { filters } = this.state;
    const [resource, filter] = location.pathname.split('/').slice(-2);
    const [oldResource, oldFilter] = prevProps.location.pathname
      .split('/')
      .slice(-2);

    if (resource !== oldResource || filter !== oldFilter) {
      this.setState(
        { resource: filter, filters: this.buildFilters(this.props) },
        this.onFetchCounters
      );
    } else if (!equal(filters, prevState.filters)) {
      this.onFetchCounters();
    }

    if (
      oldFilter !== filter &&
      ['active', 'draft', 'closed', 'candidates'].includes(filter)
    ) {
      this.onFetchMembers();
    }
  }

  onFetchCounters = () => {
    const { handleFetchCounters } = this.props;
    const filters = this.getCurrentFilters();
    const atsMemberId = get(find(filters, { id: 'ats_member_id' }), 'value');
    const params =
      atsMemberId && atsMemberId !== 'company'
        ? { ats_member_id: atsMemberId }
        : {};

    handleFetchCounters(params);
  };

  onChangeFilters = (newFilters) => {
    const { filters: stateFilters } = this.state;
    const merged = assign(keyBy(stateFilters, 'id'), keyBy(newFilters, 'id'));
    const filters = reject(values(merged), ({ value }) => isNil(value));

    this.setState({ filters });
  };

  onFetchMembers = () => {
    const {
      handleFetchMembers,
      location: { pathname },
    } = this.props;
    const counterName = last(pathname.split('/'));
    const urlParams = qs.parse(trim(window.location.search, '?'));

    const membersFilter = {
      counter: counterName,
      ...pick(urlParams, 'supplier_company_name'),
    };

    handleFetchMembers(membersFilter).then(({ data, companyCounter }) => {
      this.setState({
        members: data,
        companyCounter,
      });
    });
  };

  getCurrentFilters = () => {
    const { filters } = this.state;
    return uniqBy([...filters, ...this.buildFilters(this.props)], 'id');
  };

  buildFilters = (props) => {
    const [resource, filter] = props.location.pathname.split('/').slice(-2);
    return uniqBy(
      [
        ...this.prefillFromUrl(props),
        ...filtersDefinition(resource, filter).filters,
      ],
      'id'
    );
  };

  prefillFromUrl = (props) => {
    const { location } = props;
    const params = qs.parse(trim(location.search, '?'));
    return compact([
      params.status && { id: 'status', value: params.status },
      params.ats_member_id && {
        id: 'ats_member_id',
        value: params.ats_member_id,
      },
    ]);
  };

  render() {
    const {
      location: { pathname },
      match: { url },
      company,
      jobsCounters,
    } = this.props;
    const [resource, filter] = pathname.split('/').slice(-2);
    const { members, companyCounter: stateCompanyCounter } = this.state;

    const filters = filtersDefinition(resource, filter);
    const filtered = this.getCurrentFilters(this.props);
    const filtersProps = {
      defaultFiltered: filters.defaultFilters,
      filtered,
      resource,
      filter,
    };

    const companyCounter =
      filter === 'candidates'
        ? stateCompanyCounter
        : get(jobsCounters, `${filter}_all`, 0);

    return (
      <>
        <ContextHeader
          resource={resource}
          filter={filter}
          company={company}
          jobsCounters={jobsCounters}
        >
          {get(filters, 'Component') &&
            React.createElement(filters.Component, {
              onChange: this.onChangeFilters,
              currentFilters: filtered,
              members,
              jobsCounters,
              companyCounter,
            })}
        </ContextHeader>
        <Switch>
          {/* TODO: these are redirects from old paths; remove when not needed anymore */}
          <Redirect exact from={`${url}`} to={`${url}/active`} />
          <Redirect exact from={`${url}/draft/(my|all)`} to={`${url}/draft`} />
          <Redirect exact from={`${url}/assigned`} to={`${url}/active`} />
          <Route
            path={`${url}/active`}
            render={() => (
              <Active
                {...filtersProps}
                onFetchCounters={this.onFetchCounters}
                members={members}
                onChangeFilters={this.onChangeFilters}
              />
            )}
          />
          <Route
            path={`${url}/closed`}
            render={() => (
              <Closed
                {...filtersProps}
                onFetchCounters={this.onFetchCounters}
                members={members}
                onChangeFilters={this.onChangeFilters}
              />
            )}
          />
          <Route
            path={`${url}/draft`}
            render={() => (
              <Draft
                {...filtersProps}
                onFetchCounters={this.onFetchCounters}
                members={members}
                onChangeFilters={this.onChangeFilters}
              />
            )}
          />
          <Route
            path={`${url}/imported`}
            render={({ match }) => (
              <Imported match={match} onFetchCounters={this.onFetchCounters} />
            )}
          />
          <Route
            path={`${url}/archived`}
            render={({ match }) => (
              <Archived match={match} onFetchCounters={this.onFetchCounters} />
            )}
          />
          <Route
            path={`${url}/candidates`}
            render={() => (
              <CandidatesList
                onChangeFilters={this.onChangeFilters}
                {...filtersProps}
                members={members}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

Jobs.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  company: PropTypes.object,
  jobsCounters: PropTypes.object,
  handleFetchCounters: PropTypes.func,
  handleFetchMembers: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  company: companySelector,
  jobsCounters: jobsCountersSelector,
});

const mapDispatchToProps = (dispatch) => ({
  handleFetchCounters: debounce(
    (params) => dispatch(fetchCounters(params)),
    500
  ),
  handleFetchMembers: (filter) => dispatch(fetchAllMembers(filter)),
});

const withAuthentication = userIsAuthenticated;
const withPermission = userHasPermission;
const withReducer = injectReducer({ key: 'jobs', reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const RequisitionsContainer = compose(
  withAuthentication,
  withPermission,
  withReducer,
  withConnect
)(Jobs);
