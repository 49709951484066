export const MARKETPLACEJOBS_OVERVIEW_FETCH_REQUEST =
  'gustav/marketplace/jobOverview/FETCH_REQUEST';
export const MARKETPLACEJOBS_OVERVIEW_FETCH_SUCCESS =
  'gustav/marketplace/jobOverview/FETCH_SUCCESS';
export const MARKETPLACEJOBS_OVERVIEW_FETCH_ERROR =
  'gustav/marketplace/jobOverview/FETCH_ERROR';

export const MARKETPLACEJOBS_OVERVIEW_SAVE_JOB_REQUEST =
  'gustav/marketplace/saveJob/REQUEST';
export const MARKETPLACEJOBS_OVERVIEW_SAVE_JOB_SUCCESS =
  'gustav/marketplace/saveJob/SUCCESS';
export const MARKETPLACEJOBS_OVERVIEW_SAVE_JOB_ERROR =
  'gustav/marketplace/saveJob/ERROR';

export const MARKETPLACEJOBS_PRELOAD_CANDIDATE_REQUEST =
  'gustav/marketplace/supplyCandidate/preload/REQUEST';
export const MARKETPLACEJOBS_PRELOAD_CANDIDATE_SUCCESS =
  'gustav/marketplace/supplyCandidate/preload/SUCCESS';
export const MARKETPLACEJOBS_PRELOAD_CANDIDATE_ERROR =
  'gustav/marketplace/supplyCandidate/preload/ERROR';

export const MARKETPLACEJOBS_DUPLICATE_ASSETS_REQUEST =
  'gustav/marketplace/supplyCandidate/duplicateAssets/REQUEST';
export const MARKETPLACEJOBS_DUPLICATE_ASSETS_SUCCESS =
  'gustav/marketplace/supplyCandidate/duplicateAssets/SUCCESS';
export const MARKETPLACEJOBS_DUPLICATE_ASSETS_ERROR =
  'gustav/marketplace/supplyCandidate/duplicateAssets/ERROR';

export const MARKETPLACEJOBS_SUPPLY_CANDIDATE_REQUEST =
  'gustav/marketplace/supplyCandidate/REQUEST';
export const MARKETPLACEJOBS_SUPPLY_CANDIDATE_SUCCESS =
  'gustav/marketplace/supplyCandidate/SUCCESS';
export const MARKETPLACEJOBS_SUPPLY_CANDIDATE_ERROR =
  'gustav/marketplace/supplyCandidate/ERROR';

export const MARKETPLACEJOBS_CHANNEL_REQUEST =
  'gustav/marketplace/channel/REQUEST';
export const MARKETPLACEJOBS_CHANNEL_SUCCESS =
  'gustav/marketplace/channel/SUCCESS';

export const MARKETPLACEJOBS_SUPPLIED_CANDIDATES_REQUEST =
  'gustav/marketplace/candidates/REQUEST';
export const MARKETPLACEJOBS_SUPPLIED_CANDIDATES_SUCCESS =
  'gustav/marketplace/candidates/SUCCESS';
export const MARKETPLACEJOBS_SUPPLIED_CANDIDATES_ERROR =
  'gustav/marketplace/candidates/ERROR';

export const MARKETPLACEJOBS_SUPPLIED_CANDIDATE_REQUEST =
  'gustav/marketplace/candidate/REQUEST';
export const MARKETPLACEJOBS_SUPPLIED_CANDIDATE_SUCCESS =
  'gustav/marketplace/candidate/SUCCESS';
export const MARKETPLACEJOBS_SUPPLIED_CANDIDATE_ERROR =
  'gustav/marketplace/candidate/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_FETCH_REQUEST =
  'gustav/marketplace/questions/FETCH_REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_FETCH_SUCCESS =
  'gustav/marketplace/questions/FETCH_SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_FETCH_ERROR =
  'gustav/marketplace/questions/FETCH_ERROR';

export const MARKETPLACEJOBS_QUESTIONS_POST_QUESTION_REQUEST =
  'gustav/marketplace/questions/post/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_POST_QUESTION_SUCCESS =
  'gustav/marketplace/questions/post/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_POST_QUESTION_ERROR =
  'gustav/marketplace/questions/post/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_EDIT_QUESTION_REQUEST =
  'gustav/marketplace/questions/edit/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_EDIT_QUESTION_SUCCESS =
  'gustav/marketplace/questions/edit/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_EDIT_QUESTION_ERROR =
  'gustav/marketplace/questions/edit/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_DELETE_QUESTION_REQUEST =
  'gustav/marketplace/questions/delete/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_DELETE_QUESTION_SUCCESS =
  'gustav/marketplace/questions/delete/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_DELETE_QUESTION_ERROR =
  'gustav/marketplace/questions/delete/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_POST_ANSWER_REQUEST =
  'gustav/marketplace/questions/answer/post/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_POST_ANSWER_SUCCESS =
  'gustav/marketplace/questions/answer/post/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_POST_ANSWER_ERROR =
  'gustav/marketplace/questions/answer/post/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_EDIT_ANSWER_REQUEST =
  'gustav/marketplace/questions/answer/edit/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_EDIT_ANSWER_SUCCESS =
  'gustav/marketplace/questions/answer/edit/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_EDIT_ANSWER_ERROR =
  'gustav/marketplace/questions/answer/edit/ERROR';

export const MARKETPLACEJOBS_QUESTIONS_DELETE_ANSWER_REQUEST =
  'gustav/marketplace/questions/answer/delete/REQUEST';
export const MARKETPLACEJOBS_QUESTIONS_DELETE_ANSWER_SUCCESS =
  'gustav/marketplace/questions/answer/delete/SUCCESS';
export const MARKETPLACEJOBS_QUESTIONS_DELETE_ANSWER_ERROR =
  'gustav/marketplace/questions/answer/delete/ERROR';

export const MARKETPLACEJOBS_ASSIGNEES_UPDATE_REQUEST =
  'gustav/marketplace/job/assignees/UPDATE_REQUEST';
export const MARKETPLACEJOBS_ASSIGNEES_UPDATE_SUCCESS =
  'gustav/marketplace/job/assignees/UPDATE_SUCCESS';
export const MARKETPLACEJOBS_ASSIGNEES_UPDATE_ERROR =
  'gustav/marketplace/job/assignees/UPDATE_ERROR';

export const MARKETPLACEJOB_COUNTERS_SUCCESS =
  'gustav/marketplace/job/counters/SUCCESS';
