import PropTypes from 'prop-types';
import React from 'react';
import { isString } from 'lodash';

import { Colors } from 'components/utils/styles/ui';

import * as styled from './styles/breadcrumb';

export const Breadcrumb = function Breadcrumb({ className, routeTo, title }) {
  const linkTo = isString(routeTo) ? (
    <styled.Link to={`/${routeTo}`}>
      <styled.Icon color={Colors.text} icon="CaretLeft" />
    </styled.Link>
  ) : (
    <styled.Link onClick={() => routeTo()}>
      <styled.Icon color={Colors.text} icon="CaretLeft" />
    </styled.Link>
  );

  return (
    <styled.Breadcrumb className={className}>
      {linkTo}

      <styled.Label>{title}</styled.Label>
    </styled.Breadcrumb>
  );
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  routeTo: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};
