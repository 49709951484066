import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsFilterTitle: {
    id: 'app.components.Jobs.Draft.EmptyState.noResultsFilterTitle',
    defaultMessage: 'No matching jobs found',
  },
  noResultsFilterSubtitle: {
    id: 'app.components.Jobs.Draft.EmptyState.noResultsFilterSubtitle',
    defaultMessage:
      'The combination of filters you used has no results. Clear all filters or try different search criteria.',
  },
  resetFilters: {
    id: 'app.components.Jobs.Draft.EmptyState.resetFilters',
    defaultMessage: 'Clear all filters',
  },
  noDraftJobsTitle: {
    id: 'app.components.Jobs.Draft.noDraftJobsTitle',
    defaultMessage: 'You don’t have any draft jobs',
  },
  differentUserNoDraftJobsTitle: {
    id: 'app.components.Jobs.Draft.differentUserNoDraftJobsTitle',
    defaultMessage: "{first_name} doesn't have any draft jobs",
  },
  companyNoDraftJobsTitle: {
    id: 'app.components.Jobs.Draft.companyNoDraftJobsTitle',
    defaultMessage: "Your company doesn't have any draft jobs",
  },
  postNewJob: {
    id: 'app.components.Jobs.Draft.EmptyState.postNewJob',
    defaultMessage: 'Post new job',
  },
  switchToAllActiveJobsAction: {
    id: 'app.components.Jobs.Draft.EmptyState.switchToAllActiveJobsAction',
    defaultMessage: 'Go to all active jobs',
  },
  goToActiveJobsOrPostNewJobSubtitle: {
    id:
      'app.components.Jobs.Draft.EmptyState.goToActiveJobsOrPostNewJobSubtitle',
    defaultMessage: 'Go to "Active" jobs or post a new job now.',
  },
  postNewJobSubtitle: {
    id: 'app.components.Jobs.Draft.EmptyState.postNewJobSubtitle',
    defaultMessage: 'Post a job now to get started.',
  },
  switchToAnotherUserSubtitle: {
    id: 'app.components.Jobs.Draft.EmptyState.switchToAnotherUserSubtitle',
    defaultMessage: 'Switch to another user or post a new job now.',
  },
});
