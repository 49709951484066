import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import {
  isEmpty,
  isString,
  get,
  startsWith,
  endsWith,
  last,
  trim,
} from 'lodash';

import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import 'draft-js/dist/Draft.css';

import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';
import { isValid } from 'components/utils/form-utils';
import { Colors } from 'components/utils/styles/ui';

import { BlockStyleControls } from './BlockStyleControls';
import { InlineStyleControls } from './InlineStyleControls';
import * as styled from './styles';
import i18n from './utils/i18n';

const styleMap = {
  HIGHLIGHT: {
    backgroundColor: Colors.tealLight,
  },
};

const markdownToDraftOptions = {
  remarkableOptions: 'full',
  blockStyles: {
    ins_open: 'UNDERLINE',
    mark_open: 'HIGHLIGHT',
  },
};

const draftToMarkdownOptions = {
  styleItems: {
    UNDERLINE: {
      open: () => '++',
      close: () => '++',
    },
    HIGHLIGHT: {
      open: () => '==',
      close: () => '==',
    },
    CODE: {
      open: () => '',
      close: () => '',
    },
  },
  entityItems: {
    LINK: {
      open: () => '',
      close: () => '',
    },
  },
};

/* eslint react/no-did-update-set-state: 0 */
class RichTextEditorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: this.createEditorState(props.value),
    };
  }

  componentDidUpdate(prevProps) {
    const { value, suggestion } = this.props;

    if (value !== prevProps.value && value === suggestion) {
      this.setState({
        editorState: EditorState.moveFocusToEnd(
          this.createEditorState(suggestion)
        ),
      });
    }
  }

  handleFocus = () => this.rte.editor.focus();

  handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  handleBeforeInput = () => {
    const { maxlength } = this.props;
    const { editorState } = this.state;

    if (!maxlength) {
      return 'not-handled';
    }

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > maxlength - 1) {
      return 'handled';
    }
    return 'not-handled';
  };

  handlePastedText = (pastedText) => {
    const { maxlength } = this.props;
    const { editorState } = this.state;

    if (!maxlength) {
      return 'not-handled';
    }

    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this.getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      maxlength
    ) {
      return 'handled';
    }
    return 'not-handled';
  };

  getLengthOfSelectedText = () => {
    const { editorState } = this.state;
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);

      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  mergeTableBlocks = (rawBlocks) => {
    const blocks = [];

    rawBlocks.forEach((block) => {
      const lastBlock = last(blocks);
      if (
        startsWith(block.text, '|') &&
        endsWith(trim(get(lastBlock, 'text', '')), '|')
      ) {
        blocks[blocks.length - 1] = {
          ...lastBlock,
          text: `${lastBlock.text}\n${block.text}`,
        };
      } else {
        blocks.push(block);
      }
    });

    return blocks;
  };

  onChange = (editorState) => {
    this.setState({ editorState });
    this.updateValue(this.stateToMarkdown(editorState));
  };

  stateToMarkdown = (editorState) => {
    const raw = convertToRaw(editorState.getCurrentContent());
    return draftToMarkdown(
      { ...raw, blocks: this.mergeTableBlocks(raw.blocks) },
      draftToMarkdownOptions
    );
  };

  createEditorState = (value) => {
    const parsedMarkdown = markdownToDraft(value, markdownToDraftOptions);
    const convertedMarkdown = convertFromRaw(parsedMarkdown);

    return EditorState.createWithContent(convertedMarkdown);
  };

  updateValue(value) {
    const { onChange } = this.props;
    onChange(value);
  }

  toggleBlockType(blockType) {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleBlockType(editorState, blockType));
  }

  toggleInlineStyle(inlineStyle) {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  render() {
    const {
      intl,
      shouldValidate,
      required,
      inputType,
      height,
      placeholder = {},
      value,
      minHeight,
      maxHeight,
      maxlength = null,
      showCharactersCounter,
    } = this.props;
    const { editorState } = this.state;

    const currentContent = editorState.getCurrentContent();

    let placeholderText = '';
    if (!isEmpty(placeholder)) {
      placeholderText = isString(placeholder)
        ? placeholder
        : intl.formatMessage(placeholder);
    }

    const isInputValid = isValid({
      inputValue: value,
      shouldValidate,
      inputType,
      required,
    });

    return (
      <styled.Wrapper>
        <BlockStyleControls
          editorState={editorState}
          onToggle={(blockType) => this.toggleBlockType(blockType)}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={(inlineStyle) => this.toggleInlineStyle(inlineStyle)}
        />
        {shouldValidate && !isInputValid && (
          <ValidationErrorIndicator inputType="rte" />
        )}
        <styled.Textarea
          height={height}
          minHeight={minHeight}
          maxHeight={maxHeight}
          isValid={isInputValid}
          onClick={this.handleFocus}
          focus={this.rte?.editor === document.activeElement}
        >
          <Editor
            customStyleMap={styleMap}
            editorState={editorState}
            placeholder={placeholderText}
            onChange={this.onChange}
            handleKeyCommand={(command) => this.handleKeyCommand(command)}
            handleBeforeInput={this.handleBeforeInput}
            handlePastedText={this.handlePastedText}
            ref={(rte) => {
              this.rte = rte;
            }}
          />
        </styled.Textarea>

        {showCharactersCounter && maxlength && (
          <styled.CharactersCounter>
            <FormattedMessage
              {...i18n.charactersCounter}
              values={{
                currentCount: String(currentContent.getPlainText('').length),
                maxCharacters: maxlength,
              }}
            />
          </styled.CharactersCounter>
        )}
      </styled.Wrapper>
    );
  }
}

RichTextEditorComponent.propTypes = {
  intl: intlShape,
  placeholder: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.string,
  suggestion: PropTypes.string,
  height: PropTypes.string,
  required: PropTypes.bool,
  inputType: PropTypes.string,
  shouldValidate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  maxlength: PropTypes.number,
  showCharactersCounter: PropTypes.bool,
};

export const RichTextEditor = injectIntl(RichTextEditorComponent);
