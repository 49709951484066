import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const ChevronUpDown = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={24} size={size}>
      <path
        d="M18 14L12 20L6 14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
      <path
        d="M6 10L12 4L18 10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="transparent"
      />
    </IconBase>
  );
};

ChevronUpDown.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
