import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Badge } from 'components/misc/Badge';
import { withPreservedParams } from 'components/utils/url';

import i18n from './i18n';

export const contextHeaderDefinition = (intl, resource, filter, context) => {
  switch (`${resource}/${filter}`) {
    case 'jobs/':
    case 'jobs/active':
      return {
        title: intl.formatMessage(i18n.pageTitleJobs),
        icon: 'PlayCircle',
        badges: [
          <Badge header color="green">
            <FormattedMessage {...i18n.badgeActive} />
          </Badge>,
        ],
        helpMessage: intl.formatMessage(i18n.helpMessageAllCompanyJobs),
        helpLinks: [
          {
            path: 'https://help.hellogustav.com/en/articles/7169880-all-jobs',
            label: intl.formatMessage(i18n.helpLinkJobs),
          },
        ],
        actionButtons: [
          {
            label: intl.formatMessage(i18n.postNewJob),
            slug: 'new-job',
            icon: 'Lightning',
            color: 'primaryDark',
            path: '/job/new',
          },
        ],
      };

    case 'jobs/closed':
      return {
        title: intl.formatMessage(i18n.pageTitleJobs),
        icon: 'XCircle',
        badges: [
          <Badge header color="red">
            <FormattedMessage {...i18n.badgeClosed} />
          </Badge>,
        ],
        helpMessage: intl.formatMessage(i18n.helpMessageCompanyClosedJobs),
        helpLinks: [
          {
            path: 'https://help.hellogustav.com/en/articles/7169880-all-jobs',
            label: intl.formatMessage(i18n.helpLinkClosedJobs),
          },
        ],
        actionButtons: [
          {
            label: intl.formatMessage(i18n.postNewJob),
            slug: 'new-job',
            icon: 'Lightning',
            color: 'primaryDark',
            path: '/job/new',
          },
        ],
      };

    case 'jobs/draft':
      return {
        title: intl.formatMessage(i18n.pageTitleJobs, context),
        icon: 'PencilLine',
        badges: [
          <Badge header color="textLighter">
            <FormattedMessage {...i18n.badgeDraft} />
          </Badge>,
        ],
        helpMessage: intl.formatMessage(i18n.helpMessageCompanyDraftJobs),
        helpLinks: [
          {
            path: 'https://help.hellogustav.com/en/articles/7169880-all-jobs',
            label: intl.formatMessage(i18n.helpLinkDraftJobs),
          },
        ],
        actionButtons: [
          {
            label: intl.formatMessage(i18n.postNewJob),
            slug: 'new-job',
            icon: 'Lightning',
            color: 'primaryDark',
            path: '/job/new',
          },
        ],
      };

    case 'jobs/imported':
    case 'jobs/archived':
      return {
        title: intl.formatMessage(i18n.pageTitleJobs),
        icon: 'CloudArrowDown',
        badges: [
          <Badge header color="textLighter">
            <FormattedMessage {...i18n.badgeImported} />
          </Badge>,
        ],
        helpMessage: intl.formatMessage(i18n.helpMessageImportedJobs),
        helpLinks: [
          {
            path:
              'https://help.hellogustav.com/en/articles/3992819-imported-jobs',
            label: intl.formatMessage(i18n.helpLinkImportedJobs),
          },
        ],
        actionButtons: [
          {
            label: intl.formatMessage(i18n.postNewJob),
            slug: 'new-job',
            icon: 'Lightning',
            color: 'primaryDark',
            path: '/job/new',
          },
        ],
        menuContext: contextMenuImportedJobs(intl, filter, context),
        premiumFeature: 'integrations',
      };

    case 'jobs/candidates':
      return {
        title: intl.formatMessage(i18n.pageTitleCandidates),
        icon: 'Users',
        helpMessage: intl.formatMessage(i18n.helpMessageAllCandidates),
        helpLinks: [
          {
            path:
              'https://help.hellogustav.com/en/articles/7169879-candidates-received',
            label: intl.formatMessage(i18n.helpLinkCandidates),
          },
        ],
        actionButtons: [
          {
            label: intl.formatMessage(i18n.postNewJob),
            slug: 'new-job',
            icon: 'Lightning',
            color: 'primaryDark',
            path: '/job/new',
          },
        ],
      };

    default:
      return {
        title: intl.formatMessage(i18n.pageTitleJobs, context),
        icon: 'FileText',
      };
  }
};

const contextMenuImportedJobs = (intl, filter, context) => {
  const {
    counters: { imported, archived },
  } = context;

  return {
    parent: 'jobs',
    type: 'context',
    menuFor: 'jobs',
    active: filter,
    links: [
      {
        linkName: intl.formatMessage(i18n.menuJobsImported, {
          counter: imported,
        }),
        slug: 'imported',
        linkUrl: withPreservedParams('/jobs/imported', window.location.href, [
          'ats_member_id',
        ]),
      },
      {
        linkName: intl.formatMessage(i18n.menuJobsArchived, {
          counter: archived,
        }),
        slug: 'archived',
        linkUrl: withPreservedParams('/jobs/archived', window.location.href, [
          'ats_member_id',
        ]),
      },
    ],
  };
};
