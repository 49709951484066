import { fromJS } from 'immutable';
import {
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_UPDATE_SUCCESS,
  ACCOUNT_UPLOAD_AVATAR_SUCCESS,
  CANCEL_EMAIL_CHANGE_SUCCESS,
} from './constants';

const initialState = fromJS({
  account: {},
});

export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_FETCH_SUCCESS:
      return state.set('account', fromJS(action.payload.userData));

    case ACCOUNT_UPDATE_SUCCESS:
      return state.set('account', fromJS(action.payload.userData));

    case CANCEL_EMAIL_CHANGE_SUCCESS:
      return state.setIn(['account', 'pending_email'], null);

    case ACCOUNT_UPLOAD_AVATAR_SUCCESS:
      return state.setIn(['account', 'avatar'], action.payload.avatar);

    default:
      return state;
  }
}
