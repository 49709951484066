import React from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles';

export const Nag = function Nag(props) {
  const { leftAction, message, rightAction } = props;

  return (
    <styled.Container>
      {leftAction && (
        <styled.Left onClick={leftAction.onClick}>
          {leftAction.content}
        </styled.Left>
      )}
      <styled.Message>{message}</styled.Message>
      {rightAction && (
        <styled.Right onClick={rightAction.onClick}>
          {rightAction.content}
        </styled.Right>
      )}
    </styled.Container>
  );
};

Nag.propTypes = {
  message: PropTypes.string,
  leftAction: PropTypes.shape({
    content: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightAction: PropTypes.shape({
    content: PropTypes.string,
    onClick: PropTypes.func,
  }),
};

Nag.defaultProps = {
  message: '',
};
