export const SETUP_P20_USER_REQUEST = 'gustav/login/SETUP_P20_USER_REQUEST';
export const SETUP_P20_USER_SUCCESS = 'gustav/login/SETUP_P20_USER_SUCCESS';
export const SETUP_P20_USER_ERROR = 'gustav/login/SETUP_P20_USER_ERROR';

export const SETUP_BULLHORN_USER_REQUEST =
  'gustav/login/SETUP_BULLHORN_USER_REQUEST';
export const SETUP_BULLHORN_USER_SUCCESS =
  'gustav/login/SETUP_BULLHORN_USER_SUCCESS';
export const SETUP_BULLHORN_USER_ERROR =
  'gustav/login/SETUP_BULLHORN_USER_ERROR';

export const SETUP_ITSERVE_USER_REQUEST =
  'gustav/login/SETUP_ITSERVE_USER_REQUEST';
export const SETUP_ITSERVE_USER_SUCCESS =
  'gustav/login/SETUP_ITSERVE_USER_SUCCESS';
export const SETUP_ITSERVE_USER_ERROR = 'gustav/login/SETUP_ITSERVE_USER_ERROR';

export const SIGN_IN_REQUEST = 'gustav/login/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'gustav/login/SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'gustav/login/SIGN_IN_ERROR';

export const GET_SESSION_REQUEST = 'gustav/login/GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'gustav/login/GET_SESSION_SUCCESS';
export const GET_SESSION_ERROR = 'gustav/login/GET_SESSION_ERROR';

export const GET_SESSION_VIA_TOKEN_REQUEST =
  'gustav/login/GET_SESSION_VIA_TOKEN_REQUEST';
export const GET_SESSION_VIA_TOKEN_SUCCESS =
  'gustav/login/GET_SESSION_VIA_TOKEN_SUCCESS';
export const GET_SESSION_VIA_TOKEN_ERROR =
  'gustav/login/GET_SESSION_VIA_TOKEN_ERROR';
