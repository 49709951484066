import React from 'react';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';

import { ScopeFilter } from 'components/misc/ScopeFilter';
import { userID as getUserID } from 'utils/authorization/utils/auth';

export const ScopeFilterComponent = (props) => {
  const {
    currentFilters,
    members,
    companyCounter,
    dataManual,
    onChange,
  } = props;
  const getCurrentScopeFilterValue = () => {
    const value = get(find(currentFilters, { id: 'ats_member_id' }), 'value');

    if (value === getUserID()) {
      return null;
    }
    if (!value || value === 'company') {
      return 'company';
    }
    return { member: value };
  };

  const handleScopeChange = (value) => {
    if (value === 'company') {
      onChange([{ id: 'ats_member_id', value: 'company' }]);
    } else {
      onChange([{ id: 'ats_member_id', value: value.member }]);
    }
  };

  return (
    <ScopeFilter
      dataManual={dataManual}
      scope={getCurrentScopeFilterValue()}
      members={members}
      totalCounter={companyCounter}
      onChange={handleScopeChange}
    />
  );
};

ScopeFilterComponent.propTypes = {
  dataManual: PropTypes.string,
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  members: PropTypes.array,
  companyCounter: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
