import { defineMessages } from 'react-intl';

import theme from 'themes';

export default defineMessages({
  header: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.header',
    defaultMessage: 'Integrate with your ATS',
  },
  description: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.description',
    defaultMessage: `Connect ${theme.platformName} with your ATS (Bullhorn or JobDiva). This allows you to import jobs to ${theme.platformName} and sync candidates back to your ATS.`,
  },
  feature1: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.feature1',
    defaultMessage: `Sync jobs from your ATS to ${theme.platformName}`,
  },
  feature2: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.feature2',
    defaultMessage: `Sync candidate submission from ${theme.platformName} to your ATS`,
  },
  feature3: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.feature3',
    defaultMessage: `Map stages between ${theme.platformName} and your ATS. When you move a candidate in your ATS we automatically move them in ${theme.platformName} and notify your vendors. **(Bullhorn only)**`,
  },
  labelBullhorn: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.labelBullhorn',
    defaultMessage: 'Bullhorn',
  },
  labelJobdiva: {
    id: 'app.containers.Settings.Integrations.PremiumFeature.labelJobdiva',
    defaultMessage: 'JobDiva',
  },
});
