import React from 'react';
import PropTypes from 'prop-types';

import { UserBadge } from 'components/misc/UserBadge';
import { ComputedDate } from 'components/lists/Table';

const ComputedUserName = ({ value }) => <UserBadge user={value} />;

ComputedUserName.propTypes = {
  value: PropTypes.object,
};

export { ComputedUserName, ComputedDate };
