import { Component } from 'react';
import PropTypes from 'prop-types';

import { trackJsApplication } from 'utils/checkEnvironment';
import { commitRef } from 'utils/git-vars';

export const TrackJsAPI = (...args) => {
  if (window.TrackJS) {
    const [method, ...rest] = args;
    return window.TrackJS[method].apply(null, rest);
  }

  return null;
};

export class TrackJs extends Component {
  constructor(props) {
    super(props);

    ((w, d) => {
      const elem = d.createElement('script');
      elem.type = 'text/javascript';
      elem.src = '//cdn.trackjs.com/agent/v3/latest/t.js';
      elem.async = 'true';

      const init = () => this.initScript(w);
      if (elem.readyState) {
        // IE
        elem.onreadystatechange = () => {
          if (elem.readyState === 'loaded' || elem.readyState === 'complete') {
            elem.onreadystatechange = null;
            init();
          }
        };
      } else {
        // other browsers
        elem.onload = init;
      }

      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(elem, x);
    })(window, document);
  }

  shouldComponentUpdate() {
    return false;
  }

  initScript = (w) => {
    const { token } = this.props;

    if (w.TrackJS) {
      w.TrackJS.install({
        token,
        application: trackJsApplication(w),
        version: commitRef(),
      });
    }
  };

  render() {
    return null;
  }
}

TrackJs.propTypes = {
  token: PropTypes.string,
};
