import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import { Button, BUTTON_SIZES } from 'components/elements/button';
import { Icon } from 'components/elements/icon';
import { downloadFile } from 'utils/fileFunctions';
import emptyImageSrc from 'images/illustrations/eye.png';

import { PdfViewer } from './pdf';
import { ModalViewer } from './modal';
import { ImageViewer } from './image';
import * as styled from './styles';
import { extension, canPreview, canConvert, IMAGES_SUPPORTED } from './utils';
import i18n from './utils/i18n';

export { extension, canPreview, canConvert };

export const DocumentViewer = (props) => {
  const {
    url,
    downloadUrl,
    modal,
    renderer,
    onError,
    onClose,
    enableZoom,
    enableDownload,
  } = props;
  const ext = extension(url);
  const renderContent = () => {
    if (ext === 'pdf') {
      return (
        <PdfViewer
          file={url}
          onDocumentError={onError}
          enableZoom={enableZoom}
          {...DocumentViewer.defaultProps.renderer}
          {...renderer}
        />
      );
    }

    if (IMAGES_SUPPORTED.includes(ext)) {
      return (
        <ImageViewer
          url={url}
          onDocumentError={onError}
          enableZoom={enableZoom}
        />
      );
    }

    if (canConvert(ext)) {
      return (
        <styled.Wrapper {...renderer}>
          <styled.Preparing>
            <styled.Spinner />
            <styled.Header>
              <FormattedMessage {...i18n.preparingHeader} />
            </styled.Header>
            <styled.Subheader>
              <FormattedMessage {...i18n.preparingSubheader} />
            </styled.Subheader>
          </styled.Preparing>
        </styled.Wrapper>
      );
    }

    return (
      <styled.Failed>
        <img src={emptyImageSrc} role="presentation" alt="empty illustration" />
        <p>
          <FormattedMessage {...i18n.notSupported} />
        </p>
        {enableDownload && (
          <Button
            color="primaryDark"
            size={BUTTON_SIZES.medium}
            onClick={() => downloadFile(downloadUrl || url)}
          >
            <Icon icon="DownloadSimple" />
            <FormattedMessage {...i18n.download} />
          </Button>
        )}
      </styled.Failed>
    );
  };

  if (!modal) {
    return renderContent();
  }

  return (
    <ModalViewer url={downloadUrl || url} onClose={onClose}>
      {renderContent()}
    </ModalViewer>
  );
};

DocumentViewer.propTypes = {
  url: PropTypes.string,
  downloadUrl: PropTypes.string,
  modal: PropTypes.bool,
  renderer: PropTypes.shape({
    withScrollbars: PropTypes.bool,
    withBorder: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onError: PropTypes.func,
  onClose: PropTypes.func,
  enableZoom: PropTypes.bool,
  enableDownload: PropTypes.bool,
};

DocumentViewer.defaultProps = {
  modal: true,
  renderer: {
    withScrollbars: true,
    withBorder: false,
  },
  enableDownload: true,
  onError: noop,
  onClose: noop,
};
