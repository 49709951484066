import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, some } from 'lodash';

import { companyID } from 'utils/authorization/utils/auth';
import { Icon } from 'components/elements/icon';
import { ProgressBar } from 'components/misc/ProgressBar';
import { Tooltip } from 'components/overlay/Tooltip';
import { Highlight } from 'components/text/Highlight';

import { EditModal as Modal } from './modal';
import { Form } from './form';
import { AffiliationButtons } from './affiliationButtons';
import { Logo } from './logo';

import { isAffiliated, companyType } from '../utils/helpers';
import i18n from '../utils/i18n';
import * as styled from '../styles/info';
import {
  FormDefinitionInfo,
  FormDefinitionHubSwitch,
} from '../utils/formDefinition';
import { CompanyTypeButton } from './companyTypeButton';
import { CompleteProfileBox } from './completeProfileBox';

const LABELS_ICONS = {
  diversity_supplier: 'Users',
  goverment_contracts: 'Clipboard',
  small_business_certified: 'Clipboard',
  security_clearance: 'Clipboard',
};

/* eslint react/no-danger:0 */
export class Info extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  handleSave = (company) => {
    const { saveForm } = this.props;

    saveForm(company).then(() => {
      this.toggleModal(false);
    });
  };

  toggleHubActive = (value) => {
    this.handleSave({ hub_active: value });
  };

  isCompleted() {
    const { company } = this.props;

    return !some([company.company_size], isEmpty);
  }

  toggleModal(modal) {
    this.setState({ modal });
  }

  renderLabels(labels) {
    return labels.map((label) => (
      <styled.IconTag key={`label-${label}`}>
        <styled.Icon size="large" icon={LABELS_ICONS[label]} />
        <FormattedMessage {...i18n[label]} />
      </styled.IconTag>
    ));
  }

  renderLabelValue(value, definition) {
    const { edit } = this.props;
    return (
      <styled.Message
        isMissing={isEmpty(value) && (definition.required || !edit)}
      >
        <FormattedMessage {...(isEmpty(value) ? i18n.na : i18n[value])} />
      </styled.Message>
    );
  }

  renderModal() {
    const { company } = this.props;

    return (
      <Modal
        title={i18n.modalTitleInfo}
        onClose={() => this.toggleModal(false)}
      >
        <Form
          editObject={company}
          saveForm={this.handleSave}
          formDefinition={FormDefinitionInfo}
        />
      </Modal>
    );
  }

  render() {
    const {
      company,
      currentCompany,
      edit,
      isPublic,
      openModal,
      onRespondAffiliation,
      onRevokeAffiliation,
      blockedCompany,
      uploadLogo,
    } = this.props;
    const { modal } = this.state;
    const isSupplier = company.features.supplier;
    const isOwnCompany = company.id === companyID();

    return (
      <styled.Container>
        <styled.LeftSideWrapper>
          <styled.HeaderRow>
            <Logo edit={edit} company={company} uploadLogo={uploadLogo} />

            <styled.Header data-manual="profile.name">
              <styled.Title>
                {company.name}
                {company.account_verified && (
                  <styled.VerifiedBadge
                    icon="Check"
                    color="secondary"
                    size="xlarge"
                  />
                )}
              </styled.Title>
              <styled.Meta>
                {!isOwnCompany && (
                  <CompanyTypeButton
                    blockedCompany={blockedCompany}
                    company={company}
                    openModal={openModal}
                  />
                )}
                {isOwnCompany && (
                  <styled.Badge
                    size="normal"
                    companyType={companyType(company)}
                  />
                )}

                <styled.LabelsRow data-manual="profile.data">
                  <styled.InfoCell>
                    <span>
                      <FormattedMessage {...i18n.labelCompanySize} />
                    </span>
                    {this.renderLabelValue(
                      company.company_size,
                      FormDefinitionInfo.companySize
                    )}
                  </styled.InfoCell>

                  <styled.InfoCell>
                    <span>
                      <FormattedMessage {...i18n.labelRevenueSize} />
                    </span>
                    {this.renderLabelValue(
                      company.revenue_size,
                      FormDefinitionInfo.revenueSize
                    )}
                  </styled.InfoCell>
                  {edit && (
                    <styled.ButtonEdit
                      color={this.isCompleted() ? 'primary' : 'danger'}
                      onClick={() => this.toggleModal(true)}
                      type="blank"
                      size="large"
                    >
                      <Icon icon="NotePencil" />
                    </styled.ButtonEdit>
                  )}
                </styled.LabelsRow>
              </styled.Meta>
            </styled.Header>
          </styled.HeaderRow>

          <styled.SubHeader>
            <styled.LabelsRow data-manual="profile.data" wrappable>
              {this.renderLabels(company.labels)}
            </styled.LabelsRow>

            <styled.SubHeaderRow>
              {company.communities.map(({ id, label_html: labelHtml }) => (
                <styled.CommunityBadge
                  key={id}
                  size="normal"
                  dangerouslySetInnerHTML={{ __html: labelHtml }}
                />
              ))}
            </styled.SubHeaderRow>
          </styled.SubHeader>
        </styled.LeftSideWrapper>

        <styled.RightSideWrapper>
          {!isPublic &&
            !isOwnCompany &&
            !(blockedCompany || company.is_blocked) &&
            (company.hub_active || isAffiliated(company)) && (
              <AffiliationButtons
                company={company}
                currentCompany={currentCompany}
                openModal={openModal}
                onRespondAffiliation={onRespondAffiliation}
                onRevokeAffiliation={onRevokeAffiliation}
              />
            )}

          {!isPublic &&
            !isOwnCompany &&
            !(blockedCompany || company.is_blocked) &&
            !company.hub_active &&
            !isAffiliated(company) && (
              <styled.NotAcceptingRequestsInfoBox
                content={i18n.infoNotAcceptingRequests}
              />
            )}

          {edit && (
            <styled.Card>
              <styled.ProgressWrap>
                <styled.Label>
                  <FormattedMessage {...i18n.profileCompletion} />
                </styled.Label>
                <ProgressBar
                  hasLabel
                  progress={company.profile_completion}
                  fillColor="green"
                />
              </styled.ProgressWrap>
            </styled.Card>
          )}
          {edit && isSupplier && (
            <styled.Card>
              <styled.HubSwitchWrap>
                <styled.Label>
                  <FormattedMessage {...i18n.labelHubActive} />
                  <Tooltip
                    content={
                      <Highlight type="bold" text={i18n.tooltipHubActive} />
                    }
                  >
                    <Icon icon="Info" size="large" color="teal" />
                  </Tooltip>
                </styled.Label>
                <styled.SwitchFieldset
                  context={FormDefinitionHubSwitch.hubActive}
                  disabled={company.profile_completion < 100}
                  checked={company.hub_active}
                  fn={this.toggleHubActive}
                />
              </styled.HubSwitchWrap>
            </styled.Card>
          )}
          {company.profile_completion < 100 && isOwnCompany && (
            <CompleteProfileBox />
          )}
        </styled.RightSideWrapper>
        {edit && modal && this.renderModal()}
      </styled.Container>
    );
  }
}

Info.propTypes = {
  edit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  currentCompany: PropTypes.object,
  saveForm: PropTypes.func.isRequired,
  openModal: PropTypes.func,
  onRevokeAffiliation: PropTypes.func,
  onRespondAffiliation: PropTypes.func,
  isPublic: PropTypes.bool,
  uploadLogo: PropTypes.func.isRequired,
  blockedCompany: PropTypes.object,
};
