import React from 'react';
import PropTypes from 'prop-types';

import { userRole } from 'utils/authorization/utils/auth';

import { ScopeFilterComponent } from './ScopeFilter';
import * as styled from './styles';

export const CandidatesFilters = (props) => {
  const { currentFilters, members, companyCounter, onChange } = props;

  return (
    <styled.Wrapper>
      <div></div>
      {userRole() !== 'member' && (
        <ScopeFilterComponent
          dataManual="jobs.user-scope-filter"
          currentFilters={currentFilters}
          members={members}
          companyCounter={companyCounter}
          onChange={onChange}
        />
      )}
    </styled.Wrapper>
  );
};

CandidatesFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  members: PropTypes.array,
  companyCounter: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
