import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';
import { InputText as InputTextBase } from 'components/form/input/text';

export const Wrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: ${({ color }) => Colors[color]};

  & svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

export const Scope = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.8rem;
  min-width: 17.5rem;
  height: 3rem;
  border: 1px solid ${Colors.outline};
  border-radius: 0.3rem;
  font-size: 1.3rem;
  font-weight: 500;
  cursor: pointer;

  & > :first-child {
    margin-right: 0.8rem;
  }
`;

export const Expander = styled.div`
  align-items: center;
  display: flex;
  margin-left: auto;
  padding-left: 0.8rem;
`;

export const Content = styled.div`
  position: absolute;
  right: 0;
  z-index: 11;
  width: 35rem;
  margin-top: 0.6rem;
  background-color: ${Colors.white};
  border: 1px solid ${Colors.outline};
  border-radius: 0.3rem;

  & > :first-child {
    margin: 0.8rem 0.8rem 0 0.8rem;
    width: auto;
  }
`;

export const Input = styled(InputTextBase)`
  border: 1px solid ${Colors.outline};
`;

const scrollableMixin = css`
  max-height: 33rem;
  overflow-y: auto;
`;

export const List = styled.div`
  padding: 0.7rem 0;
  ${({ scrollable }) => scrollable && scrollableMixin}

  & + & {
    border-top: 1px solid ${Colors.outline};
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  height: 3.8rem;
  white-space: nowrap;
  cursor: pointer;

  ${({ active }) => active && 'font-weight: 500'};

  & > :first-child {
    margin-right: 1.4rem;
  }

  & > svg {
    margin-left: auto;
  }
`;

export const Avatar = styled(AvatarBase)``;

export const ItemName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Counter = styled.span`
  color: ${Colors.greyDarker};
`;

export const Divider = styled.div`
  margin: 0.6rem 1.2rem;
  border-top: 1px solid ${Colors.outline};
`;
