import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { omit, find, debounce } from 'lodash';
import { fitBounds } from 'google-map-react/utils';

import { injectReducer } from 'utils/injectReducer';
import { injectSaga } from 'utils/injectSaga';
import { filterSuppliers } from 'scenes/vendorHub/utils/filters';

import { supplierHubSaga as saga } from './sagas';
import i18n from './utils/i18n';
import { supplierHubReducer as reducer } from './reducer';
import {
  fetchIndustries,
  fetchSpecializations,
  fetchSuppliers,
  resetSuppliers,
  changeVisibility,
  openProfile,
  chooseSupplier,
  resetSearchConditions,
  changeSearchCondition,
  changeMapParams,
  changeLocation,
} from './actions';

import {
  industriesSelector,
  specializationsSelector,
  suppliersSelector,
  loadingSelector,
  searchResultsVisibleSelector,
  chosenSupplierIdSelector,
  searchConditionsSelector,
  mapParamsSelector,
  resultsLoadedSelector,
} from './selectors';
import { VendorHubComponent } from './VendorHub';

const excludedFilters = ['location'];

class SupplierHub extends Component {
  componentDidMount() {
    const {
      resultsLoaded,
      handleFetchIndustries,
      handleFetchSpecializations,
      handleResetSuppliers,
    } = this.props;
    if (!resultsLoaded) {
      handleFetchIndustries();
      handleFetchSpecializations();
      handleResetSuppliers();
      this.fetchSuppliers();
    }
  }

  onClickSupplierCard = ({ billing_address: { lat, lng }, id }) => {
    const { handleChangeMapParams, handleChooseSupplier } = this.props;
    handleChangeMapParams({
      center: { lat: parseFloat(lat), lng: parseFloat(lng) },
      zoom: 15,
    });
    handleChooseSupplier(id);
  };

  onChangeLocation = (place) => {
    if (!place) {
      return;
    }

    const { handleChangeMapParams, handleChangeLocation } = this.props;
    const { viewport } = place.geometry;
    const bounds = {
      ne: {
        lat: viewport.getNorthEast().lat(),
        lng: viewport.getNorthEast().lng(),
      },
      sw: {
        lat: viewport.getSouthWest().lat(),
        lng: viewport.getSouthWest().lng(),
      },
    };

    const size = {
      width: this.mapRef.clientWidth,
      height: this.mapRef.clientHeight,
    };

    const state = find(place.address_components, ({ types }) =>
      types.includes('administrative_area_level_1')
    );

    const city = find(place.address_components, ({ types }) =>
      types.includes('locality')
    );

    handleChangeMapParams(fitBounds(bounds, size));
    handleChangeLocation({ city, state });
  };

  onResetLocation = () => {
    const { handleChangeLocation } = this.props;
    return handleChangeLocation({ city: '', state: '' });
  };

  onOpenProfile = (supplier) => {
    const id = supplier.id || supplier;
    const { context, handleOpenProfile } = this.props;
    handleOpenProfile(
      context === 'external'
        ? `/public/company/profile/${id}`
        : `/partners/hub/profile/${id}`
    );
  };

  fetchSuppliers = () => {
    const { context, searchConditions, handleFetchSuppliers } = this.props;
    const isPublic = context === 'external';

    return handleFetchSuppliers(
      omit(searchConditions, excludedFilters),
      isPublic
    );
  };

  industriesOptions = () => {
    const { industries } = this.props;
    return [
      { linkName: i18n.industriesPlaceholder, slug: '' },
      ...industries.map((industry) => ({
        linkName: industry.name,
        slug: industry.id,
      })),
    ];
  };

  specializationsOptions = () => {
    const { specializations } = this.props;
    return [
      { linkName: i18n.specializationsPlaceholder, slug: '' },
      ...specializations.map((specialization) => ({
        linkName: specialization.name,
        slug: specialization.id,
      })),
    ];
  };

  render() {
    const {
      context = 'external',
      suppliers,
      loading,
      mapParams,
      searchResultsVisible,
      searchConditions,
      handleChangeSearchConditions,
      handleChangeVisibility,
      handleChooseSupplier,
      chosenSupplierId,
      handleResetSearchConditions,
      handleChangeMapParams,
      showCompleteProfileBanner,
    } = this.props;
    const includedFilters = omit(searchConditions, excludedFilters);
    const filtersApplied =
      Object.keys(includedFilters).filter((key) =>
        Boolean(includedFilters[key])
      ).length > 0;
    const shownSuppliers = filtersApplied
      ? filterSuppliers(suppliers, includedFilters)
      : suppliers;

    return (
      <VendorHubComponent
        context={context}
        industries={this.industriesOptions()}
        specializations={this.specializationsOptions()}
        suppliers={suppliers}
        shownSuppliers={shownSuppliers}
        loading={loading}
        searchResultsVisible={searchResultsVisible}
        clearFiltersVisible={Object.keys(searchConditions).some(
          (key) => searchConditions[key] !== ''
        )}
        searchConditions={searchConditions}
        onChangeSearchConditions={handleChangeSearchConditions}
        onChangeVisibility={handleChangeVisibility}
        onOpenProfile={this.onOpenProfile}
        onClickSupplierCard={this.onClickSupplierCard}
        onClickSupplierMarker={handleChooseSupplier}
        chosenSupplierId={chosenSupplierId}
        onClickMarkerInfoClose={() => handleChooseSupplier(null)}
        onResetFilters={handleResetSearchConditions}
        onChangeMapParams={handleChangeMapParams}
        onChangeLocation={this.onChangeLocation}
        onResetLocation={this.onResetLocation}
        onMapContainerRef={(ref) => {
          this.mapRef = ref;
        }}
        mapParams={mapParams}
        showCompleteProfileBanner={showCompleteProfileBanner}
      />
    );
  }
}

SupplierHub.propTypes = {
  context: PropTypes.oneOf(['internal', 'external']),
  industries: PropTypes.array,
  specializations: PropTypes.array,
  suppliers: PropTypes.array,
  loading: PropTypes.bool,
  searchResultsVisible: PropTypes.bool,
  chosenSupplierId: PropTypes.string,
  searchConditions: PropTypes.object,
  mapParams: PropTypes.object,
  resultsLoaded: PropTypes.bool,
  handleFetchIndustries: PropTypes.func.isRequired,
  handleFetchSpecializations: PropTypes.func.isRequired,
  handleFetchSuppliers: PropTypes.func.isRequired,
  handleResetSuppliers: PropTypes.func.isRequired,
  handleChangeVisibility: PropTypes.func.isRequired,
  handleOpenProfile: PropTypes.func.isRequired,
  handleChooseSupplier: PropTypes.func.isRequired,
  handleResetSearchConditions: PropTypes.func.isRequired,
  handleChangeSearchConditions: PropTypes.func.isRequired,
  handleChangeMapParams: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  showCompleteProfileBanner: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  handleFetchIndustries: () => dispatch(fetchIndustries()),
  handleFetchSpecializations: () => dispatch(fetchSpecializations()),
  handleFetchSuppliers: debounce(
    (filters, isPublic) => dispatch(fetchSuppliers({ filters, isPublic })),
    500
  ),
  handleResetSuppliers: () => dispatch(resetSuppliers()),
  handleChangeVisibility: () => dispatch(changeVisibility()),
  handleOpenProfile: (url) => dispatch(openProfile(url)),
  handleChooseSupplier: (id) => dispatch(chooseSupplier(id)),
  handleResetSearchConditions: () => dispatch(resetSearchConditions()),
  handleChangeSearchConditions: (name, value) =>
    dispatch(changeSearchCondition(name, value)),
  handleChangeMapParams: (mapParams) => dispatch(changeMapParams(mapParams)),
  handleChangeLocation: (params) => dispatch(changeLocation(params)),
});

const mapStateToProps = createStructuredSelector({
  industries: industriesSelector,
  specializations: specializationsSelector,
  suppliers: suppliersSelector,
  loading: loadingSelector,
  searchResultsVisible: searchResultsVisibleSelector,
  chosenSupplierId: chosenSupplierIdSelector,
  searchConditions: searchConditionsSelector,
  mapParams: mapParamsSelector,
  resultsLoaded: resultsLoadedSelector,
});

const withReducer = injectReducer({ key: 'supplierHub', reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: 'supplierHub', saga });

export const VendorHub = compose(
  withReducer,
  withConnect,
  withSaga
)(SupplierHub);
