import styled, { css } from 'styled-components';

import ReactTable from 'react-table';

import { occupiedHeight } from 'components/structure/page/utils/calculations';
import { Button } from 'components/elements/styles/button';
import { Activator } from 'components/overlay/Tooltip/styles/tooltip';
import { Colors, tableColors } from 'components/utils/styles/ui';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Toolbar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${({ extended }) => (extended ? 6.2 : 4)}rem;
  background-color: ${Colors.greyLighter};
  padding: 1rem 1rem 1rem 3rem;
`;

export const Count = styled.div`
  display: flex;
  align-items: center;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  height: auto;
  color: ${tableColors.countLabelColor};
  background-color: ${Colors.greyLighter};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 1rem;
`;

const scrollableCustomReactTableMixin = css`
  flex: 1 1 50rem;
  height: 50rem;
  overflow: hidden;
`;

const stickyPaginationCustomReactTableMixin = css`
  margin-top: 1.2rem;

  & > .pagination-bottom {
    position: sticky;
    bottom: 0;
    z-index: 100;
    border-top: 1px solid ${Colors.outline};
  }
`;

// * Make ReactTable flex to full height
// * `height` seems to be interpreted as min height
export const CustomReactTable = styled(ReactTable)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  ${({ scrollable }) => scrollable && scrollableCustomReactTableMixin}
  ${({ stickyPagination }) =>
    stickyPagination && stickyPaginationCustomReactTableMixin}
`;

export const Resizer = styled.div`
  display: inline-block;
  position: absolute;
  width: 1rem;
  top: 0;
  bottom: 0;
  right: -0.5rem;
  cursor: col-resize;
  z-index: 10;
  opacity: 0;
  transition: opacity 150ms ease;

  &:before {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    height: 2rem;
    width: 0.1rem;
    margin-top: -1rem;
    background-color: ${tableColors.resizerColor};
    content: '';
  }
`;

const scrollableTableMixin = css`
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4rem;
    content: '';
    background: linear-gradient(
      ${tableColors.paginationGradientTop},
      ${tableColors.paginationGradientBottom}
    );
    pointer-events: none;
  }
`;

export const Table = styled.div`
  position: relative;
  width: 100%;
  ${({ withToolbar }) => !withToolbar && 'padding-left: 2rem;'}
  display: flex;
  flex: auto 1;
  flex-direction: column;
  align-items: stretch;
  border-collapse: collapse;
  background-color: ${tableColors.backgroundColor};
  overflow: auto;

  ${({ scrollable }) => scrollable && scrollableTableMixin}
`;

const expanderTdMixin = css`
  padding-left: 0.5rem;
  padding-right: 0;
`;

const actionsFixedTdMixin = css`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: auto !important;
  height: 100%;
  justify-content: flex-end;

  &:before {
    content: '';
    position: absolute;
    width: 200%;
    height: 100%;
    top: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      rgba(245, 249, 250, 0),
      ${Colors.tealLighter} 50%
    );
  }
`;

export const Td = styled.div`
  position: relative;
  padding: 1.25rem 1rem;
  text-align: left;

  display: flex;
  flex: 1 0;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  color: ${tableColors.textColor};

  ${({ isExpander }) => isExpander && expanderTdMixin}
  ${({ withFixedActions }) => withFixedActions && actionsFixedTdMixin}
`;

const actionsFixedTrMixin = css`
  &:hover {
    position: relative;

    & ${Td} {
      display: flex;
    }
  }
`;

export const Tr = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  background-color: ${tableColors.backgroundColor};

  &:hover {
    background-color: ${tableColors.backgroundColorAccent};
  }

  ${({ withFixedActions }) => withFixedActions && actionsFixedTrMixin}
  ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

export const TrGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: ${({ empty }) => (empty ? '9999 0 auto' : '1 0 auto')};
`;

export const Sorter = styled.div`
  display: flex;
  align-items: center;

  svg {
    path {
      stroke: ${tableColors.sorterColor};
    }
  }
`;

const activeSortMixin = css`
  color: ${tableColors.sorterColorActive};

  ${Sorter}, ${Activator} {
    svg {
      path {
        stroke: ${tableColors.sorterColorActive};
      }
    }
  }
`;

const filterableToggleMixin = css`
  padding: 1.25rem 0 1.25rem 0.75rem;
  cursor: pointer;
`;

const actionsFixedThMixin = css`
  &,
  &:before {
    display: none;
  }
`;

export const Th = styled(Td)`
  display: flex;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'default')};
  overflow: visible;

  font-weight: 500;

  background-color: ${tableColors.backgroundColor};

  user-select: none;

  ${({ sorted }) => (sorted ? activeSortMixin : null)};

  ${({ isFilterToggle }) => isFilterToggle && filterableToggleMixin}
  ${({ withFixedActions }) => withFixedActions && actionsFixedThMixin}

  &:last-child {
    ${Resizer} {
      display: none;
    }
  }
`;

export const ThContent = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const Thead = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex: 1 0 auto;
  flex-direction: column;
  user-select: none;

  border-bottom: 0.1rem solid ${tableColors.rowBorderColorHead};

  &:hover {
    ${Resizer} {
      opacity: 1;
    }
  }
`;

export const Tfoot = Thead;

export const Tbody = styled.div`
  position: relative;
  flex: 99999 1 auto;

  ${Tr} {
    border-bottom: 0.1rem solid ${tableColors.rowBorderColorBody};
  }
`;

export const Loader = styled.div.attrs({ center: true })`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-items: flex-start;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${tableColors.overlayBackgroundColor};
  transition: all 0.3s ease;
  z-index: 2;
  opacity: 1;
  pointer-events: none;
`;

export const InlineText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: baseline;
  border-radius: 0.6rem;
  padding: 2rem;
  margin-top: 20rem;
`;

export const Expander = styled.div`
  user-select: none;
  cursor: pointer;
`;

export const Pagination = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  min-height: 5rem;
  padding: 1rem;
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;

  ${Button} {
    margin: 0 1rem;
  }
`;

export const Counter = styled.div`
  min-width: 12.2rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${Colors.text};
`;

export const PagesSpacer = styled.div`
  margin: 0 1rem;
  color: ${Colors.textLighter};

  ${({ theme }) => theme.typography.text.medium};
`;

export const PageArrow = styled(Button)`
  padding: 0.4rem;
  height: 2.4rem;
  width: 2.4rem;
`;

export const PageSize = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  select {
    min-width: 12.2rem;
    appearance: none;
  }
`;

export const PageSizeAction = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(100% - 2px);
  padding-right: 5px;
  background-color: ${Colors.white};
  border-radius: 0 0.5rem 0.5rem 0;
  cursor: pointer;
  pointer-events: none;
`;

export const NoData = styled(Loader).attrs({ center: false })`
  background-color: ${Colors.white};
  padding-top: 5rem;
  pointer-events: all;

  & > div {
    position: relative;
    bottom: 45%;
  }
`;

export const IntercomOverlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(
    100vh -
      ${({ withTableSearch }) =>
        occupiedHeight({
          withTableSearch,
          withTableMenu: true,
          withTableTabs: true,
        })}rem - 10.3rem
  );
  pointer-events: none;
`;

export const TooltipContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
