import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { EmptyState } from 'components/misc/EmptyState';
import { Button } from 'components/elements/button';
import { SmartLink } from 'components/navigation/SmartLink';

import { userRole } from 'utils/authorization/utils/auth';

import { switchToEveryoneAction } from '../../EmptyStatesShared/sharedActions';
import i18n from './utils/i18n';

const NoResultsQuery = ({
  onChangeFilters,
  userType,
  differentUserFirstName,
}) => {
  const createContent = () => {
    let title;
    let subTitle;

    const actions = [
      <SmartLink to="/job/new">
        <Button color="primary">
          <FormattedMessage {...i18n.postNewJob} />
        </Button>
      </SmartLink>,
    ];

    const goToAllActiveJobsAction = (
      <SmartLink to="/jobs/active">
        <Button color="primary">
          <FormattedMessage {...i18n.goToAllActiveJobsAction} />
        </Button>
      </SmartLink>
    );

    if (userType === 'owner') {
      title = i18n.noClosedJobsTitle;
      switch (userRole()) {
        case 'member':
          subTitle = i18n.goToActiveJobsOrPostNewJobSubtitle;
          actions.unshift(goToAllActiveJobsAction);

          return { title, subTitle, actions };
        default:
          subTitle = i18n.switchToAnotherUserSubtitle;
          actions.unshift(switchToEveryoneAction(onChangeFilters));

          return { title, subTitle, actions };
      }
    } else if (userType === 'differentUser' && userRole() !== 'member') {
      title = i18n.differentUserNoClosedJobsTitle;
      subTitle = i18n.switchToAnotherUserSubtitle;
      actions.unshift(switchToEveryoneAction(onChangeFilters));

      return { title, subTitle, actions };
    }

    title = i18n.companyNoClosedJobsTitle;
    subTitle = i18n.goToActiveJobsOrPostNewJobSubtitle;
    actions.unshift(goToAllActiveJobsAction);

    return { title, subTitle, actions };
  };

  const { title, subTitle, actions } = createContent();
  return (
    <EmptyState
      title={
        <FormattedMessage
          {...title}
          values={{ first_name: differentUserFirstName }}
        />
      }
      actions={actions}
      illustration="clipboard"
    >
      <FormattedMessage {...subTitle} />
    </EmptyState>
  );
};

const NoResultsFilter = ({ resetFilter, onChangeFilters, userType }) => {
  const actions = [
    <Button color="primary" onClick={resetFilter}>
      <FormattedMessage {...i18n.resetFilters} />
    </Button>,
  ];

  if (userType !== 'everyone' && userRole() !== 'member') {
    actions.push(switchToEveryoneAction(onChangeFilters));
  }

  return (
    <EmptyState
      actions={actions}
      title={<FormattedMessage {...i18n.noResultsFilterTitle} />}
    >
      <FormattedMessage {...i18n.noResultsFilterSubtitle} />
    </EmptyState>
  );
};

NoResultsFilter.propTypes = {
  resetFilter: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

NoResultsQuery.propTypes = {
  userType: PropTypes.string.isRequired,
  differentUserFirstName: PropTypes.string,
  onChangeFilters: PropTypes.func.isRequired,
};

export { NoResultsQuery, NoResultsFilter };
