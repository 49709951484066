import { defineMessages } from 'react-intl';

import theme from 'themes';

export default defineMessages({
  titleAbout: {
    id: 'app.containers.CompanyProfile.titleAbout',
    defaultMessage: 'About',
  },
  titleIndustry: {
    id: 'app.containers.CompanyProfile.titleIndustry',
    defaultMessage: 'Industry',
  },
  titleClientIndustries: {
    id: 'app.containers.CompanyProfile.titleClientIndustries',
    defaultMessage: 'Client industries',
  },
  titleJobDepartments: {
    id: 'app.containers.CompanyProfile.titleJobDepartments',
    defaultMessage: 'Job departments',
  },
  titleRoleSpecializations: {
    id: 'app.containers.CompanyProfile.titleRoleSpecializations',
    defaultMessage: 'Role specializations',
  },
  titleNotes: {
    id: 'app.containers.CompanyProfile.titleNotes',
    defaultMessage: 'Your internal notes',
  },
  titleTooltipNotes: {
    id: 'app.containers.CompanyProfile.titleTooltipNotes',
    defaultMessage:
      '**Your internal notes** are only visible for you and users of your company.',
  },
  titleDocuments: {
    id: 'app.containers.CompanyProfile.titleDocuments',
    defaultMessage: 'Your internal documents',
  },
  infoBoxDocuments: {
    id: 'app.containers.CompanyProfile.infoBoxDocuments',
    defaultMessage:
      '**Your internal documents** and **Your internal notes** are only visible for you and users of your company. The vendor company will not be able to access them.',
  },
  titleTooltipDocuments: {
    id: 'app.containers.CompanyProfile.titleTooltipDocuments',
    defaultMessage:
      '**Your internal documents** are only visible for you and users of your company.',
  },
  titleVerificationDocuments: {
    id: 'app.containers.CompanyProfile.titleVerificationDocuments',
    defaultMessage: 'Verified documents',
  },
  titleUsers: {
    id: 'app.containers.CompanyProfile.titleUsers',
    defaultMessage: 'Users',
  },
  ratingCardTitle: {
    id: 'app.containers.CompanyProfile.ratingCardTitle',
    defaultMessage: 'Rating',
  },
  detailsCardTitle: {
    id: 'app.containers.CompanyProfile.detailsCardTitle',
    defaultMessage: 'Details',
  },
  infoBoxHeader: {
    id: 'app.components.CompanyProfile.infoBoxHeader',
    defaultMessage:
      "You don't have the permission to edit your company profile.",
  },
  infoBoxContent: {
    id: 'app.components.CompanyProfile.infoBoxContent',
    defaultMessage:
      'Please ask one of your company admins or owners if you need to make changes.',
  },
  noAboutText: {
    id: 'app.components.CompanyProfile.noAboutText',
    defaultMessage: 'No About section provided',
  },
  emptyStateNoClientIndustries: {
    id: 'app.components.CompanyProfile.emptyStateNoClientIndustries',
    defaultMessage: 'No Client industries provided',
  },
  emptyStateNoRoleSpecializations: {
    id: 'app.components.CompanyProfile.emptyStateNoRoleSpecializations',
    defaultMessage: 'No Role specializations provided',
  },
  na: {
    id: 'app.containers.CompanyProfile.na',
    defaultMessage: 'n/a',
  },

  showMore: {
    id: 'app.containers.CompanyProfile.showMore',
    defaultMessage: '{count, number, integer} more',
  },

  profileCompletion: {
    id: 'app.containers.CompanyProfile.profileCompletion',
    defaultMessage: 'Profile completion',
  },
  tooltipHubActive: {
    id: 'app.containers.CompanyProfile.tooltipHubActive',
    defaultMessage:
      '**If set to active:**\n• Your company will be listed on the vendor hub \n• Other vendors can send you partner requests',
  },

  infoNotAcceptingRequests: {
    id: 'app.containers.CompanyProfile.infoNotAcceptingRequests',
    defaultMessage: 'This company doesn’t accept partner requests.',
  },

  infoRequestDisabled1: {
    id: 'app.containers.CompanyProfile.infoRequestDisabled1',
    defaultMessage: 'Please complete your\n',
  },

  infoRequestDisabled2: {
    id: 'app.containers.CompanyProfile.infoRequestDisabled2',
    defaultMessage: 'company profile',
  },
  infoRequestDisabled3: {
    id: 'app.containers.CompanyProfile.infoRequestDisabled3',
    defaultMessage: ' first in order to send vendor partner requests',
  },
  buttonRequest: {
    id: 'app.containers.CompanyProfile.buttonRequest',
    defaultMessage: 'Request as vendor partner',
  },
  buttonRequestSent: {
    id: 'app.containers.CompanyProfile.buttonRequestSent',
    defaultMessage: 'Sent request as vendor partner',
  },
  buttonRequestFollowingSent: {
    id: 'app.containers.CompanyProfile.buttonRequestFollowingSent',
    defaultMessage: 'Sent request to follow them',
  },
  buttonRequestFollowerSent: {
    id: 'app.containers.CompanyProfile.buttonRequestFollowerSent',
    defaultMessage: 'Sent request to follow you',
  },
  buttonRequestReceived: {
    id: 'app.containers.CompanyProfile.buttonRequestReceived',
    defaultMessage: 'Received request as vendor partner',
  },
  buttonRequestFollowingReceived: {
    id: 'app.containers.CompanyProfile.buttonRequestFollowingReceived',
    defaultMessage: 'Received request to follow you',
  },
  buttonRequestFollowerReceived: {
    id: 'app.containers.CompanyProfile.buttonRequestFollowerReceived',
    defaultMessage: 'Received request to follow them',
  },
  buttonRequestRevoke: {
    id: 'app.containers.CompanyProfile.buttonRequestRevoke',
    defaultMessage: 'Revoke request',
  },
  buttonRequestView: {
    id: 'app.containers.CompanyProfile.buttonRequestView',
    defaultMessage: 'View request',
  },

  buttonEmptyAbout: {
    id: 'app.containers.CompanyProfile.buttonEmptyAbout',
    defaultMessage: 'Start adding an about section',
  },
  buttonEmptyIndustry: {
    id: 'app.containers.CompanyProfile.buttonEmptyIndustry',
    defaultMessage: 'Start adding industry',
  },
  buttonEmptyClientIndustries: {
    id: 'app.containers.CompanyProfile.buttonEmptyClientIndustries',
    defaultMessage: 'Start adding client industries',
  },
  buttonEmptyRoleSpecializations: {
    id: 'app.containers.CompanyProfile.buttonEmptyRoleSpecializations',
    defaultMessage: 'Start adding role specializations',
  },
  buttonEmptyJobDepartments: {
    id: 'app.containers.CompanyProfile.buttonEmptyJobDepartments',
    defaultMessage: 'Start adding job departments',
  },

  save: {
    id: 'app.containers.CompanyProfile.modal.save',
    defaultMessage: 'Save',
  },
  modalTitleInfo: {
    id: 'app.containers.CompanyProfile.modal.modalTitleInfo',
    defaultMessage: 'Edit your info',
  },
  modalTitleAbout: {
    id: 'app.containers.CompanyProfile.modal.modalTitleAbout',
    defaultMessage: 'Edit your about section',
  },
  modalTitleIndustry: {
    id: 'app.containers.CompanyProfile.modal.modalTitleIndustry',
    defaultMessage: 'Edit your industry',
  },
  modalTitleClientIndustries: {
    id: 'app.containers.CompanyProfile.modal.modalTitleClientIndustries',
    defaultMessage: 'Select client industries',
  },
  modalTitleJobDepartments: {
    id: 'app.containers.CompanyProfile.modal.modalTitleJobDepartments',
    defaultMessage: 'Select job departments',
  },
  modalTitleRoleSpecializations: {
    id: 'app.containers.CompanyProfile.modal.modalTitleRoleSpecializations',
    defaultMessage: 'Select role specializations',
  },
  modalTitleAllClientIndustries: {
    id: 'app.containers.CompanyProfile.modal.modalTitleAllClientIndustries',
    defaultMessage: 'Client industries ({count})',
  },
  modalTitleAllJobDepartments: {
    id: 'app.containers.CompanyProfile.modal.modalTitleAllJobDepartments',
    defaultMessage: 'Job departments ({count})',
  },
  modalTitleAllRoleSpecializations: {
    id: 'app.containers.CompanyProfile.modal.modalTitleAllRoleSpecializations',
    defaultMessage: 'Role specializations ({count})',
  },

  updateSuccess: {
    id: 'app.containers.CompanyProfile.modal.updateSuccess',
    defaultMessage: 'Profile updated successfully',
  },
  uploadLogoSuccess: {
    id: 'app.containers.CompanyProfile.modal.uploadLogoSuccess',
    defaultMessage: 'Logo uploaded successfully',
  },
  uploadLogoError: {
    id: 'app.containers.CompanyProfile.modal.uploadLogoError',
    defaultMessage: "Logo couldn't be uploaded",
  },
  rateSuccess: {
    id: 'app.containers.CompanyProfile.modal.rateSuccess',
    defaultMessage: 'Company rated successfully',
  },

  labelCompanySize: {
    id: 'app.containers.CompanyProfile.form.labelCompanySize',
    defaultMessage: 'Company size',
  },
  labelRevenueSize: {
    id: 'app.containers.CompanyProfile.form.labelRevenueSize',
    defaultMessage: 'Revenue size',
  },
  labelCertifications: {
    id: 'app.containers.CompanyProfile.form.labelCertifications',
    defaultMessage: 'Certifications',
  },
  labelAbout: {
    id: 'app.containers.CompanyProfile.form.labelAbout',
    defaultMessage: 'About',
  },
  labelHeadquarter: {
    id: 'app.containers.CompanyProfile.form.labelHeadquarter',
    defaultMessage: 'Headquarters',
  },
  labelBillingAddress: {
    id: 'app.containers.CompanyProfile.form.labelBillingAddress',
    defaultMessage: 'Headquarters (city)',
  },
  labelWebsite: {
    id: 'app.containers.CompanyProfile.form.labelWebsite',
    defaultMessage: 'Website',
  },
  labelContactPerson: {
    id: 'app.containers.CompanyProfile.form.labelContactPerson',
    defaultMessage: 'Contact person',
  },
  labelContactPhone: {
    id: 'app.containers.CompanyProfile.form.labelContactPhone',
    defaultMessage: 'Contact person phone number',
  },
  labelPhone: {
    id: 'app.containers.CompanyProfile.form.labelPhone',
    defaultMessage: 'Phone number',
  },
  labelTeamSize: {
    id: 'app.containers.CompanyProfile.form.labelTeamSize',
    defaultMessage: 'Team size',
  },
  labelUserCount: {
    id: 'app.containers.CompanyProfile.form.labelUserCount',
    defaultMessage:
      '{count, plural, =0 {0 users} =1 {1 user} other {{count} users}}',
  },
  labelJoined: {
    id: 'app.containers.CompanyProfile.form.labelJoined',
    defaultMessage: 'Joined',
  },
  labelIndustry: {
    id: 'app.containers.CompanyProfile.form.labelIndustry',
    defaultMessage: 'Select industry',
  },
  labelIndustries: {
    id: 'app.containers.CompanyProfile.form.labelIndustries',
    defaultMessage:
      'Please select all the industries that you are recruiting for.',
  },
  labelDepartments: {
    id: 'app.containers.CompanyProfile.form.labelDepartments',
    defaultMessage:
      'Please select all the job departments that you are recruiting for.',
  },
  labelSpecializations: {
    id: 'app.containers.CompanyProfile.form.labelSpecializations',
    defaultMessage: 'Please select all the roles that you are specialized for.',
  },
  labelHubActive: {
    id: 'app.containers.CompanyProfile.form.labelHubActive',
    defaultMessage: 'Open for partner requests',
  },
  placeholderCompanySize: {
    id: 'app.containers.CompanyProfile.form.placeholderCompanySize',
    defaultMessage: 'Select company size',
  },
  placeholderRevenueSize: {
    id: 'app.containers.CompanyProfile.form.placeholderRevenueSize',
    defaultMessage: 'Select revenue size',
  },
  placeholderAbout: {
    id: 'app.containers.CompanyProfile.form.placeholderAbout',
    defaultMessage: 'About...',
  },
  placeholderBillingAddress: {
    id: 'app.containers.CompanyProfile.form.placeholderBillingAddress',
    defaultMessage: 'Enter the city where your headquarters is located',
  },
  placeholderWebsite: {
    id: 'app.containers.CompanyProfile.form.placeholderWebsite',
    defaultMessage: 'Enter your companies website',
  },
  placeholderContactPerson: {
    id: 'app.containers.CompanyProfile.form.placeholderContactPerson',
    defaultMessage: 'Select contact person',
  },
  placeholderIndustry: {
    id: 'app.containers.CompanyProfile.form.placeholderIndustry',
    defaultMessage: "Select company's industry",
  },
  placeholderIndustries: {
    id: 'app.containers.CompanyProfile.form.placeholderIndustries',
    defaultMessage: 'Search for industries',
  },
  placeholderDepartments: {
    id: 'app.containers.CompanyProfile.form.placeholderDepartments',
    defaultMessage: 'Search for departments',
  },
  placeholderSpecializations: {
    id: 'app.containers.CompanyProfile.form.placeholderSpecializations',
    defaultMessage: 'Search for specializations',
  },
  placeholderLogo: {
    id: 'app.containers.CompanyProfile.form.placeholderLogo',
    defaultMessage: 'Upload your company logo',
  },
  selectedCount: {
    id: 'app.containers.CompanyProfile.form.selectedCount',
    defaultMessage: 'Selected: {count, number, integer}',
  },
  descriptionAbout: {
    id: 'app.containers.CompanyProfile.form.descriptionAbout',
    defaultMessage:
      'Please describe your business in a few sentences. (maximum 800 characters)',
  },
  tooltipContactPhone: {
    id: 'app.containers.CompanyProfile.form.tooltipContactPhone',
    defaultMessage:
      "This phone number is taken from the contact person's account.",
  },
  tooltipWebsite: {
    id: 'app.containers.CompanyProfile.form.tooltipWebsite',
    defaultMessage: 'Add your website',
  },

  diversity_supplier: {
    id: 'app.containers.CompanyProfile.labels.diversitySupplier',
    defaultMessage: 'Diversity vendor',
  },
  goverment_contracts: {
    id: 'app.containers.CompanyProfile.labels.govermentContracts',
    defaultMessage: 'Government contracts',
  },
  small_business_certified: {
    id: 'app.containers.CompanyProfile.labels.smallBusinessCertified',
    defaultMessage: 'Small business certified',
  },
  security_clearance: {
    id: 'app.containers.CompanyProfile.labels.securityClearance',
    defaultMessage: 'Security clearance',
  },

  employees_0: {
    id: 'app.containers.CompanyProfile.companySize.employees0',
    defaultMessage: '0-1',
  },
  employees_2: {
    id: 'app.containers.CompanyProfile.companySize.employees2',
    defaultMessage: '2-10',
  },
  employees_11: {
    id: 'app.containers.CompanyProfile.companySize.employees11',
    defaultMessage: '11-50',
  },
  employees_51: {
    id: 'app.containers.CompanyProfile.companySize.employees51',
    defaultMessage: '51-200',
  },
  employees_201: {
    id: 'app.containers.CompanyProfile.companySize.employees201',
    defaultMessage: '201-500',
  },
  employees_501: {
    id: 'app.containers.CompanyProfile.companySize.employees501',
    defaultMessage: '501-1,000',
  },
  employees_1001: {
    id: 'app.containers.CompanyProfile.companySize.employees1001',
    defaultMessage: '1,001-5,000',
  },
  employees_5001: {
    id: 'app.containers.CompanyProfile.companySize.employees5001',
    defaultMessage: '5,001-10,000',
  },
  employees_10001: {
    id: 'app.containers.CompanyProfile.companySize.employees10001',
    defaultMessage: '10,001+',
  },

  revenue_0: {
    id: 'app.containers.CompanyProfile.revenue.revenue0',
    defaultMessage: '0-500K',
  },
  revenue_500k: {
    id: 'app.containers.CompanyProfile.revenue.revenue500k',
    defaultMessage: '500K-1M',
  },
  revenue_1m: {
    id: 'app.containers.CompanyProfile.revenue.revenue1m',
    defaultMessage: '1M-5M',
  },
  revenue_5m: {
    id: 'app.containers.CompanyProfile.revenue.revenue5m',
    defaultMessage: '5M-10M',
  },
  revenue_10m: {
    id: 'app.containers.CompanyProfile.revenue.revenue10m',
    defaultMessage: '10M-50M',
  },
  revenue_50m: {
    id: 'app.containers.CompanyProfile.revenue.revenue50m',
    defaultMessage: '50M-100M',
  },
  revenue_100m: {
    id: 'app.containers.CompanyProfile.revenue.revenue100m',
    defaultMessage: '100M+',
  },

  completeProfile: {
    id: 'app.containers.CompanyProfile.completeProfile',
    defaultMessage: 'Please fill out your company profile to 100% to:',
  },
  completeProfileContent1: {
    id: 'app.containers.CompanyProfile.completeProfileContent1',
    defaultMessage: 'Request and list candidates on the Hotlist.',
  },
  completeProfileContent2: {
    id: 'app.containers.CompanyProfile.completeProfileContent2',
    defaultMessage: `Discover and connect with new vendors on ${theme.platformName}’s vendor hub.`,
  },
  completeProfileContent3: {
    id: 'app.containers.CompanyProfile.completeProfileContent3',
    defaultMessage:
      'Share jobs globally with all vendors and submit candidates to global jobs.',
  },

  performanceToHeader: {
    id: 'app.containers.CompanyProfile.performance.performanceToHeader',
    defaultMessage: 'Jobs posted to {company}',
  },
  performanceFromHeader: {
    id: 'app.containers.CompanyProfile.performance.performanceFromHeader',
    defaultMessage: 'Jobs received from {company}',
  },
  performanceOverallHeader: {
    id: 'app.containers.CompanyProfile.performance.performanceOverallHeader',
    defaultMessage: 'Overall performance',
  },
  performanceYourActivity: {
    id: 'app.containers.CompanyProfile.performance.performanceYourActivity',
    defaultMessage: 'Your activity:',
  },
  performanceCompanyActivity: {
    id: 'app.containers.CompanyProfile.performance.performanceCompanyActivity',
    defaultMessage: '{company}’s activity:',
  },
  performanceJobsActivity: {
    id: 'app.containers.CompanyProfile.performance.performanceJobsActivity',
    defaultMessage: 'Jobs:',
  },
  performanceCandidatesActivity: {
    id:
      'app.containers.CompanyProfile.performance.performanceCandidatesActivity',
    defaultMessage: 'Candidate submissions:',
  },
  performanceToJobsPosted: {
    id: 'app.containers.CompanyProfile.performance.performanceToJobsPosted',
    defaultMessage: 'Total # of jobs posted to them',
  },
  performanceToJobsOpen: {
    id: 'app.containers.CompanyProfile.performance.performanceToJobsOpen',
    defaultMessage: '# of open jobs posted to them',
  },
  performanceToJobsLast: {
    id: 'app.containers.CompanyProfile.performance.performanceToJobsLast',
    defaultMessage: 'Last job posted to them',
  },
  performanceToJobsEngaged: {
    id: 'app.containers.CompanyProfile.performance.performanceToJobsEngaged',
    defaultMessage: 'Total # of your jobs engaged',
  },
  performanceToCandidatesSupply: {
    id:
      'app.containers.CompanyProfile.performance.performanceToCandidatesSupply',
    defaultMessage: '# of candidates submitted to you',
  },
  performanceToCandidatesLast: {
    id: 'app.containers.CompanyProfile.performance.performanceToCandidatesLast',
    defaultMessage: 'Last candidate submitted to you',
  },
  performanceFromJobsPosted: {
    id: 'app.containers.CompanyProfile.performance.performanceFromJobsPosted',
    defaultMessage: 'Total # of jobs posted to you',
  },
  performanceFromJobsOpen: {
    id: 'app.containers.CompanyProfile.performance.performanceFromJobsOpen',
    defaultMessage: '# of open jobs posted to you',
  },
  performanceFromJobsLast: {
    id: 'app.containers.CompanyProfile.performance.performanceFromJobsLast',
    defaultMessage: 'Last job posted to you',
  },
  performanceFromJobsEngaged: {
    id: 'app.containers.CompanyProfile.performance.performanceFromJobsEngaged',
    defaultMessage: 'Total # of jobs that you engaged',
  },
  performanceFromCandidatesSupply: {
    id:
      'app.containers.CompanyProfile.performance.performanceFromCandidatesSupply',
    defaultMessage: '# of candidates submitted to them',
  },
  performanceFromCandidatesLast: {
    id:
      'app.containers.CompanyProfile.performance.performanceFromCandidatesLast',
    defaultMessage: 'Last candidate submitted to them',
  },
  performanceOverallJobsPosted: {
    id:
      'app.containers.CompanyProfile.performance.performanceOverallJobsPosted',
    defaultMessage: 'Total # of jobs posted overall',
  },
  performanceOverallJobsOpen: {
    id: 'app.containers.CompanyProfile.performance.performanceOverallJobsOpen',
    defaultMessage: 'Total # of open jobs posted overall',
  },
  performanceOverallJobsLast: {
    id: 'app.containers.CompanyProfile.performance.performanceOverallJobsLast',
    defaultMessage: 'Last job posted overall',
  },
  performanceOverallJobsEngaged: {
    id:
      'app.containers.CompanyProfile.performance.performanceOverallJobsEngaged',
    defaultMessage: 'Total # of jobs engaged overall',
  },
  performanceOverallCandidatesSupply: {
    id:
      'app.containers.CompanyProfile.performance.performanceOverallCandidatesSupply',
    defaultMessage: '# of candidates submitted overall',
  },
  performanceOverallCandidatesLast: {
    id:
      'app.containers.CompanyProfile.performance.performanceOverallCandidatesLast',
    defaultMessage: 'Last candidate submitted overall',
  },

  placeholderNote: {
    id: 'app.containers.CompanyProfile.notes.placeholderNote',
    defaultMessage: 'Leave a note on the vendor profile',
  },
  buttonSubmit: {
    id: 'app.containers.CompanyProfile.notes.buttonSubmit',
    defaultMessage: 'Submit',
  },
  noteBy: {
    id: 'app.containers.CompanyProfile.notes.noteBy',
    defaultMessage: 'by {first_name} {last_name}',
  },
  noteType_blockCompany: {
    id: 'app.containers.CompanyProfile.notes.noteType_blockCompany',
    defaultMessage: 'Vendor blocking note:',
  },
  titleDeleteNote: {
    id: 'app.containers.CompanyProfile.notes.titleDeleteNote',
    defaultMessage: 'Delete note',
  },
  subtitleDeleteNote: {
    id: 'app.containers.CompanyProfile.notes.subtitleDeleteNote',
    defaultMessage: 'Are you sure you want to delete this note?',
  },
  titleEditNote: {
    id: 'app.containers.CompanyProfile.notes.titleEditNote',
    defaultMessage: 'Edit note',
  },
  buttonEditNote: {
    id: 'app.containers.CompanyProfile.notes.buttonEditNote',
    defaultMessage: 'Save',
  },

  titleDeleteDocument: {
    id: 'app.containers.CompanyProfile.documents.titleDeleteDocument',
    defaultMessage: 'Delete document',
  },
  subtitleDeleteDocument: {
    id: 'app.containers.CompanyProfile.documents.subtitleDeleteDocument',
    defaultMessage: 'Are sure you want to delete the document "{name}"?',
  },
  titleRenameDocument: {
    id: 'app.containers.CompanyProfile.documents.titleRenameDocument',
    defaultMessage: 'Rename document',
  },
  buttonSave: {
    id: 'app.containers.CompanyProfile.documents.buttonSave',
    defaultMessage: 'Save',
  },
  headerName: {
    id: 'app.containers.CompanyProfile.documents.headerName',
    defaultMessage: 'Name',
  },
  headerType: {
    id: 'app.containers.CompanyProfile.documents.headerType',
    defaultMessage: 'File type',
  },
  headerUploader: {
    id: 'app.containers.CompanyProfile.documents.headerUploader',
    defaultMessage: 'Uploaded by',
  },
  headerDate: {
    id: 'app.containers.CompanyProfile.documents.headerDate',
    defaultMessage: 'Date uploaded',
  },
  headerAmount: {
    id: 'app.containers.CompanyProfile.documents.headerAmount',
    defaultMessage: 'Amount ($)',
  },
  headerExpirationDate: {
    id: 'app.containers.CompanyProfile.documents.headerExpirationDate',
    defaultMessage: 'Expiration date',
  },

  buttonEdit: {
    id: 'app.containers.CompanyProfile.resources.buttonEdit',
    defaultMessage: 'Edit',
  },
  buttonDelete: {
    id: 'app.containers.CompanyProfile.resources.buttonDelete',
    defaultMessage: 'Delete',
  },
  buttonPreview: {
    id: 'app.containers.CompanyProfile.resources.buttonPreview',
    defaultMessage: 'Preview',
  },
  buttonDownload: {
    id: 'app.containers.CompanyProfile.resources.buttonDownload',
    defaultMessage: 'Download',
  },
  buttonRename: {
    id: 'app.containers.CompanyProfile.resources.buttonRename',
    defaultMessage: 'Rename',
  },
  buttonCancel: {
    id: 'app.containers.CompanyProfile.resources.buttonCancel',
    defaultMessage: 'Cancel',
  },

  certificate_of_insurance: {
    id: 'app.containers.CompanyProfile.documentType.certificate_of_insurance',
    defaultMessage: 'Certificate of insurance',
  },
  w_9: {
    id: 'app.containers.CompanyProfile.documentType.w_9',
    defaultMessage: 'W-9',
  },
  business_registration: {
    id: 'app.containers.CompanyProfile.documentType.business_registration',
    defaultMessage: 'Business registration',
  },

  ratingOverall: {
    id: 'app.containers.CompanyProfile.Ratings.ratingOverall',
    defaultMessage: 'Overall rating',
  },
  ratingYour: {
    id: 'app.containers.CompanyProfile.Ratings.ratingYour',
    defaultMessage:
      'Your rating{rating, select, 0.0 {} false {} other {: {rating}} }',
  },
  ratingCount: {
    id: 'app.containers.CompanyProfile.Ratings.ratingCount',
    defaultMessage:
      '({count, plural, =0 {0 ratings} =1 {1 rating} other {{count} ratings}})',
  },
  ratedOverall: {
    id: 'app.containers.CompanyProfile.Ratings.ratedOverall',
    defaultMessage: 'Rated {rating, number, float} overall',
  },
  ratingEdit: {
    id: 'app.containers.CompanyProfile.Ratings.ratingEdit',
    defaultMessage: 'edit',
  },

  tabAbout: {
    id: 'app.containers.CompanyProfile.Tabs.tabAbout',
    defaultMessage: 'About',
  },
  tabUsers: {
    id: 'app.containers.CompanyProfile.Tabs.tabUsers',
    defaultMessage: 'Team',
  },

  usersCount: {
    id: 'app.containers.CompanyProfile.Tabs.Users.usersCount',
    defaultMessage:
      '{count, plural, =0 {0 users} =1 {1 user} other {{count} users}}',
  },
  filterPlaceholder: {
    id: 'app.containers.CompanyProfile.Tabs.Users.filterPlaceholder',
    defaultMessage: 'Search by name, role and location',
  },
  filterButton: {
    id: 'app.containers.CompanyProfile.Tabs.Users.filterButton',
    defaultMessage: 'Search',
  },
  userName: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userName',
    defaultMessage: 'Name',
  },
  userRole: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userRole',
    defaultMessage: 'Job title',
  },
  userLocation: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userLocation',
    defaultMessage: 'Location',
  },
  userEmail: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userEmail',
    defaultMessage: 'Email address',
  },
  userPhone: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userPhone',
    defaultMessage: 'Phone',
  },
  userLastActivityAt: {
    id: 'app.containers.CompanyProfile.Tabs.Users.userLastActivityAt',
    defaultMessage: 'Last online',
  },

  features: {
    employer: {
      true: {
        buttonStatus: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.buttonStatus',
          defaultMessage: 'Following them',
        },
        requestHeader: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestHeader',
          defaultMessage: 'Stop following',
        },
        requestText: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestText',
          defaultMessage: 'You will no longer see their jobs.',
        },
      },
      false: {
        buttonStatus: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.buttonStatus',
          defaultMessage: 'Not following them',
        },
        requestHeader: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestHeader',
          defaultMessage: 'Request to follow them',
        },
        requestText: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestText',
          defaultMessage:
            'By following them you will be able to see their jobs and submit candidates to them.',
        },
      },
    },
    supplier: {
      true: {
        buttonStatus: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.buttonStatus',
          defaultMessage: 'Following you',
        },
        requestHeader: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestHeader',
          defaultMessage: 'Remove as follower',
        },
        requestText: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestText',
          defaultMessage:
            'They will no longer be able to submit candidates to your jobs.',
        },
      },
      false: {
        buttonStatus: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.buttonStatus',
          defaultMessage: 'Not following you',
        },
        requestHeader: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestHeader',
          defaultMessage: 'Request as follower',
        },
        requestText: {
          id: 'app.containers.CompanyProfile.AffiliationRequest.requestText',
          defaultMessage:
            'As a follower, they will be able to submit candidates to your jobs.',
        },
      },
    },
  },

  labelFollowYou: {
    id: 'app.containers.CompanyProfile.AffiliationRequest.labelFollowYou',
    defaultMessage: 'Requested to follow you',
  },
  labelFollowThem: {
    id: 'app.containers.CompanyProfile.AffiliationRequest.labelFollowThem',
    defaultMessage: 'Requested to follow them',
  },
  placeholderFollowYou: {
    id: 'app.containers.CompanyProfile.AffiliationRequest.placeholderFollowYou',
    defaultMessage:
      'As follower, {name} will be able to submit candidates to your jobs.',
  },
  placeholderFollowThem: {
    id:
      'app.containers.CompanyProfile.AffiliationRequest.placeholderFollowThem',
    defaultMessage:
      'By following {name} you will be able to see their jobs and submit candidates to them.',
  },
  blockVendor: {
    id: 'app.containers.CompanyProfile.BlockVendor.blockVendor',
    defaultMessage: 'Block vendor',
  },
  blockVendorDescription: {
    id: 'app.containers.CompanyProfile.BlockVendor.description',
    defaultMessage: 'You will no longer be visible to this vendor',
  },
  vendor: {
    id: 'app.containers.CompanyProfile.vendor',
    defaultMessage: 'Vendor',
  },
  vendorPartner: {
    id: 'app.containers.CompanyProfile.vendorPartner',
    defaultMessage: 'Vendor partner',
  },
  blockedVendor: {
    id: 'app.containers.CompanyProfile.blockedVendor',
    defaultMessage: 'Blocked vendor',
  },
  endClient: {
    id: 'app.containers.CompanyProfile.endClient',
    defaultMessage: 'End client',
  },
  unblockVendor: {
    id: 'app.containers.CompanyProfile.unblockVendor',
    defaultMessage: 'Unblock vendor',
  },
  unblockVendorDescription: {
    id: 'app.containers.CompanyProfile.unblockVendorDescription',
    defaultMessage: 'You will no longer be visible to this vendor',
  },
  blockVendorModalHeader: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.header',
    defaultMessage: 'Block {vendorName}',
  },
  blockVendorModalSubheader: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.subheader',
    defaultMessage: 'Are you sure you want to block {vendorName}?',
  },
  reasonHeader: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.reasonHeader',
    defaultMessage: 'Reason for blocking {vendorName}',
  },
  optional: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.optional',
    defaultMessage: 'optional',
  },
  reasonExplanation: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.reasonExplanation',
    defaultMessage:
      'Leave an internal note, why you blocked this vendor.\nThis will only be visible to members of your own company',
  },
  textareaPlaceholder: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.textareaPlaceholder',
    defaultMessage: 'Enter blocking note...',
  },
  cancel: {
    id: 'app.containers.CompanyProfile.BlockVendorModal.cancel',
    defaultMessage: 'Cancel',
  },
  blockVendorModalList: {
    jobsAndCandidates: {
      id:
        'app.containers.CompanyProfile.BlockVendorModal.wont.jobsAndCandidates',
      defaultMessage:
        "{vendorName} won't be able to see your jobs and submit candidates to them.",
    },
    hotlist: {
      id: 'app.containers.CompanyProfile.BlockVendorModal.wont.hotlist',
      defaultMessage:
        "{vendorName} won't be able to see your Hotlist candidates.",
    },
    vendonHub: {
      id: 'app.containers.CompanyProfile.BlockVendorModal.wont.vendonHub',
      defaultMessage:
        "{vendorName} won't be able to discover you on the Vendor Hub.",
    },
    community: {
      id: 'app.containers.CompanyProfile.BlockVendorModal.wont.community',
      defaultMessage: "{vendorName} won't be able to see you in any community.",
    },
  },
  blockWidgetHeader: {
    id: 'app.containers.CompanyProfile.BlockWidget.header',
    defaultMessage: 'Reason for blocking {vendorName}',
  },
  blockWidgetBlockedBy: {
    id: 'app.containers.CompanyProfile.BlockWidget.blockedBy',
    defaultMessage: 'Blocked by {user} - {date}',
  },
  editReason: {
    id: 'app.containers.CompanyProfile.BlockWidget.editReason',
    defaultMessage: 'Edit',
  },
  editBlockingReasonVendorModalHeader: {
    id: 'app.containers.CompanyProfile.EditBlockingReasonModal.header',
    defaultMessage: 'Edit the reason for blocking {vendorName}',
  },
  showBlockingReasonVendorModalHeader: {
    id: 'app.containers.CompanyProfile.ShowBlockingReasonModal.header',
    defaultMessage: 'Reason for blocking {vendorName}',
  },
  editBlockingReasonVendorModalSubheader: {
    id: 'app.containers.CompanyProfile.EditBlockingReasonModal.subheader',
    defaultMessage:
      'An internal note, why you blocked this vendor.\nThis will only be visible to members of your own company. ',
  },
  updateReasonButtonText: {
    id:
      'app.containers.CompanyProfile.EditBlockingReasonModal.updateReasonButtonText',
    defaultMessage: 'Update reason',
  },
  editReasonButtonText: {
    id:
      'app.containers.CompanyProfile.EditBlockingReasonModal.editReasonButtonText',
    defaultMessage: 'Edit reason',
  },
  unblockVendorModalHeader: {
    id: 'app.containers.CompanyProfile.UnblockVendorModal.header',
    defaultMessage: 'Unblock {vendorName}',
  },
  unblockVendorModalSubheader: {
    id: 'app.containers.CompanyProfile.UnblockVendorModal.subheader',
    defaultMessage: 'Are you sure you want to unblock {vendorName}?',
  },
  unblockVendorModalList: {
    discover: {
      id: 'app.containers.CompanyProfile.UnblockVendorModal.discover',
      defaultMessage:
        '{vendorName} may be able to discover you on the Vendor Hub, depending on if you are listed on the Vendor Hub.',
    },
    commonCommunity: {
      id: 'app.containers.CompanyProfile.UnblockVendorModal.commonCommunity',
      defaultMessage:
        '{vendorName} may be able to see your jobs and submit candidates to them, depending on if you are part of a common community.',
    },
    global: {
      id: 'app.containers.CompanyProfile.UnblockVendorModal.global',
      defaultMessage:
        '{vendorName} may be able to see your jobs and submit candidates to them, if you decide to share them as a global job.',
    },
  },
});
