import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles';

export const AnimatedText = function AnimatedText(props) {
  const { texts } = props;

  const [index, setIndex] = React.useState(0);
  const indexRef = useRef(index);
  indexRef.current = index;

  useEffect(() => {
    const increaseIndex = () => {
      if (indexRef.current < texts.length - 2) {
        setTimeout(increaseIndex, 3000);
      }
      setIndex((currentIndex) => currentIndex + 1);
    };
    setTimeout(increaseIndex, 3000);
  }, []);

  return (
    <styled.Wrapper>
      <styled.List index={index}>
        {texts.map((t) => (
          <styled.Text key={t}>{t}</styled.Text>
        ))}
      </styled.List>
    </styled.Wrapper>
  );
};

AnimatedText.propTypes = {
  texts: PropTypes.arrayOf(PropTypes.string),
};
