import { some } from 'lodash';

import { PUBLIC_PATHS } from 'utils/authorization/authConstants';

export const isPublicPath = ({ pathname }) => pathname.startsWith('/public');

export const isLoginOrSignupPath = ({ pathname }) =>
  pathname.startsWith('/login') || pathname.startsWith('/signup');

export const isUnauthorizedPath = ({ pathname }) =>
  some(
    PUBLIC_PATHS,
    (path) => pathname === path || pathname.match(new RegExp(path))
  );

export const isSettingPassword = (location) => {
  const { pathname, query: { redirect } = {} } = location;
  return (
    pathname.startsWith('/setpassword') ||
    pathname.startsWith('/resetpassword') ||
    pathname.startsWith('/forgotpassword') ||
    (redirect && redirect.startsWith('/setpassword'))
  );
};

export const isOnboarding = ({ pathname }) =>
  pathname.startsWith('/onboarding');

export const isConfirmEmail = ({ pathname }) =>
  pathname.startsWith('/confirm-email');

export const isLoggingOut = ({ pathname }) => pathname.startsWith('/logout');

export const isLoggingInToken = ({ pathname, search }) =>
  pathname === '/login' && search.match(/(login_token|email_confirm_token)=/);

export const isWithSidebarPath = (location) =>
  !isSettingPassword(location) &&
  !isConfirmEmail(location) &&
  !isPublicPath(location) &&
  !isLoggingOut(location) &&
  !isOnboarding(location);

export const isWithSubMenuPath = () => true;
