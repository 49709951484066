import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty, pick, camelCase } from 'lodash';

import { formatDate } from 'components/utils/date';
import { confirmAlert } from 'components/overlay/Confirm';
import { Overlay } from 'components/structure/overlay/overlay';
import { ButtonDropdownMenu } from 'components/navigation/ButtonDropdownMenu';
import noteImageSrc from 'images/svg/note.svg';
import boxImageSrc from 'images/svg/box.svg';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/notes';

export class Notes extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      newContent: '',
      shouldValidate: false,
      modal: null,
    };
    this.state = this.initialState;
  }

  handleCreateNote = (event) => {
    event.preventDefault();

    const { onCreateNote } = this.props;
    const { newContent } = this.state;

    this.setState({ shouldValidate: true });

    if (!isEmpty(newContent)) {
      onCreateNote({ content: newContent }).then(() => {
        this.setState(this.initialState);
      });
    }
  };

  handleDeleteNote = (note) => {
    const { intl, onDeleteNote } = this.props;

    confirmAlert({
      title: intl.formatMessage(i18n.titleDeleteNote),
      message: intl.formatMessage(i18n.subtitleDeleteNote),
      confirmLabel: intl.formatMessage(i18n.buttonDelete),
      cancelLabel: intl.formatMessage(i18n.buttonCancel),
      onConfirm: () => onDeleteNote(note),
    });
  };

  handleEditNote = (note) => {
    this.setState({ modal: note });
  };

  handleCancelEdit = () => {
    this.setState({ modal: null });
  };

  handleUpdateNote = (event) => {
    event.preventDefault();

    const { onUpdateNote } = this.props;
    const { modal } = this.state;
    const note = pick(modal, ['id', 'content']);

    if (!isEmpty(note.content)) {
      onUpdateNote(note).then(() => {
        this.setState({ modal: null });
      });
    }
  };

  canEditNote = (note) => {
    const { currentUser } = this.props;

    return (
      get(currentUser, 'id') === note.author.id ||
      ['Owner', 'Admin'].includes(get(currentUser, 'position'))
    );
  };

  renderNewForm() {
    const { newContent, shouldValidate } = this.state;

    return (
      <styledUI.Form>
        <styledUI.Textarea
          placeholder={i18n.placeholderNote}
          inputType="text"
          value={newContent}
          required
          shouldValidate={shouldValidate}
          onChange={(val) =>
            this.setState({ newContent: val, shouldValidate: true })
          }
        />
        <styledUI.ButtonWrapper>
          <styledUI.Submit
            value={i18n.buttonSubmit}
            onClick={this.handleCreateNote}
          />
        </styledUI.ButtonWrapper>
      </styledUI.Form>
    );
  }

  renderEditModal() {
    const { modal: note } = this.state;

    if (isEmpty(note)) {
      return null;
    }

    return (
      <Overlay closeOverlay={this.handleCancelEdit}>
        <styledUI.ModalView>
          <styledUI.Form>
            <styledUI.FormTitle>
              <FormattedMessage {...i18n.titleEditNote} />
            </styledUI.FormTitle>

            <styledUI.Textarea
              inputType="text"
              value={note.content}
              required
              shouldValidate
              onChange={(val) =>
                this.setState({ modal: { ...note, content: val } })
              }
            />

            <styledUI.ButtonWrapper>
              <styledUI.Submit
                value={i18n.buttonEditNote}
                onClick={this.handleUpdateNote}
              />
            </styledUI.ButtonWrapper>
          </styledUI.Form>
        </styledUI.ModalView>
      </Overlay>
    );
  }

  renderNoteItem = (note) => {
    const { id, type, content, author, inserted_at: insertedAt } = note;
    const menuItems = [
      {
        text: i18n.buttonEdit,
        onClick: () => this.handleEditNote(note),
        icon: 'PencilLine',
      },
      {
        text: i18n.buttonDelete,
        onClick: () => this.handleDeleteNote(note),
        icon: 'X',
      },
    ];

    return (
      <styledUI.Item key={`note-${id}`}>
        <styledUI.Image src={isEmpty(type) ? noteImageSrc : boxImageSrc} />
        <styledUI.ContentWrap>
          {!isEmpty(type) && (
            <styledUI.Type>
              <FormattedMessage {...i18n[`noteType_${camelCase(type)}`]} />
            </styledUI.Type>
          )}
          <styledUI.Content>{content}</styledUI.Content>
          <styledUI.Detail>
            {formatDate({ date: insertedAt, format: 'L [at] h:mma' })}
          </styledUI.Detail>
          <styledUI.User to={`/user/profile/${author.id}`}>
            <FormattedMessage {...i18n.noteBy} values={author} />
          </styledUI.User>
        </styledUI.ContentWrap>
        {this.canEditNote(note) && (
          <styledUI.Actions>
            <ButtonDropdownMenu items={menuItems} />
          </styledUI.Actions>
        )}
      </styledUI.Item>
    );
  };

  render() {
    const { notes } = this.props;

    return (
      <styled.Card bordered isSimple>
        <styled.Section
          title={<FormattedMessage {...i18n.titleNotes} />}
          tooltip={i18n.titleTooltipNotes}
          data-manual="profile.notes"
        >
          {this.renderNewForm()}
          {this.renderEditModal()}
          <styledUI.List>{notes.map(this.renderNoteItem)}</styledUI.List>
        </styled.Section>
      </styled.Card>
    );
  }
}

Notes.propTypes = {
  intl: PropTypes.object,
  currentUser: PropTypes.object,
  notes: PropTypes.array,
  onCreateNote: PropTypes.func,
  onUpdateNote: PropTypes.func,
  onDeleteNote: PropTypes.func,
};
