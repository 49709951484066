import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formHOC } from 'scenes/utils/form/hoc';

import * as styled from '../styles/logo';
import { FormDefinitionLogo } from '../utils/formDefinition';

@formHOC()
export class Logo extends Component {
  parseObject() {
    return { mainContainer: styled.Wrapper };
  }

  render() {
    const { company, edit, shouldValidate, uploadLogo } = this.props;

    return (
      <div data-manual="profile.picture">
        {edit && (
          <styled.ImageUpload
            image={company.logo}
            isImageLoading={company.isLoadingLogo}
            formDefinition={FormDefinitionLogo.logo}
            shouldValidate={shouldValidate}
            onUpload={uploadLogo}
            disabled
          />
        )}
        {!edit && (
          <styled.Image
            size="x2large"
            shape="square-rounded"
            color={company.color}
            context={{ picture: company.logo, initials: company.name?.[0] }}
          />
        )}
      </div>
    );
  }
}

Logo.propTypes = {
  shouldValidate: PropTypes.bool,
  edit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  uploadLogo: PropTypes.func.isRequired,
};
