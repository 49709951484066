import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { map, partition, isString, isNumber } from 'lodash';

import { Icon } from 'components/elements/icon';

import * as styled from './styles/replicate';

class ReplicateComponent extends Component {
  constructor(props) {
    super(props);

    this.key = null;
    this.addAnother = this.addAnother.bind(this);
  }

  addAnother(event) {
    event.preventDefault();
    event.stopPropagation();

    this.replicateObject();
  }

  objectChange(index, value) {
    const {
      objValues: { values },
      onChange,
    } = this.props;

    const objectsValues = map(values, (objValue) =>
      objValue.index === index ? { index, value } : objValue
    );

    onChange(objectsValues);
  }

  canDelete(index) {
    const {
      minItems,
      noLabelCount,
      deleteButtonType,
      deleteButtonColor,
    } = this.props;

    if (index <= minItems) {
      return null;
    }

    return (
      <styled.Delete
        index={index}
        noLabelCount={noLabelCount}
        type={deleteButtonType}
        color={deleteButtonColor}
        fn={() => this.deleteObject(index)}
      />
    );
  }

  canAdd() {
    const {
      itemsLimit,
      objValues: { values },
    } = this.props;

    return !isNumber(itemsLimit) || itemsLimit > values.length;
  }

  createObject(value, objIndex) {
    const {
      required,
      shouldValidate,
      noLabelCount,
      noSeparator,
      children,
    } = this.props;

    const props = {
      shouldValidate,
      required,
      onChange: (aValue) => this.objectChange(objIndex, aValue),
      [this.key]: value,
    };

    const object = React.cloneElement(children, props);

    const countLabel = noLabelCount ? null : (
      <styled.Label value={`${objIndex}.`} includeIndicator={false} />
    );

    return (
      <styled.Input
        index={objIndex}
        noSeparator={noSeparator}
        key={`input-replicate-${objIndex}`}
      >
        {countLabel}

        {object}

        {this.canDelete(objIndex)}
      </styled.Input>
    );
  }

  deleteObject(index) {
    const {
      onChange,
      onDelete,
      objValues: { values },
    } = this.props;

    const [[deletedValue], nonDeletedValues] = partition(values, { index });
    const newValues = map(nonDeletedValues, ({ value }, idx) => ({
      index: idx + 1,
      value,
    }));

    if (onDelete) {
      onDelete(deletedValue).then(() => onChange(newValues));
    } else {
      onChange(newValues);
    }
  }

  replicateObject() {
    const {
      defaultValue: {
        value: { value },
      },
      objValues: { values },
      onChange,
    } = this.props;

    values.push({
      index: values.length + 1,
      value,
    });

    onChange(values);
  }

  renderObjects() {
    const {
      objValues: { key, values },
    } = this.props;
    this.key = key;

    const renderedObjects = map(values, (value, index) =>
      this.createObject(value.value, index + 1)
    );

    return renderedObjects;
  }

  render() {
    const {
      intl,
      className,
      buttonLabel,
      objValues: { values = [] },
    } = this.props;

    const label = isString(buttonLabel)
      ? buttonLabel
      : intl.formatMessage(buttonLabel);

    return (
      <div className={className}>
        {this.renderObjects()}

        {this.canAdd() && (
          <styled.Button onClick={this.addAnother} itemsCount={values.length}>
            <Icon icon="Plus" />
            {label}
          </styled.Button>
        )}
      </div>
    );
  }
}

ReplicateComponent.propTypes = {
  className: PropTypes.string,
  /**
   * @description we must force children to be just an object, an input.
   */
  children: PropTypes.object,
  defaultValue: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  }),
  objValues: PropTypes.shape({
    key: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        value: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.object,
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
  }),
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  noLabelCount: PropTypes.bool,
  noSeparator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  itemsLimit: PropTypes.number,
  minItems: PropTypes.number,
  intl: PropTypes.object,
  onChange: PropTypes.func,
  deleteButtonType: PropTypes.string,
  deleteButtonColor: PropTypes.string,
  onDelete: PropTypes.func,
};

ReplicateComponent.defaultProps = {
  noLabelCount: false,
  noSeparator: false,
  minItems: 1,
  deleteButtonColor: 'danger',
};

export const Replicate = injectIntl(ReplicateComponent);
