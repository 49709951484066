import PropTypes from 'prop-types';
import React from 'react';

import * as styled from './styles/fieldset';
import { NamedDropdown } from '../named-dropdown/named-dropdown';

export const NamedDropdownFieldset = function NamedDropdownFieldset({
  className,
  context,
  includeIndicator,
  namedropdown,
  shouldValidate,
  fn,
}) {
  const { label, description, named, dropdown, required } = context;

  return (
    <styled.Fieldset className={className}>
      <styled.FormLabel
        margin="0 0 1rem"
        value={label}
        required={required}
        includeIndicator={includeIndicator}
      />

      <NamedDropdown
        description={description}
        named={named}
        dropdown={dropdown}
        namedropdown={namedropdown}
        shouldValidate={shouldValidate}
        required={required}
        onChange={(namedObject) => fn(namedObject)}
      />
    </styled.Fieldset>
  );
};

NamedDropdownFieldset.propTypes = {
  className: PropTypes.string,
  includeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  context: PropTypes.object,
  namedropdown: PropTypes.object,
  fn: PropTypes.func,
};

NamedDropdownFieldset.defaultProps = {
  includeIndicator: true,
};
