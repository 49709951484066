/* eslint-disable react/prop-types */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ComputedUserName, ComputedDate } from './cells';
import i18n from './i18n';

const usersColumns = [
  {
    Header: <FormattedMessage {...i18n.userName} />,
    id: 'name',
    accessor: (user) => user,
    Cell: ComputedUserName,
    minWidth: 160,
    sortable: true,
    filterable: false,
  },
  {
    Header: <FormattedMessage {...i18n.userRole} />,
    id: 'position',
    accessor: ({ position }) => position || <FormattedMessage {...i18n.na} />,
    minWidth: 100,
    sortable: true,
    filterable: false,
  },
  {
    Header: <FormattedMessage {...i18n.userLocation} />,
    id: 'location',
    accessor: ({ location }) =>
      location?.address || <FormattedMessage {...i18n.na} />,
    sortable: true,
    filterable: false,
  },
  {
    Header: <FormattedMessage {...i18n.userEmail} />,
    id: 'email',
    accessor: 'email',
    withTooltip: true,
    minWidth: 180,
    sortable: true,
    filterable: false,
  },
  {
    Header: <FormattedMessage {...i18n.userPhone} />,
    id: 'phone_number',
    accessor: 'phone_number',
    sortable: true,
    filterable: false,
  },
  {
    Header: <FormattedMessage {...i18n.userLastActivityAt} />,
    id: 'last_activity_at',
    accessor: 'last_activity_at',
    minWidth: 80,
    Cell: ComputedDate,
    sortable: true,
    filterable: false,
  },
];

export { usersColumns };
