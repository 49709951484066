import { defineMessages } from 'react-intl';

export default defineMessages({
  noActiveJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noActiveJobsTitle',
    defaultMessage: "You don't have any active jobs",
  },
  noOpenJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noOpenJobsTitle',
    defaultMessage: "You don't have any open jobs",
  },
  noPausedJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noPausedJobsTitle',
    defaultMessage: "You don't have any paused jobs",
  },
  switchToAnotherUserSubtitle: {
    id: 'app.components.Jobs.Active.EmptyState.SwitchToAnotherUserSubtitle',
    defaultMessage: 'Switch to another user or post a new job now.',
  },
  postNewJobSubtitle: {
    id: 'app.components.Jobs.Active.EmptyState.postNewJobSubtitle',
    defaultMessage: 'Post a job now to get started.',
  },
  switchStatusSubtitle: {
    id: 'app.components.Jobs.Active.switchStatusSubtitle',
    defaultMessage: 'Switch to another job status or post a new job now.',
  },
  noResultsQuerySubtitle: {
    id: 'app.components.Jobs.Active.EmptyState.noResultsQuerySubtitle',
    defaultMessage:
      "There aren't any active jobs. Start by creating a new one now.",
  },
  differentUserNoActiveJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.differentUserNoActiveJobsTitle',
    defaultMessage: "{first_name} doesn't have any active jobs",
  },
  differentUserNoOpenJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.differentUserNoOpenJobsTitle',
    defaultMessage: "{first_name} doesn't have any open jobs",
  },
  differentUserNoPausedJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.differentUserNoPausedJobsTitle',
    defaultMessage: "{first_name} doesn't have any paused jobs",
  },
  noActiveCompanyJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noActiveCompanyJobsTitle',
    defaultMessage: "Your company doesn't have any active jobs",
  },
  noOpenCompanyJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noOpenCompanyJobsTitle',
    defaultMessage: "Your company doesn't have any open jobs",
  },
  noPausedCompanyJobsTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noPausedCompanyJobsTitle',
    defaultMessage: "Your company doesn't have any paused jobs",
  },
  noCompanyJobsSubtitle: {
    id: 'app.components.Jobs.Active.EmptyState.noCompanyJobsSubtitle',
    defaultMessage: 'Start by posting a new job now.',
  },
  noResultsFilterTitle: {
    id: 'app.components.Jobs.Active.EmptyState.noResultsFilterTitle',
    defaultMessage: 'No matching jobs found',
  },
  noResultsFilterSubtitle: {
    id: 'app.components.Jobs.Active.EmptyState.noResultsFilterSubtitle',
    defaultMessage:
      'The combination of filters you used has no results. Clear all filters or try different search criteria.',
  },
  postNewJob: {
    id: 'app.components.Jobs.Active.EmptyState.postNewJob',
    defaultMessage: 'Post new job',
  },
  switchToAllAction: {
    id: 'app.components.Jobs.Active.EmptyState.switchToAllAction',
    defaultMessage: 'Switch to All',
  },
  resetFilters: {
    id: 'app.components.Jobs.Active.EmptyState.resetFilters',
    defaultMessage: 'Clear all filters',
  },
});
