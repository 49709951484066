import { map, pick, keys, has } from 'lodash';

const FIELDS_MAPPING = {
  companySize: 'company_size',
  revenueSize: 'revenue_size',
  labels: 'labels',
  about: 'about',
  website: 'website',
  contactPerson: 'contact_person_id',
  billingAddress: 'billing_address',
  industry: 'industry_ids',
  industries: 'industry_ids',
  departments: 'department_ids',
  specializations: 'specialization_ids',
};

const selectValue = function selectValue(object, attribute) {
  const val = object[attribute];
  return val !== 'none' ? val : null;
};

export const companyAsJSON = function companyAsJSON(
  companyObject,
  FormDefinition
) {
  const labels = map(companyObject.labels, 'value');
  const industry = selectValue(companyObject, 'industry');
  const industries = map(companyObject.industries, 'id');
  const departments = map(companyObject.departments, 'id');
  const specializations = map(companyObject.specializations, 'id');

  const parsedObject = {
    company_size: selectValue(companyObject, 'companySize'),
    revenue_size: selectValue(companyObject, 'revenueSize'),
    about: companyObject.about,
    labels,
    website: companyObject.website,
    contact_person_id: selectValue(companyObject, 'contactPerson'),
    billing_address: companyObject.billingAddress,
    industry_ids: has(FormDefinition, 'industry') ? [industry] : industries,
    department_ids: departments,
    specialization_ids: specializations,
  };

  return pick(
    parsedObject,
    map(keys(FormDefinition), (key) => FIELDS_MAPPING[key])
  );
};
