import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import i18n from './utils/i18n';
import { StyleControl } from './styleControl';
import * as styled from './styles';

export const InlineStyleControlsComponent = ({
  onToggle,
  editorState,
  intl,
}) => {
  const currentStyle = editorState.getCurrentInlineStyle();

  const INLINE_STYLES = useMemo(
    () => [
      {
        label: 'Bold',
        style: 'BOLD',
        icon: 'TextBolder',
        text: intl.formatMessage(i18n.bold),
      },
      {
        label: 'Italic',
        style: 'ITALIC',
        icon: 'TextItalic',
        text: intl.formatMessage(i18n.italic),
      },
      {
        label: 'Underline',
        style: 'UNDERLINE',
        icon: 'TextUnderline',
        text: intl.formatMessage(i18n.underline),
      },
      {
        label: 'Highlight',
        style: 'HIGHLIGHT',
        icon: 'PencilLine',
        text: intl.formatMessage(i18n.highlight),
      },
    ],
    []
  );

  return (
    <styled.ButtonSet
      onMouseDown={(event) => {
        event.preventDefault();
        onToggle();
      }}
    >
      {INLINE_STYLES.map((type) => (
        <StyleControl
          key={type.label}
          type={type}
          onToggle={onToggle}
          active={currentStyle.has(type.style)}
        />
      ))}
    </styled.ButtonSet>
  );
};

InlineStyleControlsComponent.propTypes = {
  editorState: PropTypes.any.isRequired,
  onToggle: PropTypes.func.isRequired,
  intl: PropTypes.object,
};

export const InlineStyleControls = injectIntl(InlineStyleControlsComponent);
