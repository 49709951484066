export const Constants = {
  USER_SIGNED_IN: 'USER_SIGNED_IN',
  CURRENT_USER: 'CURRENT_USER',
  USER_SIGNED_OUT: 'USER_SIGNED_OUT',
  BOARDS_FULL_RESET: 'BOARDS_FULL_RESET',

  OPEN_PAGE_LOADING: 'OPEN_PAGE_LOADING',
  CLOSE_PAGE_LOADING: 'CLOSE_PAGE_LOADING',

  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  OPEN_SIDEMODAL: 'gustav/App/ui/OPEN_SIDEMODAL',
  CLOSE_SIDEMODAL: 'gustav/App/ui/CLOSE_SIDEMODAL',

  FETCH_COUNTERS_REQUEST: 'gustav/Company/Counters/FETCH_REQUEST',
  FETCH_COUNTERS_SUCCESS: 'gustav/Company/Counters/FETCH_SUCCESS',
  FETCH_COUNTERS_ERROR: 'gustav/Company/Counters/FETCH_ERROR',
};
