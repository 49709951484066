import theme from 'themes';
import { INTEGRATION_FRAME_HEIGHT } from 'utils/constants';

/**
 * Checks if app is running on real production server, not only on server with NODE_ENV == 'production';
 * If window location origin matches theme.domain - it's production, otherwise it's local dev, staging or dev server (app.gustav.build).
 */

const hostnames = {
  localhost: 'dev',
  'app.gustav.build': 'dev',
  'demo.hellogustav.com': 'demo',
  'staging.hellogustav.com': 'staging',
  'staging-new.hellogustav.com': 'staging',
  'staging.gustav.build': 'staging',
  'fuse-staging.hellogustav.com': 'staging',
  'app.hellogustav.com': 'prod',
};

export const trackJsApplication = (window) =>
  window.location.hostname === 'fuse-staging.hellogustav.com'
    ? 'fuse-staging'
    : getServerEnv(window);

export const getServerEnv = (window) =>
  hostnames[window.location.hostname] || 'prod';

export const isProductionServer = (window) =>
  window.location.origin.endsWith(theme.domain);

export const isDevServer = (window) =>
  hostnames[window.location.hostname] === 'dev';

export const isDemoServer = (window) =>
  hostnames[window.location.hostname] === 'demo';

export const isStagingServer = (window) =>
  hostnames[window.location.hostname] === 'staging';

export const isIntegrationIframe = (window) => window.top !== window.self;

export const isStaleIntegrationIframe = (window) =>
  isIntegrationIframe(window) &&
  window.innerHeight === INTEGRATION_FRAME_HEIGHT;
