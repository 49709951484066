import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { EmptyState } from 'components/misc/EmptyState';
import { Button } from 'components/elements/button';
import { SmartLink } from 'components/navigation/SmartLink';

import i18n from './utils/i18n';

const NoResultsQuery = () => (
  <EmptyState
    title={<FormattedMessage {...i18n.noResultsQueryTitle} />}
    actions={[
      <SmartLink to="/jobs/archived">
        <Button color="primary">
          <FormattedMessage {...i18n.goToArchivedJobs} />
        </Button>
      </SmartLink>,
    ]}
    illustration="clipboard"
  >
    <FormattedMessage {...i18n.noResultsQuerySubtitle} />
  </EmptyState>
);

const NoResultsFilter = ({ resetFilter }) => (
  <EmptyState
    title={<FormattedMessage {...i18n.noResultsFilterTitle} />}
    actions={[
      <Button color="primary" onClick={resetFilter}>
        <FormattedMessage {...i18n.resetFilters} />
      </Button>,
    ]}
  >
    <FormattedMessage {...i18n.noResultsFilterSubtitle} />
  </EmptyState>
);

NoResultsFilter.propTypes = {
  resetFilter: PropTypes.func.isRequired,
};

export { NoResultsQuery, NoResultsFilter };
