import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { clamp, get, isEmpty, isEqual, find, noop } from 'lodash';

import { Flag } from 'components/elements/flag';
import { ValidationErrorIndicator } from 'components/form/validationErrorIndicator';
import { DynamicWidthInput } from 'components/form/dynamicWidthInput';
import { isValid as isFieldValid } from 'components/utils/form-utils';
import { currencySymbolFor } from 'components/utils/conversions';
import {
  formatAmount,
  moneyShape,
  moneyToNumber,
  isValidRangeMoneyInputs,
  isValidMinMaxRange,
} from 'components/utils/money';
import { currencies } from 'ui-definitions/currency';

import * as styled from './styles';

/* eslint react/no-did-update-set-state: 0 */
class InputMoneyComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      typed:
        props.inputType === 'money_range' ? props.value : props.value.amount,
      value: props.value,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, inputType } = this.props;

    if (value && (!prevState.value || !isEqual(value, prevProps.value))) {
      let typed = value.amount;
      if (inputType === 'money_range') {
        typed = value;
      }
      this.setState({ value, typed });
    }
  }

  onFocus = (event) => {
    const { onFocus } = this.props;

    this.setState({ focused: true });
    onFocus(event);
  };

  onBlur = (event) => {
    const { onBlur } = this.props;

    this.setState({ focused: false });
    onBlur(event);
  };

  updateAmount = (event, rangeType) => {
    const { onChange, inputType } = this.props;
    const { value, typed } = this.state;
    const newValue = this.formattedValue(
      {
        amount: event.target.value,
        currency: value.currency,
      },
      rangeType
    );

    let typedAmount = event.target.value;

    if (inputType === 'money_range' && rangeType) {
      typedAmount = { ...typed, [rangeType]: event.target.value };
    }

    this.setState({ value: newValue, typed: typedAmount });
    onChange(newValue);
  };

  updateCurrency = (currency) => {
    const { onChange } = this.props;
    const { value } = this.state;
    const newValue = {
      ...value,
      amount: value.amount,
      currency,
    };

    this.setState({ value: newValue });
    onChange(newValue);
  };

  decimals = (currencyCode) => {
    const { inputType } = this.props;
    const currency = find(currencies, { code: currencyCode });
    const decimals =
      inputType === 'money_decimal' || inputType === 'money_range';

    return decimals && currency.decimals;
  };

  formattedValue = ({ amount, currency }, rangeType) => {
    const { max, inputType } = this.props;
    const { value } = this.state;

    if (isEmpty(amount) && inputType !== 'money_range') {
      return { amount: null, currency };
    }

    const val = moneyToNumber(amount, this.decimals(currency));
    const clampedAmount = max ? clamp(val, 0, max) : val;

    if (inputType === 'money_range') {
      return {
        ...value,
        [rangeType]: clampedAmount,
        currency,
      };
    }

    return { amount: clampedAmount, currency };
  };

  displayValue(rangeType) {
    const { max, inputType } = this.props;
    const { value, typed } = this.state;
    const decimals = this.decimals(value.currency);

    let typedAmount = typed;

    if (inputType === 'money_range' && rangeType && typed) {
      typedAmount = typed[rangeType];
    }

    return formatAmount(
      max && moneyToNumber(typedAmount, decimals) > max ? max : typedAmount,
      decimals
    );
  }

  render() {
    const {
      intl,
      className,
      inputType,
      isValid,
      shouldValidate,
      required,
      placeholder,
      minPlaceholder,
      maxPlaceholder,
      readonly = false,
      disabled = false,
      min,
      max,
      onInnerRef,
      onKeyUp,
      onKeyPress,
      dataManual,
      size,
      validationErrorIndicator,
      currencySelector,
    } = this.props;
    const { value, focused } = this.state;
    const isInputValid = isValid
      ? isFieldValid({
          inputValue: value,
          inputType,
          shouldValidate,
          required,
          min,
          max,
          validateValueInputs,
        })
      : isValid;
    const currencyCode = get(value, 'currency', 'USD');
    const isMoneyRange = inputType === 'money_range';
    const currency = find(currencies, { code: currencyCode });
    const currencySymbol =
      get(currency, 'symbol') || currencySymbolFor(currencyCode);
    const currenciesOptions = currencies.map((c) => ({
      slug: c.code,
      textValue: `${c.name} ${c.code}`,
      linkName: (
        <>
          <span>
            <Flag country={c.flag} /> {c.name} - {c.symbol}
          </span>
          <styled.CurrencyCode>{c.code}</styled.CurrencyCode>
        </>
      ),
      selectedName: c.code,
    }));

    const validateValueInputs = (inputValue) =>
      isValidRangeMoneyInputs(inputValue) && isValidMinMaxRange(inputValue);

    return (
      <styled.MoneyContainer
        isValid={isInputValid}
        focused={focused}
        readOnly={readonly}
        disabled={disabled}
      >
        {shouldValidate && !isInputValid && validationErrorIndicator && (
          <ValidationErrorIndicator />
        )}

        <styled.CurrencySymbol noSymbol={isEmpty(currencySymbol)}>
          {currencySymbol}
        </styled.CurrencySymbol>
        {!isMoneyRange && (
          <styled.FixedInputMoney
            ref={(input) => {
              if (onInnerRef) {
                onInnerRef(input);
              }
            }}
            className={className}
            disabled={disabled}
            readOnly={readonly}
            value={this.displayValue()}
            placeholder={intl.formatMessage(placeholder)}
            isValid={isInputValid}
            onChange={this.updateAmount}
            onKeyUp={onKeyUp}
            onKeyPress={onKeyPress}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            data-manual={dataManual}
            size={size}
          />
        )}
        {isMoneyRange && (
          <styled.RangeInputsContainer
            className={className}
            data-manual={dataManual}
          >
            <DynamicWidthInput
              disabled={disabled}
              readOnly={readonly}
              value={this.displayValue('min')}
              placeholder={intl.formatMessage(minPlaceholder)}
              isValid={isInputValid}
              onChange={(event) => this.updateAmount(event, 'min')}
              onKeyUp={onKeyUp}
              onKeyPress={onKeyPress}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              size={size}
            />
            <styled.InputDivider />
            <DynamicWidthInput
              disabled={disabled}
              readOnly={readonly}
              value={this.displayValue('max')}
              placeholder={intl.formatMessage(maxPlaceholder)}
              isValid={isInputValid}
              onChange={(event) => this.updateAmount(event, 'max')}
              onKeyUp={onKeyUp}
              onKeyPress={onKeyPress}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              size={size}
            />
          </styled.RangeInputsContainer>
        )}

        {currencySelector ? (
          <styled.Select
            inputType="currency"
            selected={currencyCode}
            options={currenciesOptions}
            disabled={disabled}
            onChange={this.updateCurrency}
            size={size}
          />
        ) : (
          <styled.CurrencyIndicator>{currencyCode}</styled.CurrencyIndicator>
        )}
      </styled.MoneyContainer>
    );
  }
}

InputMoneyComponent.propTypes = {
  intl: intlShape,
  className: PropTypes.string,
  inputType: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  value: moneyShape,
  placeholder: PropTypes.object,
  minPlaceholder: PropTypes.object,
  maxPlaceholder: PropTypes.object,
  size: PropTypes.oneOf(['small', 'default']),
  isValid: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  onKeyPress: PropTypes.func,
  onInnerRef: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataManual: PropTypes.string,
  validationErrorIndicator: PropTypes.bool,
  currencySelector: PropTypes.bool,
};

InputMoneyComponent.defaultProps = {
  isValid: true,
  currencySelector: false,
  onFocus: noop,
  onBlur: noop,
};

export const InputMoney = injectIntl(InputMoneyComponent);
