import React, { useReducer, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';

import { get, find, isEmpty } from 'lodash';

import { openModal } from 'containers/App/actions/ui';
import { isOnboarding, isSettingPassword } from 'containers/App/utils/helpers';
import { isIntegrationIframe } from 'utils/checkEnvironment';
import {
  companySelector,
  companyChannelSelector,
} from 'containers/App/selectors';
import { userRole } from 'utils/authorization/utils/auth';
import { integrationsEnabled } from 'components/utils/subscriptionPlans';

import {
  fetchIntegrations,
  fetchIntegration,
  createIntegration,
  updateIntegration,
  deleteIntegration,
  validateCredentials,
  fetchResources,
  fetchResource,
  refreshSettings,
  forceSync,
} from './actions';
import {
  INTEGRATION_SYNC_UPDATE,
  INTEGRATION_SYNC_PROGRESS_UPDATE,
} from './constants';
import { integrationsReducer, initialState } from './reducer';

function isImportedJobDiva() {
  return window.location.pathname.startsWith('/job/imported/jobdiva');
}

function isBullhornSettingsPage() {
  return window.location.pathname.startsWith('/settings/integrations/bullhorn');
}

export const IntegrationsContext = createContext({});

const IntegrationsProviderComponent = (props) => {
  const {
    children,
    location,
    company,
    channel,
    handleOpenModal,
    handleRedirect,
  } = props;
  const [state, dispatch] = useReducer(integrationsReducer, initialState);

  useEffect(
    () =>
      !isEmpty(company) &&
      fetchIntegrations()(dispatch).then(({ integrations: data }) => {
        if (
          !isBullhornSettingsPage() &&
          !isSettingPassword(location) &&
          !isImportedJobDiva() &&
          !isOnboarding(location)
        ) {
          const integration = find(data, {
            type: 'bullhorn',
            connected: false,
          });

          if (
            integration &&
            (isIntegrationIframe(window) || userRole() === 'owner') &&
            integrationsEnabled(company)
          ) {
            handleOpenModal({ type: 'bullhornAuth' });
          }
        }
      }),
    [company]
  );

  useEffect(() => {
    if (channel) {
      channel.on('integration:sync_integration_update', (data) => {
        dispatch({
          type: INTEGRATION_SYNC_UPDATE,
          payload: data,
        });
      });
      channel.on('integration:sync_progress_update', (data) => {
        dispatch({
          type: INTEGRATION_SYNC_PROGRESS_UPDATE,
          payload: data,
        });
      });
    }
  }, [channel]);

  const onFetchIntegration = (type) => fetchIntegration(type)(dispatch);

  const onCreateIntegration = (type, integration) =>
    createIntegration(integration)(dispatch).then(() => {
      const bullhornAuthRedirect = sessionStorage.getItem(
        'BullhornAuthRedirect'
      );
      if (type === 'bullhorn' && bullhornAuthRedirect) {
        handleRedirect(bullhornAuthRedirect);
        sessionStorage.removeItem('BullhornAuthRedirect');
      } else {
        handleRedirect(`/settings/integrations/${type}`);
      }
    });

  const onUpdateIntegration = (id, integrationObj) =>
    updateIntegration(id, integrationObj)(dispatch);

  const onDeleteIntegration = (id) => deleteIntegration(id)(dispatch);

  const onValidateCredentials = (type, credentials) =>
    validateCredentials(type, credentials)(dispatch);

  const onFetchResources = (integrationId, resourceDetails) =>
    fetchResources(integrationId, resourceDetails)(dispatch);

  const onFetchResource = (integrationId, resourceDetails) =>
    fetchResource(integrationId, resourceDetails)(dispatch);

  const onRefreshSettings = (type) =>
    refreshSettings(get(state, `integrations.${type}.id`))(dispatch);

  const onForceSync = (id, params) => forceSync(id, params)(dispatch);

  const value = {
    integrations: state.integrations,
    loading: state.loading,
    onFetchIntegration,
    onCreateIntegration,
    onUpdateIntegration,
    onDeleteIntegration,
    onValidateCredentials,
    onFetchResources,
    onFetchResource,
    onRefreshSettings,
    onForceSync,
    onOpenBullhornAuthModal: () => handleOpenModal({ type: 'bullhornAuth' }),
  };

  return (
    <IntegrationsContext.Provider value={value}>
      {children}
    </IntegrationsContext.Provider>
  );
};

IntegrationsProviderComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object,
  company: PropTypes.object,
  channel: PropTypes.object,
  handleOpenModal: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  company: companySelector,
  channel: companyChannelSelector,
});

const mapDispatchToProps = (dispatch) => ({
  handleOpenModal: (modal) => dispatch(openModal(modal)),
  handleRedirect: (path) => dispatch(push(path)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export const IntegrationsProvider = compose(
  withConnect,
  withRouter
)(IntegrationsProviderComponent);
