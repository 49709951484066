import { defineMessages } from 'react-intl';

export default defineMessages({
  credentialsValidationSuccess: {
    id: 'app.containers.Integrations.credentialsValidationSuccess',
    defaultMessage: 'Credentials successfully stored',
  },
  invalidCredentials: {
    id: 'app.containers.Integrations.invalidCredentials',
    defaultMessage:
      'Login credentials cannot be verified. Please check them and try again.',
  },
});
