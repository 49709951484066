import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { ReactPageClick } from 'react-page-click';
import { escapeRegExp, debounce, isEmpty } from 'lodash';

import { Icon } from 'components/elements/icon';

import { icons } from './utils/list';
import * as styled from './styles';
import i18n from './utils/i18n';

const IconPickerComponent = (props) => {
  const {
    placeholder,
    intl,
    onChange,
    value,
    className,
    required,
    shouldValidate,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState(value);
  const [filtered, setFiltered] = useState(icons);
  const onSelect = (name) => {
    onChange(name);
    setSelected(name);
    setSearchTerm('');
    setExpanded(false);
  };
  const onExpand = (expand) => {
    setExpanded(expand);

    if (!expand) {
      setSearchTerm('');
    }
  };
  const hasErrors = required && shouldValidate && !selected;
  const filterIcons = useRef(
    debounce((newSearchTerm) => {
      const search = new RegExp(escapeRegExp(newSearchTerm), 'i');
      const newFiltered = icons.filter(
        ({ categories, tags, Icon: { displayName } }) =>
          tags.some((tag) => tag.match(search)) ||
          categories.some((category) => category.match(search)) ||
          displayName.match(search)
      );

      setFiltered(newFiltered);
    }, 50)
  );

  useEffect(() => {
    filterIcons.current(searchTerm);
  }, [searchTerm]);

  return (
    <ReactPageClick notify={() => onExpand(false)}>
      <styled.Wrapper className={className} data-test="icon-picker">
        <styled.Icon hasErrors={hasErrors} onClick={() => onExpand(true)}>
          {selected && <Icon icon={selected} weight="bold" />}
        </styled.Icon>
        {expanded && (
          <styled.IconPicker>
            <styled.Header>
              <FormattedMessage {...i18n.titleSelectIcon} />
            </styled.Header>
            <styled.InputWrapper>
              <Icon icon="MagnifyingGlass" color="textLighter" />
              <styled.Input
                autoFocus
                value={searchTerm}
                placeholder={intl.formatMessage(
                  placeholder || i18n.placeholder
                )}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              {!isEmpty(searchTerm) && (
                <styled.ClearIcon
                  icon="X"
                  color="textLighter"
                  onClick={() => setSearchTerm('')}
                />
              )}
            </styled.InputWrapper>
            {!isEmpty(filtered) && (
              <styled.Icons data-test="icon-picker.icons">
                {filtered.map(({ Icon: { displayName } }) => (
                  <styled.IconWrapper
                    key={displayName}
                    onClick={() => onSelect(displayName)}
                  >
                    <Icon icon={displayName} weight="bold" />
                  </styled.IconWrapper>
                ))}
              </styled.Icons>
            )}
            {isEmpty(filtered) && (
              <styled.NoResults>
                <styled.NoResultsIcon>
                  <Icon
                    icon="MagnifyingGlass"
                    size="large"
                    weight="bold"
                    color="tealDark"
                  />
                </styled.NoResultsIcon>
                <FormattedMessage {...i18n.noResults} />
              </styled.NoResults>
            )}
          </styled.IconPicker>
        )}
      </styled.Wrapper>
    </ReactPageClick>
  );
};

IconPickerComponent.propTypes = {
  intl: intlShape,
  className: PropTypes.string,
  placeholder: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  shouldValidate: PropTypes.bool,
};

export const IconPicker = injectIntl(IconPickerComponent);
