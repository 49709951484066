import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const UserTick = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={24} size={size}>
      <path
        d="M2.90521 20.2491C3.8273 18.6531 5.15316 17.3278 6.7496 16.4064C8.34605 15.485 10.1568 15 12.0001 15C13.8434 15 15.6541 15.4851 17.2506 16.4065C18.847 17.3279 20.1728 18.6533 21.0949 20.2493"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18 9.00001C17.9986 10.2444 17.6099 11.4575 16.8879 12.471C16.1658 13.4845 15.1462 14.2481 13.9705 14.6558C12.7948 15.0636 11.5213 15.0952 10.3268 14.7464C9.13228 14.3977 8.07595 13.6857 7.30439 12.7094C6.53284 11.733 6.08432 10.5407 6.02108 9.29794C5.95783 8.05515 6.283 6.82349 6.95146 5.77388C7.61992 4.72426 8.59852 3.90874 9.75146 3.44049C10.9044 2.97224 12.1745 2.87448 13.3855 3.16077"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M18 4L14.6667 8L13 6"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};

UserTick.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
