import React from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles/contentList';

export const ContentList = function ContentList(props) {
  const { items, type, isBordered, ...restProps } = props;

  /* eslint-disable react/no-array-index-key */
  const ListItems = items.reduce(
    (allItems, item, index) => [
      ...allItems,
      <styled.Item key={`item-${index}`} isBordered={isBordered}>
        {item}
      </styled.Item>,
    ],
    []
  );

  let List;
  switch (type) {
    case 'bullet':
      List = (
        <styled.OrderedList {...restProps}>{ListItems}</styled.OrderedList>
      );
      break;
    case 'number':
      List = (
        <styled.NumberedList {...restProps}>{ListItems}</styled.NumberedList>
      );
      break;
    default:
      List = <styled.List {...restProps}>{ListItems}</styled.List>;
      break;
  }

  return List;
};

ContentList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  type: PropTypes.oneOf(['bullet', 'number']),
  isBordered: PropTypes.bool,
};

ContentList.defaultProps = {
  items: [],
  type: null,
  isBordered: false,
};
