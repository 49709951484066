export const PUBLIC_PATHS = [
  '/login',
  '/itserve',
  '/404',
  '/401',
  '/forgotpassword',
  '/signup',
  '/logout',
  '/public/vendor-hub',
  '/public/company/profile/\\w*',
];

// TOP ADMIN LEVEL FOR ALL COMPANIES:
const commonAuthorizedPaths = [
  '/',
  '/setpassword',
  '/resetpassword',
  '/confirm-email',
  '/onboarding',
  '/onboarding/(welcome|set-password|create-company|about-yourself|personal-profile|company-profile|finished)',
];

// SHARED FEATURES FOR ALL COMPANY TYPES:
const commonFeaturesAuthorizedPaths = [
  '/account',
  '/account/user-profile',

  '/company/profile',
  '/company/profile/about',
  '/company/profile/users',
  '/company/profile/\\w*',
  '/company/profile/\\w*/about',
  '/company/profile/\\w*/users',

  '/user/profile',
  '/user/profile/\\w*',

  '/candidates',
  '/candidates/\\w*',
  '/candidates/\\w*/addto',
  '/candidates/\\w*/addto/job',
  '/candidates/\\w*/addto/circle',
  '/candidates/circles',
  '/candidates/circles/new',
  '/candidates/circles/\\w*',
  '/candidates/circles/\\w*/\\w*',
  '/candidates/circles/\\w*/edit',
  '/candidates/circles/\\w*/\\w*/addto',
  '/candidates/circles/\\w*/\\w*/addto/job',
  '/candidates/circles/\\w*/\\w*/addto/circle',

  '/work-orders',
  '/work-orders/pending',
  '/work-orders/upcoming',
  '/work-orders/active',
  '/work-orders/declined',
  '/work-orders/archived',
  '/work-orders/\\w+',

  '/hotlist',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/edit/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/activate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/duplicate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/candidate/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/request/\\w*',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/complete-profile',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/add',
  '/hotlist/(find|saved|requested|archived|active|draft|ended)/alerts/edit/\\w*',

  '/partners',
  '/partners/',
  '/partners/followers',
  '/partners/followers/new',
  '/partners/following',
  '/partners/following/new',
  '/partners/profile/\\w*',
  '/partners/profile/\\w*/about',
  '/partners/profile/\\w*/users',
  '/partners/requests',
  '/partners/requests/received',
  '/partners/requests/received/past',
  '/partners/requests/received/new',
  '/partners/requests/received/past/new',
  '/partners/requests/sent',
  '/partners/requests/sent/past',
  '/partners/requests/sent/new',
  '/partners/requests/sent/past/new',
  '/partners/requests/profile/\\w*',
  '/partners/requests/profile/\\w*/about',
  '/partners/requests/profile/\\w*/users',
  '/partners/hub',
  '/partners/hub/profile/\\w*',
  '/partners/hub/profile/\\w*/about',
  '/partners/hub/profile/\\w*/users',
  '/partners/circles',
  '/partners/circles/new',
  '/partners/circles/\\w+/edit',
  '/partners/circles/\\w*/details',
  '/partners/circles/\\w*/send-update',
  '/partners/circles/\\w*/details/add',
  '/partners/circles/\\w*/details/edit',
  '/partners/circles/\\w*/details/send-update',
  '/partners/circles/\\w*/details/\\w+/add-to-circle',
  '/partners/\\w+/\\w*/add-to-circle',
  '/partners/blocked',
  '/partners/blocked/profile/\\w*',
  '/partners/blocked/profile/\\w*/about',
  '/partners/blocked/profile/\\w*/users',

  '/partners/communities',
  '/partners/communities/list',
  '/partners/communities/profile/\\w*/members',
  '/partners/communities/profile/\\w*/guidelines',
  '/partners/communities/members/profile/\\w*',
  '/partners/communities/members/profile/\\w*/about',
  '/partners/communities/members/profile/\\w*/users',

  '/reporting',

  '/settings',
  '/settings/company',
  '/settings/members',
  '/settings/members/invite',
  '/settings/members/\\w*/remove',
  '/settings/company-profile',
  '/settings/company-profile/about',
  '/settings/company-profile/users',

  '/subscription-plan',
  '/subscription-plan/change',
  '/subscription-plan/setup',

  '/clients',
  '/clients/new',
  '/clients/find',
  '/clients/find/contacts',
  '/clients/\\w*',
  '/clients/\\w*/lists',
  '/clients/\\w*/contacts',
  '/clients/\\w*/contacts/new',
  '/clients/\\w*/contacts/\\w*/edit',
  '/clients/\\w*/contact/\\w*',
  '/clients/\\w*/contact/\\w*/edit',
];

const administratorAuthorizedPaths = [
  '/',
  '/admin',
  '/admin/job/\\w*',
  '/admin/jobs',

  '/admin/companies',
  '/admin/companies/list',
  '/admin/companies/users',
  '/admin/companies/invites',
  '/admin/companies/requests',
  '/admin/companies/list/merge',

  '/admin/company/\\w*',
  '/admin/company/\\w*/users',
  '/admin/company/\\w*/settings',
  '/admin/company/\\w*/documents',
  '/admin/company/\\w*/followers',
  '/admin/company/\\w*/following',

  '/admin/review',
  '/admin/review/jobs',
  '/admin/review/candidates',
  '/admin/review/suppliers',
  '/admin/review/company-documents',

  '/admin/integrations',

  '/admin/messages',
  '/admin/messages/failed',
  '/admin/messages/processed',

  '/admin/communities',
  '/admin/communities/create',
  '/admin/communities/list',
  '/admin/communities/\\w*',
  '/admin/communities/\\w*/details/edit',
  '/admin/communities/\\w*/details/members',
  '/admin/communities/\\w*/details/members/add',

  '/admin/subscription-plans',
  '/admin/subscription-plans/new',
  '/admin/subscription-plans/logs',
  '/admin/subscription-plans/\\w*',
  '/admin/subscription-plans/\\w*/edit',
  '/admin/subscription-plans/\\w*/companies',
  '/admin/subscription-plans/\\w*/companies/add',

  '/admin/hotlist',
  '/admin/hotlist/\\w*/match',
  '/admin/hotlist/\\w*/ui',
  '/admin/hotlist/\\w*/reindex',

  '/admin/data-analysis',

  // TODO: nest route under /admin
  '/job/edit/\\w*',
];

const employerAuthorizedPaths = [
  // TODO: these are redirects from old paths; remove when not needed anymore
  '/jobs/candidates/company',
  '/jobs/candidates/my',
  '/jobs/assigned',
  '/jobs/draft/my',
  '/jobs/draft/all',

  '/jobs',
  '/jobs/',
  '/jobs/candidates',
  '/jobs/active',
  '/jobs/closed',
  '/jobs/imported',
  '/jobs/archived',
  '/jobs/draft',
  '/job/new',
  '/job/edit/\\w*',
  '/job/duplicate/\\w*',
  '/job/activate/\\w*',
  '/job/imported/\\w*/\\w*',

  '/ats/\\w*',
  '/ats/\\w*/overview',
  '/ats/\\w*/applicants',
  '/ats/\\w*/questions',
  '/ats/\\w*/supply-candidate',
  '/ats/\\w*/supply-candidate/resupply',
  '/ats/\\w*/supply-candidate/cv',
  '/ats/\\w*/supply-candidate/form',
  '/ats/\\w*/supply-candidate/\\w*/submit',

  '/ats/\\w*/applicants/profile/\\w*',
  '/ats/\\w*/applicants/profile/\\w*/score',
  '/ats/\\w*/applicants/profile/\\w*/description',
  '/ats/\\w*/applicants/profile/\\w*/messenger',
  '/ats/\\w*/applicants/profile/\\w*/activity',
  '/ats/\\w*/applicants/profile/\\w*/documents',
  '/ats/\\w*/applicants/profile/\\w*/contact',
  '/ats/\\w*/applicants/profile/\\w*/skills',

  '/settings/requisition',
  '/settings/job',
  '/settings/integrations',
  '/settings/integrations/\\w*',
  '/settings/integrations/\\w*/jobs',

  '/account/notifications',
  '/account/user-profile',

  '/integrations/bullhorn',

  '/work-orders/new/\\w+',
];

const supplierAuthorizedPaths = [
  // TODO: these are redirects from old paths; remove when not needed anymore
  '/marketplace/all',
  '/marketplace/all/all',
  '/marketplace/all/partners',
  '/marketplace/all/communities',
  '/marketplace/all/all/notifications',
  '/marketplace/all/partners/notifications',
  '/marketplace/all/communities/notifications',
  '/marketplace/partners',
  '/marketplace/partners/all',
  '/marketplace/partners/partners',
  '/marketplace/partners/communities',
  '/marketplace/partners/all/notifications',
  '/marketplace/partners/partners/notifications',
  '/marketplace/partners/communities/notifications',
  '/marketplace/global',
  '/marketplace/global/all',
  '/marketplace/assigned/all',
  '/marketplace/assigned/global',
  '/marketplace/assigned/partners',
  '/marketplace/assigned/communities',
  '/marketplace/assigned/all/notifications',
  '/marketplace/assigned/partners/notifications',
  '/marketplace/assigned/communities/notifications',
  '/marketplace/supplied-candidates/user',
  '/marketplace/supplied-candidates/company',

  '/marketplace',
  '/marketplace/discover',
  '/marketplace/discover/notifications',
  '/marketplace/saved',
  '/marketplace/saved/notifications',
  '/marketplace/assigned',
  '/marketplace/assigned/notifications',
  '/marketplace/supplied-candidates',
  '/marketplace/supplied-candidates/\\w*',
  '/marketplace/supplied-candidates/\\w*/description',
  '/marketplace/supplied-candidates/\\w*/messenger',
  '/marketplace/supplied-candidates/\\w*/documents',
  '/marketplace/supplied-candidates/\\w*/contact',
  '/marketplace/\\w*',
  '/marketplace/\\w*/',
  '/marketplace/\\w*/description',
  '/marketplace/\\w*/supply-candidate',
  '/marketplace/\\w*/supply-candidate/resupply',
  '/marketplace/\\w*/supply-candidate/cv',
  '/marketplace/\\w*/supply-candidate/form',
  '/marketplace/\\w*/supply-candidate/\\w*/submit',
  '/marketplace/\\w*/supplied-candidates',
  '/marketplace/\\w*/questions',

  '/ats/\\w*',
  '/ats/\\w*/overview',
  '/ats/\\w*/applicants',
  '/ats/\\w*/questions',
  '/ats/\\w*/applicants/profile/\\w*/messenger',

  '/settings/documents',

  '/account/notifications',
  '/account/user-profile',

  '/settings/integrations/xml-feed',
];

export const getAuthorizedPaths = (companyType, companyFeatures) => {
  if (companyType === 'administrator') {
    return [...commonAuthorizedPaths, ...administratorAuthorizedPaths];
  }

  const canSeeEmployerPaths =
    companyType === 'employer' || companyFeatures.employer === true;
  const canSeeSupplierPaths =
    companyType === 'supplier' || companyFeatures.supplier === true;

  return [
    ...commonAuthorizedPaths,
    ...commonFeaturesAuthorizedPaths,
    ...(canSeeEmployerPaths ? employerAuthorizedPaths : []),
    ...(canSeeSupplierPaths ? supplierAuthorizedPaths : []),
  ];
};
