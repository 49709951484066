import React from 'react';
import PropTypes from 'prop-types';

import { inputFor } from 'components/form/fieldset/utils/inputTypeFor';
import { inputProperties } from 'components/form/fieldset/utils/inputPropertiesForFieldset';

import * as styled from './styles/fieldset';

import { FormLabel } from '../label/label';
import { InputLabel } from '../label/styles/label';

export const RangeFieldset = function RangeFieldset({
  className,
  context,
  inputValue,
  shouldValidate,
  inputIndicator,
  fn,
}) {
  const {
    id,
    label,
    placeholder,
    required,
    min,
    placeholderSecond,
    inputType = 'number',
  } = context;

  const minFieldComponent = inputFor(
    inputProperties({
      context: {
        id: `${id}-min`,
        name: id,
        placeholder,
        inputType,
        required,
        min,
      },
      shouldValidate,
      inputIndicator,
      inputValue: inputValue.min,
      fn: (value) => fn({ min: value, max: inputValue.max }),
    })
  );
  const maxFieldComponent = inputFor(
    inputProperties({
      context: {
        id: `${id}-max`,
        name: id,
        placeholder: placeholderSecond,
        inputType,
        required,
        min,
      },
      shouldValidate,
      inputIndicator,
      inputValue: inputValue.max,
      fn: (value) => fn({ min: inputValue.min, max: value }),
    })
  );

  return (
    <styled.Fieldset className={className}>
      <FormLabel value={label} elementToRefer={id} required={required} />

      <styled.Container>
        {minFieldComponent}

        <styled.RangeSeparator>
          <InputLabel>to</InputLabel>
        </styled.RangeSeparator>

        {maxFieldComponent}
      </styled.Container>
    </styled.Fieldset>
  );
};

RangeFieldset.propTypes = {
  className: PropTypes.string,
  inputIndicator: PropTypes.string,
  context: PropTypes.object,
  inputValue: PropTypes.object,
  shouldValidate: PropTypes.bool,
  fn: PropTypes.func,
};
