import React from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles';

export const IconBase = (props) => {
  const { viewBox, size, children, className } = props;
  return (
    <styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${viewBox} ${viewBox}`}
      className={className}
    >
      {children}
    </styled.Svg>
  );
};

IconBase.propTypes = {
  viewBox: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
