import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import {
  compact,
  filter,
  isArray,
  isNumber,
  isFunction,
  noop,
  uniqBy,
  isObject,
} from 'lodash';
import equal from 'fast-deep-equal';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import { Button } from 'components/elements/button';
import { Spinner } from 'components/visual/Spinner';
import { SelectInput } from '../Filter/styles';

import { paginationWithOffset } from './helper';

import * as styled from '../styles';

const Props = {
  children: PropTypes.node,
};

export const Table = (props) => {
  const { children, ...rest } = props;

  return <styled.Table {...rest}>{children}</styled.Table>;
};

Table.propTypes = Props;

export const Thead = (props) => {
  const { children, filterable, ...rest } = props;

  return (
    <styled.Thead {...rest} hidden={filterable === false}>
      {children}
    </styled.Thead>
  );
};

Thead.propTypes = {
  filterable: PropTypes.bool,
  ...Props,
};

export const Tfoot = (props) => {
  const { children, ...rest } = props;

  return <styled.Tfoot {...rest}>{children}</styled.Tfoot>;
};

Tfoot.propTypes = Props;

export const Tbody = (props) => {
  const { children, ...rest } = props;

  return <styled.Tbody {...rest}>{children}</styled.Tbody>;
};

Tbody.propTypes = Props;

export const Tr = (props) => {
  const { children, withFixedActions, ...rest } = props;

  return (
    <styled.Tr {...rest} withFixedActions={withFixedActions}>
      {children}
    </styled.Tr>
  );
};

Tr.propTypes = {
  ...Props,
  withFixedActions: PropTypes.bool,
};

export const TrGroup = (props) => {
  const { children, ...rest } = props;

  return <styled.TrGroup {...rest}>{children}</styled.TrGroup>;
};

TrGroup.propTypes = Props;

export const Th = (props) => {
  const {
    children,
    defaultFiltered,
    externalFiltered,
    allActiveFilters,
    hasActiveFilters,
    onFilteredChange,
    loading,
    expander,
    sorted,
    filtered,
    sortable,
    toggleSort,
    style,
    dataManual,
    dataColumnId,
    filterToggle,
    filterable,
    onFilterableChange,
    withTableSearch,
    withFixedActions,
  } = props;

  const canSort = sortable && toggleSort && !loading;
  // NOTE:
  // Do not wrap Resizer component with additional markup otherwise it will fail
  // calculating it's position correctly when dragged to resize a column
  const label = isArray(children)
    ? filter(children, (child) => child && child.type !== Resizer)
    : children;
  const resizer = isArray(children)
    ? filter(children, (child) => child && child.type === Resizer)
    : null;

  const allDefaultFilters = [
    ...(defaultFiltered || []),
    ...(externalFiltered || []),
  ];

  const hasOwnFilter = !equal(
    compact(allDefaultFilters),
    compact(uniqBy(allActiveFilters, 'id'))
  );

  let clickFn = noop;
  if (filterToggle) {
    clickFn = onFilterableChange;
  } else if (canSort) {
    clickFn = toggleSort;
  }

  return (
    <styled.Th
      onClick={clickFn}
      sortable={sortable}
      sorted={sorted}
      filtered={filtered}
      style={style}
      isExpander={expander}
      isFilterToggle={filterToggle}
      withFixedActions={withFixedActions}
      data-column-id={dataColumnId}
    >
      {filterToggle && (
        <Icon
          data-manual="filter_toggle"
          icon="FunnelFill"
          color={filterable ? 'tealDark' : 'textLighter'}
        />
      )}
      {expander && hasActiveFilters && hasOwnFilter ? (
        <Button
          color="danger"
          type="blank"
          onClick={() => !loading && onFilteredChange(defaultFiltered)}
        >
          <Icon icon="X" />
        </Button>
      ) : null}
      {label && (
        <styled.ThContent>
          {/* NOTE: Disable filter input while `loading: true` */}
          {isFunction(onFilteredChange) && isValidElement(label)
            ? React.cloneElement(label, { disabled: loading, defaultFiltered })
            : label}
        </styled.ThContent>
      )}
      {canSort && (
        <styled.Sorter>
          <Icon
            icon={
              (sorted && (sorted.desc ? 'CaretDown' : 'CaretUp')) ||
              'ChevronUpDown'
            }
            size={1.2}
          />
        </styled.Sorter>
      )}
      {resizer}
      {dataManual && (
        <styled.IntercomOverlay
          data-manual={dataManual}
          withTableSearch={withTableSearch}
        ></styled.IntercomOverlay>
      )}
    </styled.Th>
  );
};

Th.propTypes = {
  ...Props,
  sorted: PropTypes.object,
  filtered: PropTypes.object,
  sortable: PropTypes.bool,
  toggleSort: PropTypes.func,
  dataManual: PropTypes.string,
  withFixedActions: PropTypes.bool,
  dataColumnId: PropTypes.string,
};

const WithTooltip = ({ content }) => (
  <Tooltip content={content}>
    <styled.TooltipContent>{content}</styled.TooltipContent>
  </Tooltip>
);

WithTooltip.propTypes = {
  content: PropTypes.string,
};

export const Td = (props) => {
  const {
    children,
    style,
    className,
    onClick,
    isExpander,
    withTooltip,
    withFixedActions,
    dataColumnId,
  } = props;

  return (
    <styled.Td
      data-column-id={dataColumnId}
      style={style}
      className={className}
      onClick={onClick}
      isExpander={isExpander}
      withFixedActions={withFixedActions}
    >
      {withTooltip ? <WithTooltip content={children} /> : children}
    </styled.Td>
  );
};

Td.propTypes = {
  ...Props,
  dataColumnId: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  isExpander: PropTypes.bool,
  onClick: PropTypes.func,
  withTooltip: PropTypes.bool,
  withFixedActions: PropTypes.bool,
};

export const Resizer = (props) => {
  const { children, ...rest } = props;

  return <styled.Resizer {...rest}>{children}</styled.Resizer>;
};

Resizer.propTypes = Props;

export const Loader = (props) => {
  const { loading } = props;

  return loading ? (
    <styled.Loader>
      <styled.InlineText>
        <Spinner />
      </styled.InlineText>
    </styled.Loader>
  ) : null;
};

Loader.propTypes = {
  ...Props,
  loading: PropTypes.bool,
};

export const Expander = (props) => {
  const { children, isExpanded, column, ...rest } = props;
  if (!column.expandable) {
    return null;
  }

  const chevron = isExpanded ? (
    <Icon icon="CaretDown" />
  ) : (
    <Icon icon="CaretRight" />
  );

  return (
    <styled.Expander {...rest}>
      {children}
      {chevron}
    </styled.Expander>
  );
};

Expander.propTypes = {
  ...Props,
  isExpanded: PropTypes.object,
};

export const Pagination = (props) => {
  const {
    page,
    pages,
    pageSize,
    pageSizeOptions,
    showPageSizeOptions,
    showPageJump,
    canPrevious,
    canNext,
    onPageSizeChange,
    onPageChange,
    rowsText,
    totalCount,
    totalCountLabel,
  } = props;

  // NOTE:
  // react-table starts pagination with 0, whereas our API start with 1
  const canReallyPrevious = canPrevious && page > 1;

  return (
    <styled.Pagination>
      <styled.Counter>
        {isObject(totalCountLabel) && isNumber(totalCount) && (
          <FormattedMessage
            {...totalCountLabel}
            values={{ count: totalCount }}
          />
        )}
      </styled.Counter>
      <styled.Pages>
        <styled.PageArrow
          rounded
          size="small"
          onClick={() => (canReallyPrevious ? onPageChange(page - 1) : null)}
          disabled={!canReallyPrevious}
          color={canReallyPrevious ? 'primary' : 'mono'}
        >
          <Icon icon="CaretLeft" />
        </styled.PageArrow>

        {showPageJump &&
          paginationWithOffset(page, pages).map((currentPage) =>
            isNumber(currentPage) ? (
              <Button
                key={currentPage}
                type="blank"
                size="normal"
                color={page === currentPage ? 'primary' : 'mono'}
                onClick={() => onPageChange(currentPage)}
              >
                {currentPage}
              </Button>
            ) : (
              <styled.PagesSpacer key={currentPage}>
                {currentPage}
              </styled.PagesSpacer>
            )
          )}

        <styled.PageArrow
          rounded
          size="small"
          onClick={() => (canNext ? onPageChange(page + 1) : null)}
          disabled={!canNext}
          color={canNext ? 'primary' : 'mono'}
        >
          <Icon icon="CaretRight" />
        </styled.PageArrow>
      </styled.Pages>

      {showPageSizeOptions && (
        <styled.PageSize>
          <SelectInput
            value={pageSize}
            onChange={(event) => onPageSizeChange(Number(event.target.value))}
          >
            {pageSizeOptions.map((size) => (
              <option value={size} key={`page-size-option-${size}`}>
                {size} {rowsText}
              </option>
            ))}
          </SelectInput>
          <styled.PageSizeAction>
            <Icon size={1.15} icon="ChevronUpDown" />
          </styled.PageSizeAction>
        </styled.PageSize>
      )}
    </styled.Pagination>
  );
};

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  showPageSizeOptions: PropTypes.bool,
  showPageJump: PropTypes.bool,
  canPrevious: PropTypes.bool,
  canNext: PropTypes.bool,
  onPageSizeChange: PropTypes.func,
  onPageChange: PropTypes.func,
  rowsText: PropTypes.string,
  totalCount: PropTypes.number,
  totalCountLabel: PropTypes.object,
};

export const NoData = (props) => {
  const { children, loading } = props;

  return loading ? null : (
    <styled.NoData>
      <styled.InlineText>{children}</styled.InlineText>
    </styled.NoData>
  );
};

NoData.propTypes = {
  ...Props,
  loading: PropTypes.bool,
};
