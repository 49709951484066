import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactPageClick } from 'react-page-click';
import { getDropdownOptionsSettings } from 'components/utils/dropdown';

import { isEmpty, includes, uniq, difference, noop, filter } from 'lodash';

import * as styled from './styles';
import i18n from './utils/i18n';

const BORDER_HEIGHT = 0.2;

export const TagsDropdown = (props) => {
  const {
    className,
    onChange,
    options,
    selected,
    disabledOptions,
    optionsHeight,
    placeholder,
    maxOptionsHeight,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [optionsPosition, setOptionsPosition] = useState('bottom');
  const [optionsSumHeight, setOptionsSumHeight] = useState(
    options.length * optionsHeight
  );

  const selectRef = useRef();

  const onSelect = useCallback(
    (option) => onChange(uniq([...selected, option.slug])),
    [selected]
  );

  const onUnselect = useCallback(
    (option) => onChange(difference(selected, [option])),
    [selected]
  );

  const onClear = () => onChange([]);

  const handleExpandClick = () => {
    if (expanded) return;

    const { position, height } = getDropdownOptionsSettings({
      element: selectRef.current,
      options,
      optionsPosition,
      optionsHeight,
      borderHeight: BORDER_HEIGHT,
    });

    setOptionsPosition(position);
    setOptionsSumHeight(height);
    setExpanded(true);
  };

  const selectedOptions = filter(options, ({ slug }) =>
    includes(selected, slug)
  );

  return (
    <ReactPageClick notify={() => setExpanded(false)}>
      <styled.InputContainer className={className}>
        <styled.Input onClick={handleExpandClick} ref={selectRef}>
          {isEmpty(selected) && (
            <styled.Placeholder>
              <FormattedMessage {...(placeholder || i18n.defaultPlaceholder)} />
            </styled.Placeholder>
          )}
          <styled.TagsList>
            {selectedOptions.map((option) => (
              <styled.Tag key={option.slug} size="normal">
                {option.label}
                <styled.UnselectIcon onClick={() => onUnselect(option.slug)} />
              </styled.Tag>
            ))}
          </styled.TagsList>
          <styled.IconsWrapper>
            {!isEmpty(selected) && (
              <>
                <styled.ClearIcon onClick={onClear} />
                <styled.IconSpacer />
              </>
            )}
            <styled.DropdownIcon
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            />
          </styled.IconsWrapper>
        </styled.Input>
        <styled.OptionsWrapper
          expanded={expanded}
          optionsPosition={optionsPosition}
          height={optionsSumHeight}
          maxOptionsHeight={maxOptionsHeight + BORDER_HEIGHT}
        >
          {options.map((option) => (
            <styled.Option
              key={option.slug}
              selected={includes(selected, option.slug)}
              disabled={includes(disabledOptions, option.slug)}
              onClick={() =>
                includes(disabledOptions, option.slug) ? noop : onSelect(option)
              }
            >
              {option.label}
            </styled.Option>
          ))}
        </styled.OptionsWrapper>
      </styled.InputContainer>
    </ReactPageClick>
  );
};

TagsDropdown.defaultProps = {
  optionsHeight: 4,
  maxOptionsHeight: 20,
};

TagsDropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ slug: PropTypes.string, label: PropTypes.string })
  ).isRequired,
  optionsHeight: PropTypes.number,
  selected: PropTypes.arrayOf(PropTypes.string.isRequired),
  disabledOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.object,
  maxOptionsHeight: PropTypes.number,
};
