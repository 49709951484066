import PropTypes from 'prop-types';
import React, { Component } from 'react';
import equal from 'fast-deep-equal';

import * as styled from './styles/slider';

const Handle = ({ formatValue, ...props }) => (
  <styled.Handle {...props}>
    <styled.Tooltip
      color="dark"
      position="N"
      active={props.dragging}
      content={formatValue ? formatValue(props.value) : props.value}
    ></styled.Tooltip>
  </styled.Handle>
);

Handle.propTypes = {
  value: PropTypes.any,
  formatValue: PropTypes.func,
  dragging: PropTypes.bool,
};

/* eslint react/no-did-update-set-state: 0 */
export class InputSlider extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.inputValue };
  }

  componentDidUpdate(prevProps) {
    const { inputValue } = this.props;
    if (!equal(prevProps.inputValue, inputValue)) {
      this.setState({ value: inputValue });
    }
  }

  changeValue = (value) => {
    this.setState({ value });
  };

  updateValue = (value) => {
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  };

  render() {
    const {
      className,
      min = 0,
      max,
      step,
      range,
      tooltip,
      color,
      shouldValidate,
      inputValue = 0,
      formatValue,
    } = this.props;
    const { value } = this.state;
    const isValid = shouldValidate ? inputValue >= min : true;
    const SliderEl = range ? styled.Range : styled.Slider;
    const otherProps = {};
    if (tooltip) {
      otherProps.handle = (props) => (
        <Handle {...props} formatValue={formatValue} />
      );
    }

    return (
      <SliderEl
        className={className}
        isValid={isValid}
        color={color}
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={this.changeValue}
        onAfterChange={this.updateValue}
        {...otherProps}
      />
    );
  }
}

InputSlider.propTypes = {
  className: PropTypes.string,
  shouldValidate: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  range: PropTypes.bool,
  tooltip: PropTypes.bool,
  color: PropTypes.string,
  inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  formatValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};

InputSlider.defaultProps = {
  range: false,
  tooltip: false,
  color: 'primary',
};
