import { useEffect } from 'react';
import PropTypes from 'prop-types';

export function Beamer({ apiKey, user }) {
  useEffect(() => {
    ((w, d) => {
      window.beamer_config = {
        product_id: apiKey,
        selecor: 'beamerTrigger',
        top: 4,
        right: 4,
        button: false,
        filter: 'gustav',
        user_firstname: user.first_name,
        user_lastname: user.last_name,
        user_email: user.email,
        user_id: user.id,
      };

      const elem = d.createElement('script');
      elem.type = 'text/javascript';
      elem.src = 'https://app.getbeamer.com/js/beamer-embed.js';
      elem.defer = 'defer';

      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(elem, x);
    })(window, document);
  }, []);

  return null;
}

Beamer.propTypes = {
  apiKey: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};
