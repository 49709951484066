import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const ChevronDownUp = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={20} size={size}>
      <path
        d="M15 3.33331L10 8.33331L5 3.33331"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 16.6667L10 11.6667L15 16.6667"
        fill="none"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};

ChevronDownUp.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
