import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';

import { Button } from 'components/elements/button';

export class ClipboardButton extends Component {
  constructor(props) {
    super(props);
    this.clipboard = null;
    this.copyButton = null;
  }

  componentDidMount() {
    const { copyText, clipboardTarget } = this.props;

    if (clipboardTarget) {
      this.clipboard = new Clipboard(this.copyButton, {
        target: clipboardTarget,
      });
    } else {
      this.clipboard = new Clipboard(this.copyButton, {
        text: () => copyText,
      });
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  onClick = (event) => {
    const { onCopy } = this.props;

    event.preventDefault();
    if (onCopy) {
      onCopy(event);
    }
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Button
        onClick={this.onClick}
        ref={(button) => {
          this.copyButton = button;
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
}

ClipboardButton.propTypes = {
  copyText: PropTypes.string,
  clipboardTarget: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node.isRequired,
  onCopy: PropTypes.func,
};
