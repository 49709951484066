import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { get, find } from 'lodash';

import { BubbleFilter } from 'components/misc/BubbleFilter';
import { userRole } from 'utils/authorization/utils/auth';

import { ScopeFilterComponent } from './ScopeFilter';
import * as styled from './styles';
import i18n from './utils/i18n';

export class JobsActiveFilters extends Component {
  getCurrentStatusFilterValue = () => {
    const { currentFilters } = this.props;
    return get(find(currentFilters, { id: 'status' }), 'value', 'active');
  };

  handleStatusChange = (value) => {
    const { onChange } = this.props;
    const current = this.getCurrentStatusFilterValue();

    if (value === current) {
      onChange([{ id: 'status', value: 'active' }]);
    } else if (current === 'open_and_paused' && value === 'open') {
      onChange([{ id: 'status', value: 'paused' }]);
    } else if (current === 'open_and_paused' && value === 'paused') {
      onChange([{ id: 'status', value: 'open' }]);
    } else if (
      (value === 'paused' && current === 'open') ||
      (value === 'open' && current === 'paused')
    ) {
      onChange([{ id: 'status', value: 'open_and_paused' }]);
    } else {
      onChange([{ id: 'status', value }]);
    }
  };

  render() {
    const {
      currentFilters,
      members,
      jobsCounters,
      companyCounter,
      onChange,
    } = this.props;
    const currentStatus = this.getCurrentStatusFilterValue();

    return (
      <styled.Wrapper>
        <styled.FiltersGroup data-manual="jobs-active.job-filters">
          <BubbleFilter
            title={
              <FormattedMessage
                {...i18n.filterAll}
                values={{ count: get(jobsCounters, 'active', 0) }}
              />
            }
            value={currentStatus === 'active' && { active: true }}
            onClick={() => this.handleStatusChange('active')}
            formatValue={() => null}
          />

          <BubbleFilter
            title={
              <FormattedMessage
                {...i18n.filterOpen}
                values={{ count: get(jobsCounters, 'open', 0) }}
              />
            }
            value={
              (currentStatus === 'open' ||
                currentStatus === 'open_and_paused') && { open: true }
            }
            onClick={() => this.handleStatusChange('open')}
            onClear={() => this.handleStatusChange('open')}
            formatValue={() => null}
          />

          <BubbleFilter
            title={
              <FormattedMessage
                {...i18n.filterPaused}
                values={{ count: get(jobsCounters, 'paused', 0) }}
              />
            }
            value={
              (currentStatus === 'paused' ||
                currentStatus === 'open_and_paused') && { open: true }
            }
            onClick={() => this.handleStatusChange('paused')}
            onClear={() => this.handleStatusChange('paused')}
            formatValue={() => null}
          />
        </styled.FiltersGroup>

        {userRole() !== 'member' && (
          <ScopeFilterComponent
            dataManual="jobs.user-scope-filter"
            currentFilters={currentFilters}
            members={members}
            companyCounter={companyCounter}
            onChange={onChange}
          />
        )}
      </styled.Wrapper>
    );
  }
}

JobsActiveFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })
  ),
  members: PropTypes.array,
  companyCounter: PropTypes.number,
  jobsCounters: PropTypes.object,
};
