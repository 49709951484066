import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const HelpCircle = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={256} size={size}>
      <rect width="256" height="256" fill="none" />
      <circle
        cx="128"
        cy="128"
        r="96"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <circle cx="128" cy="180" r="12" />
      <path
        d="M127.9995,144.0045v-8a28,28,0,1,0-28-28"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </IconBase>
  );
};

HelpCircle.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
