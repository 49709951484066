import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'points-cluster';
import { identity, pickBy, isEqual } from 'lodash';

import { ReactComponent as MapPointer } from 'images/svg/map-pointer.svg';
import { ReactComponent as MapPointerHover } from 'images/svg/map-pointer-hover.svg';
import { ReactComponent as MapPointerMarker } from 'images/svg/map-pointer-empty.svg';
import { Config as AppConfig } from 'containers/App/config';
import { coordPropType } from 'utils/propTypes';

import * as styled from './styles/map';

const MapPoint = ({
  supplier,
  chosen,
  onClickMarkerInfoClose,
  $hover,
  onOpenProfile,
}) => (
  <styled.PointWrapper>
    <styled.MapPoint>
      {$hover || chosen ? <MapPointerHover /> : <MapPointer />}
      {chosen && (
        <styled.SupplierCard
          supplier={supplier}
          nameColor="tealDark"
          onClick={() => onOpenProfile(supplier)}
        >
          <styled.CloseMapPoint
            fn={(ev) => ev.stopPropagation() || onClickMarkerInfoClose()}
          />
        </styled.SupplierCard>
      )}
    </styled.MapPoint>
  </styled.PointWrapper>
);

MapPoint.propTypes = {
  supplier: PropTypes.object.isRequired,
  chosen: PropTypes.bool.isRequired,
  onClickMarkerInfoClose: PropTypes.func.isRequired,
  onOpenProfile: PropTypes.func.isRequired,
  $hover: PropTypes.bool,
};

const ClusterPoint = ({ numPoints, lat, lng, zoom, onClick }) => (
  <styled.PointWrapper>
    <styled.MapPoint
      onClick={() => onClick({ center: { lat, lng }, zoom: zoom + 2 })}
    >
      <styled.ClusterCounter>{numPoints}</styled.ClusterCounter>
      <MapPointerMarker />
    </styled.MapPoint>
  </styled.PointWrapper>
);

ClusterPoint.propTypes = {
  numPoints: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  onClick: PropTypes.func,
};

export const Map = (props) => {
  const {
    mapParams,
    searchResultsVisible,
    onMapContainerRef,
    onClickSupplierMarker,
    yOffset,
    context,
    shownSuppliers,
    chosenSupplierId,
    onClickMarkerInfoClose,
    onOpenProfile,
    onChangeMapParams,
  } = props;
  const [clusters, setClusters] = useState([]);
  const [activeMapParams, setActiveMapParams] = useState();

  const getClusters = () => {
    const points = shownSuppliers.map((supplier) => {
      const {
        id,
        billing_address: { lat, lng },
      } = supplier;
      return { id, supplier, lat: parseFloat(lat), lng: parseFloat(lng) };
    });
    const clustersInstance = Supercluster(points, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60,
    });

    return clustersInstance(mapParams);
  };

  const createClusters = () => {
    if (!mapParams.bounds) {
      return [];
    }

    return getClusters().map(({ wx, wy, numPoints, points }) => ({
      lat: wy,
      lng: wx,
      numPoints,
      points,
      id: `${points[0].id}-${numPoints}`,
    }));
  };

  const handleChangeMapParams = ({ center, zoom, bounds }) => {
    onChangeMapParams(pickBy({ center, zoom, bounds }, identity));
    const newClusters = createClusters();
    if (newClusters.length === 0) {
      return;
    }
    setClusters(newClusters);
  };

  const renderPoints = () =>
    clusters.map((item) => {
      if (item.numPoints === 1) {
        const { supplier } = item.points[0];

        return (
          <MapPoint
            lat={item.lat}
            lng={item.lng}
            key={supplier.id}
            supplier={supplier}
            chosen={supplier.id === chosenSupplierId}
            onClickMarkerInfoClose={() => onClickMarkerInfoClose(supplier.id)}
            onOpenProfile={() => onOpenProfile(supplier.id)}
          />
        );
      }

      return (
        <ClusterPoint
          lat={item.lat}
          lng={item.lng}
          zoom={mapParams.zoom}
          key={item.id}
          numPoints={item.numPoints}
          onClick={handleChangeMapParams}
        />
      );
    });

  useEffect(() => {
    if (isEqual(activeMapParams, mapParams)) {
      return;
    }
    setActiveMapParams(mapParams);
    handleChangeMapParams(mapParams);
  }, [mapParams]);

  useEffect(() => {
    const newClusters = createClusters();
    if (newClusters.length === 0 && shownSuppliers.length > 0) {
      return;
    }
    setClusters(newClusters);
  }, [shownSuppliers, activeMapParams]);

  return (
    <styled.MapContainer
      key="map"
      showResults={searchResultsVisible}
      ref={onMapContainerRef}
      scrolled={yOffset}
      context={context}
    >
      <GoogleMapReact
        bootstrapURLKeys={{ key: AppConfig.MapsApiKey }}
        center={mapParams.center}
        zoom={mapParams.zoom}
        onChildClick={onClickSupplierMarker}
        onChange={handleChangeMapParams}
        options={() => ({ gestureHandling: 'greedy' })}
        yesIWantToUseGoogleMapApiInternals
      >
        {renderPoints()}
      </GoogleMapReact>
    </styled.MapContainer>
  );
};

Map.propTypes = {
  yOffset: PropTypes.number,
  shownSuppliers: PropTypes.array,
  searchResultsVisible: PropTypes.bool,
  mapParams: PropTypes.shape({
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    zoom: PropTypes.number,
    bounds: PropTypes.shape({
      ne: coordPropType,
      nw: coordPropType,
      se: coordPropType,
      sw: coordPropType,
    }),
  }),
  chosenSupplierId: PropTypes.string,
  onClickSupplierMarker: PropTypes.func,
  onClickMarkerInfoClose: PropTypes.func,
  onOpenProfile: PropTypes.func,
  onChangeMapParams: PropTypes.func,
  onMapContainerRef: PropTypes.func,
  context: PropTypes.string,
};
