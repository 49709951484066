/* eslint no-console:0 */

export const askPushPermissions = () =>
  new Promise((resolve, reject) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        resolve();
      } else {
        console.warn('Unable to get permission to notify.');
        reject('permission');
      }
    });
  });

export const getPushToken = (messaging) =>
  new Promise((resolve, reject) => {
    messaging
      .getToken()
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
        } else {
          console.warn(
            'No Instance ID token available. Request permission to generate one.'
          );
          reject('token');
        }
      })
      .catch((err) => {
        console.warn('An error occurred while retrieving token ', err);
        reject('token');
      });
  });

export const showNotification = (tag, notification) => {
  const { title, body, icon, click_action: action } = notification;

  const notif = new Notification(title, { body, icon, tag });
  notif.onclick = (e) => {
    e.target.close();
    window.focus();
    window.location = action;
  };
};

export const onPushMessage = (messaging) => {
  messaging.onMessage((payload) => {
    const { notification, collapse_key: key } = payload;
    if (notification) {
      showNotification(key, notification);
    }
  });
};

export const watchRefreshPushToken = (messaging, callback) => {
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then((refreshedToken) => {
        callback(refreshedToken);
      })
      .catch((err) => {
        console.warn('Unable to retrieve refreshed token ', err);
      });
  });
};

export const findServiceWorker = () =>
  new Promise((resolve, reject) => {
    window.navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((worker) => {
        if (
          worker.active &&
          worker.active.scriptURL.includes('firebase-messaging-sw')
        ) {
          resolve(worker);
        }
      });
      reject();
    });
  });

export const registerServiceWorker = (messaging) =>
  new Promise((resolve, reject) => {
    if (window.navigator && window.navigator.serviceWorker) {
      findServiceWorker()
        .then(() => {
          resolve(messaging);
        })
        .catch(() => {
          window.navigator.serviceWorker
            .register('/firebase-messaging-sw.js?v2')
            .then((registration) => {
              messaging.useServiceWorker(registration);
              resolve(messaging);
            })
            .catch((err) => {
              console.warn(
                'Unable to register serviceWorker: [firebase-messaging-sw.js]',
                err
              );
              reject('serviceWorker');
            });
        });
    } else {
      reject('navigator');
    }
  });
