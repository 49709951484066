import PropTypes from 'prop-types';
import React from 'react';

import * as styled from './styles/fieldset';
import { Switch } from '../switch/switch';

export const SwitchFieldset = function SwitchFieldset(props) {
  const {
    className,
    selected,
    context,
    includeIndicator,
    disabled,
    fn,
  } = props;
  return (
    <styled.Fieldset className={className}>
      {context.label && (
        <styled.FormLabel
          margin="0 0 1rem"
          includeIndicator={includeIndicator}
          value={context.label}
          required={context.required}
          elementToRefer={context.id}
        />
      )}

      <Switch
        selected={selected}
        disabled={disabled}
        context={context}
        onChange={(valSelected) => fn(valSelected)}
      />
    </styled.Fieldset>
  );
};

SwitchFieldset.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.string,
  context: PropTypes.object,
  includeIndicator: PropTypes.bool,
  disabled: PropTypes.bool,
  fn: PropTypes.func,
};
