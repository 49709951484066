import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';

import { TAG_SIZES } from '../constants';

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;
  color: ${Colors.text};
`;

const tagClickableMixin = css`
  background-color: ${Colors.tealLighter};
  cursor: pointer;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  min-height: ${({ size }) => TAG_SIZES[size].height}rem;
  line-height: ${({ size }) => TAG_SIZES[size].line}rem;
  font-size: ${({ size }) => TAG_SIZES[size].font}rem;
  padding: 0 1rem;
  margin: 0 1rem 1rem 0;
  border: 0.1rem solid ${Colors.grey};
  border-radius: ${({ size }) => TAG_SIZES[size].height / 2}rem;
  background-color: ${Colors.greyLight};
  word-break: break-word;

  ${({ onClick }) => onClick && tagClickableMixin}
`;

export const Icon = styled(IconBase)`
  flex: 0 0 auto;
  cursor: pointer;
`;

export const AddIcon = styled(Icon)`
  margin: -0.3rem 0.6rem 0 -0.4rem;
`;

export const RemoveIcon = styled(Icon)`
  margin: 0 -0.4rem 0 0.6rem;
`;
