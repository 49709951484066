import { defineMessages } from 'react-intl';

export default defineMessages({
  settingsTitle: {
    id: 'app.components.Settings.header.title',
    defaultMessage: 'Settings',
  },
  membersSettingsTitle: {
    id: 'app.components.Settings.Members.title',
    defaultMessage: 'Members settings',
  },
  integrationsSettingsTitle: {
    id: 'app.components.Settings.Integrations.title',
    defaultMessage: 'Integrations settings',
  },
  settings: {
    id: 'app.components.Settings.settings',
    defaultMessage: 'Settings',
  },

  general: {
    id: 'app.components.Settings.Menu.general',
    defaultMessage: 'General',
  },
  companySettingsTitle: {
    id: 'app.components.Settings.Menu.companySettingsTitle',
    defaultMessage: 'Company',
  },
  companyProfileTitle: {
    id: 'app.components.Settings.Menu.companyProfileTitle',
    defaultMessage: 'Company profile',
  },
  usersTitle: {
    id: 'app.components.Settings.Menu.usersTitle',
    defaultMessage: 'Users',
  },
  documentsTitle: {
    id: 'app.components.Settings.Menu.documentsTitle',
    defaultMessage: 'Document verification',
  },

  configurationJobs: {
    id: 'app.components.Settings.Menu.configurationJobs',
    defaultMessage: 'Jobs configuration',
  },
  submissionProperties: {
    id: 'app.components.Settings.Menu.submissionProperties',
    defaultMessage: 'Submission properties',
  },
  jobProperties: {
    id: 'app.components.Settings.Menu.jobProperties',
    defaultMessage: 'Job settings',
  },
  integrations: {
    id: 'app.components.Settings.Menu.integrations',
    defaultMessage: 'Integrations',
  },
  bullhorn: {
    id: 'app.components.Settings.Menu.bullhorn',
    defaultMessage: 'Bullhorn',
  },
  jobDiva: {
    id: 'app.components.Settings.Menu.jobDiva',
    defaultMessage: 'JobDiva',
  },
  xmlFeed: {
    id: 'app.components.Settings.Menu.xmlFeed',
    defaultMessage: 'XML Feed',
  },

  submitButton: {
    id: 'app.components.Settings.SubmitContainer.submitButton',
    defaultMessage: 'Save changes',
  },
  cancelButton: {
    id: 'app.components.Settings.SubmitContainer.cancelButton',
    defaultMessage: 'Cancel',
  },
  changesMadeMessage: {
    id: 'app.components.Settings.SubmitContainer.changesMadeMessage',
    defaultMessage: "You've made some changes on this page.",
  },

  flashSavedSuccess: {
    id: 'app.components.Settings.Account.flashSavedSuccess',
    defaultMessage: 'Saved successfully!',
  },
});
