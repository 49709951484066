import { userID as getUserID } from 'utils/authorization/utils/auth';

import { JobsActiveFilters } from '../JobsActive';
import { JobsDefaultFilters } from '../JobsDefault';
import { CandidatesFilters } from '../Candidates';

export const filtersDefinition = (resource, filter) => {
  switch (`${resource}/${filter}`) {
    case 'jobs/active':
      return {
        defaultFilters: [],
        filters: [
          { id: 'status', value: 'active' },
          { id: 'ats_member_id', value: getUserID() },
        ],
        Component: JobsActiveFilters,
      };

    case 'jobs/draft':
      return {
        defaultFilters: [
          { id: 'status', value: 'draft' },
          { id: 'imported', value: 'false' },
        ],
        filters: [{ id: 'ats_member_id', value: getUserID() }],
        Component: JobsDefaultFilters,
      };

    case 'jobs/closed':
      return {
        defaultFilters: [{ id: 'status', value: 'closed' }],
        filters: [{ id: 'ats_member_id', value: getUserID() }],
        Component: JobsDefaultFilters,
      };

    case 'jobs/candidates':
      return {
        defaultFilters: [],
        filters: [{ id: 'ats_member_id', value: getUserID() }],
        Component: CandidatesFilters,
      };

    default:
      return { defaultFilters: [], filters: [] };
  }
};
