import React from 'react';
import PropTypes from 'prop-types';

import { find, get, isObject } from 'lodash';

import { FormattedMessage } from 'react-intl';

import * as styled from './styles/toggle';

export const Toggle = function Toggle(props) {
  const {
    className,
    options,
    onChange,
    checked,
    disabled,
    size,
    color,
    colorWhenDisabled,
  } = props;

  const label = get(find(options, { checked }), 'label');

  const labelComponent = isObject(label) ? (
    <FormattedMessage {...label} />
  ) : (
    label
  );

  return (
    <>
      <styled.Toggle className={className} size={size} isOn={checked}>
        <input
          type="checkbox"
          onChange={() => !disabled && onChange(!checked)}
        />
        <styled.Slider
          isOn={checked}
          disabled={disabled}
          color={color}
          colorWhenDisabled={colorWhenDisabled}
        />
      </styled.Toggle>
      {label && (
        <styled.Label key="label" size={size}>
          {labelComponent}
        </styled.Label>
      )}
    </>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  colorWhenDisabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

Toggle.defaultProps = {
  options: [],
  disabled: false,
  colorWhenDisabled: false,
  size: 'large',
};
