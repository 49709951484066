import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'components/overlay/Tooltip';
import { Icon } from 'components/elements/icon';
import { Highlight } from 'components/text/Highlight';

import * as styled from './styles/card';

export const CardSection = function CardSection({
  title,
  children,
  tooltip,
  ...props
}) {
  return (
    <styled.Section {...props}>
      {(title || tooltip) && (
        <styled.SectionTitle>
          {title || ''}

          {tooltip && (
            <styled.SectionTitleTooltip>
              <Tooltip content={<Highlight type="bold" text={tooltip} />}>
                <Icon icon="Info" size="large" color="tealDark" />
              </Tooltip>
            </styled.SectionTitleTooltip>
          )}
        </styled.SectionTitle>
      )}
      {children}
    </styled.Section>
  );
};

CardSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  tooltip: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

CardSection.defaultProps = {
  title: null,
};
