import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { Highlight } from 'components/text/Highlight';

import * as styled from './styles';
import i18n from './utils/i18n';

const SchemeDefinition = {
  feature: {
    icon: 'ShieldFill',
    colorIcon: 'yellow',
    colorPrimary: 'tealDark',
    colorBackground: 'tealLighter',
    colorShadow: 'tealLight',
    buttonTo: '/subscription-plan',
  },
  addon: {
    icon: 'PuzzlePieceFill',
    colorIcon: 'orchid',
    colorPrimary: 'orchid',
    colorBackground: 'orchidLighter',
    colorShadow: 'palePink',
    buttonTo: '#change-subscription1',
  },
};

export const PremiumFeature = (props) => {
  const {
    children,
    type,
    header,
    description,
    features,
    image,
    onClose,
  } = props;
  const scheme = SchemeDefinition[type];

  /* eslint-disable react/no-array-index-key */
  return (
    <styled.Wrapper>
      <styled.Header>
        <Icon icon={scheme.icon} size="xlarge" color={scheme.colorIcon} />
        <FormattedMessage {...header} />
      </styled.Header>

      {children}

      <styled.Banner
        color={scheme.colorPrimary}
        background={scheme.colorBackground}
      >
        <styled.BannerHeader>
          <styled.BannerIcon
            icon={scheme.icon}
            color={scheme.colorPrimary}
            shadow={scheme.colorShadow}
          />
          <FormattedMessage {...i18n.banner[type]} />
        </styled.BannerHeader>
      </styled.Banner>

      <styled.Description>
        <FormattedMessage {...description} />
      </styled.Description>

      <styled.Image src={image} role="presentation" />

      <styled.FeaturesHeader color={scheme.colorPrimary}>
        <FormattedMessage {...i18n.features} />
      </styled.FeaturesHeader>
      <styled.Features>
        {features.map((feat, i) => (
          <styled.Feature key={`feature-${i}`}>
            <Highlight text={feat} />
          </styled.Feature>
        ))}
      </styled.Features>

      <styled.ButtonWrapper>
        {onClose && (
          <styled.Button onClick={onClose} type="outline" color="mono">
            <FormattedMessage {...i18n.buttonCancel} />
          </styled.Button>
        )}
        <styled.Button to={scheme.buttonTo} color={scheme.colorPrimary}>
          <Icon icon={scheme.icon} color="white" size="small" />
          <FormattedMessage {...i18n.button[type]} />
        </styled.Button>
      </styled.ButtonWrapper>
    </styled.Wrapper>
  );
};

PremiumFeature.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['feature', 'addon']),
  header: PropTypes.object,
  description: PropTypes.object,
  features: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.string,
  onClose: PropTypes.func,
};
