import { defineMessages } from 'react-intl';

import theme from 'themes';

export default defineMessages({
  banner: {
    feature: {
      id: 'app.components.PremiumFeature.bannerFeature',
      defaultMessage: `Available on the ${theme.platformName} Pro plan.`,
    },
    addon: {
      id: 'app.components.PremiumFeature.bannerAddon',
      defaultMessage: 'Available as an optional add-on.',
    },
  },

  button: {
    feature: {
      id: 'app.components.PremiumFeature.buttonFeature',
      defaultMessage: 'Upgrade to Pro',
    },
    addon: {
      id: 'app.components.PremiumFeature.buttonAddon',
      defaultMessage: 'Talk to us',
    },
  },

  buttonCancel: {
    id: 'app.components.PremiumFeature.buttonCancel',
    defaultMessage: 'Cancel',
  },

  linkMore: {
    id: 'app.components.PremiumFeature.linkMore',
    defaultMessage: 'Learn more',
  },

  features: {
    id: 'app.components.PremiumFeature.features',
    defaultMessage: 'Features:',
  },
});
