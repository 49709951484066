import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isArray, isObject, has, isNumber } from 'lodash';
import { ReactPageClick } from 'react-page-click';

import { Icon } from 'components/elements/icon';

import * as styled from './styles';

export class BubbleFilter extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.ref = React.createRef();
  }

  toggleModal = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  };

  closeModal = (e) => {
    if (!this.ref.current.contains(e.target)) {
      this.setState({ open: false });
    }
  };

  clearFilter = (e) => {
    const { onClear } = this.props;

    e.stopPropagation();
    this.setState({ open: false });
    onClear();
  };

  formattedValue = () => {
    const { value, formatValue } = this.props;
    const toFormat = isArray(value) ? value[0] : value;
    const formatted = formatValue ? formatValue(toFormat) : toFormat;

    if (!formatted) {
      return null;
    }

    if (!isArray(formatted)) {
      return (
        <>
          {':\u00A0'}
          <styled.Value>{formatted}</styled.Value>
        </>
      );
    }

    return (
      <>
        {':\u00A0'}
        <styled.Value>{formatted[0]}</styled.Value>
        {formatted.slice(1)}
      </>
    );
  };

  renderTitle = () => {
    const { title } = this.props;

    return isObject(title) && has(title, 'defaultMessage') ? (
      <FormattedMessage {...title} />
    ) : (
      title
    );
  };

  renderCounter = () => {
    const { counter, withCounterCircle } = this.props;
    if (withCounterCircle) {
      return <styled.CounterCircle>{counter}</styled.CounterCircle>;
    }

    return ` (${counter})`;
  };

  render() {
    const {
      className,
      children,
      icon,
      value,
      width,
      dataManual,
      onClick,
      onClear,
      counter,
    } = this.props;
    const { open } = this.state;
    const isActive = !isEmpty(value);

    return (
      <styled.Wrapper className={className}>
        <styled.Bubble
          ref={this.ref}
          open={open}
          active={isActive}
          onClick={onClick || this.toggleModal}
          data-manual={dataManual}
        >
          {icon && <styled.Icon icon={icon} size="small" active={isActive} />}
          {this.renderTitle()}
          {isNumber(counter) && this.renderCounter()}
          {isActive && this.formattedValue()}
          {isActive &&
            isArray(value) &&
            value.length > 1 &&
            `\u00A0+${value.length - 1}`}
          {isActive && onClear && (
            <styled.Clear onClick={this.clearFilter}>
              <Icon icon="X" size="small" color="tealDark" />
            </styled.Clear>
          )}
        </styled.Bubble>

        {children && open && (
          <ReactPageClick notify={this.closeModal}>
            <styled.Modal width={width}>
              <styled.Title>
                {this.renderTitle()}
                <styled.Close onClick={this.closeModal}>
                  <Icon icon="X" color="white" />
                </styled.Close>
              </styled.Title>
              <styled.Content>{children}</styled.Content>
            </styled.Modal>
          </ReactPageClick>
        )}
      </styled.Wrapper>
    );
  }
}

BubbleFilter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.object,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  counter: PropTypes.number,
  withCounterCircle: PropTypes.bool,
  formatValue: PropTypes.func,
  width: PropTypes.string,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  dataManual: PropTypes.string,
};
