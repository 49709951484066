import { cloneDeep, get, set, isNumber } from 'lodash';

import { COMPANY_PROFILE_UPDATE_SUCCESS } from 'containers/CompanyProfile/constants';
import { SUBSCRIPTION_CHANGE_SUCCESS } from 'containers/SubscriptionPlan/constants';
import { HOMEPAGE_NEW_JOB_FORM_SUCCESS } from 'containers/JobPosting/constants';
import { CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS } from 'containers/ConfirmEmail/constants';
import {
  SAVE_JOB_SUCCESS,
  UNSAVE_JOB_SUCCESS,
} from 'containers/Marketplace/constants';
import { MARKETPLACEJOBS_OVERVIEW_SAVE_JOB_SUCCESS } from 'containers/MarketplaceJobs/constants';
import { JOBS_COUNTERS_SUCCESS } from 'containers/Requisitions/constants';
import { COMPANY_UPDATE_SUCCESS } from 'containers/Settings/Company/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';
import {
  GET_SESSION_SUCCESS,
  GET_SESSION_VIA_TOKEN_SUCCESS,
} from 'containers/Login/constants';

import { userReducer as User } from './user';
import { companyReducer as Company } from './company';

import { Constants } from '../../constants';

const sessionInitialState = {
  socket: null,
  user: User(),
  company: Company(),
  error: null,
  release: null,
  counters: { candidates: {}, jobs: {}, partners: {} },
};

export const session = (state = sessionInitialState, action) => {
  switch (action.type) {
    case 'SOCKET_CONNECTED':
      return {
        ...state,
        socket: action.socket,
        error: null,
      };

    case 'USER_CHANNEL_JOINED':
    case 'USER_CHANNEL_LEFT':
    case 'USER_AVATAR_UPLOAD':
    case 'UPDATE_USER':
    case CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS:
    case GET_SESSION_SUCCESS:
    case GET_SESSION_VIA_TOKEN_SUCCESS:
      return {
        ...state,
        user: User(state.user, action),
      };

    case 'COMPANY_CHANNEL_JOINED':
    case 'COMPANY_CHANNEL_LEFT':
    case COMPANY_PROFILE_UPDATE_SUCCESS:
    case SUBSCRIPTION_CHANGE_SUCCESS:
    case HOMEPAGE_NEW_JOB_FORM_SUCCESS:
    case COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        company: Company(state.company, action),
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        company: Company(state.company, action),
        user: User(state.user, action),
      };

    case SAVE_JOB_SUCCESS: {
      const currentSavedJobs = get(state, 'counters.marketplace.saved_jobs');
      return isNumber(currentSavedJobs)
        ? set(
            cloneDeep(state),
            'counters.marketplace.saved_jobs',
            currentSavedJobs + 1
          )
        : state;
    }

    case UNSAVE_JOB_SUCCESS: {
      const currentSavedJobs = get(state, 'counters.marketplace.saved_jobs');
      return isNumber(currentSavedJobs)
        ? set(
            cloneDeep(state),
            'counters.marketplace.saved_jobs',
            currentSavedJobs - 1
          )
        : state;
    }

    case MARKETPLACEJOBS_OVERVIEW_SAVE_JOB_SUCCESS: {
      const currentSavedJobs = get(state, 'counters.marketplace.saved_jobs');
      const change = action.data.status === 'saved' ? 1 : -1;
      return isNumber(currentSavedJobs)
        ? set(
            cloneDeep(state),
            'counters.marketplace.saved_jobs',
            currentSavedJobs + change
          )
        : state;
    }

    case JOBS_COUNTERS_SUCCESS: {
      const {
        payload: { counters },
      } = action;

      return {
        ...state,
        counters: {
          ...state.counters,
          jobs: {
            ...state.counters.jobs,
            active_jobs: get(counters, 'active', 0),
            closed_jobs: get(counters, 'closed', 0),
            draft_jobs: get(counters, 'draft', 0),
            imported_jobs: get(counters, 'imported', 0),
            received_candidates: get(counters, 'candidates', 0),
          },
        },
      };
    }

    case 'RELEASE_CHANNEL_JOINED':
      return {
        ...state,
        release: action.payload,
      };

    case Constants.FETCH_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload.counters,
      };

    default:
      return state;
  }
};
