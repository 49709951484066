import React, { Component, Fragment, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { some, isString, isEqual, get, pick } from 'lodash';

import { Tooltip } from 'components/overlay/Tooltip';

import * as styled from './styles/switch';

class SwitchComponent extends Component {
  constructor(props) {
    super(props);

    const {
      selected,
      allowEmpty,
      context: { options },
    } = props;
    let optionSelected;
    if (some(options, { slug: selected })) {
      optionSelected = selected;
    } else if (!allowEmpty) {
      optionSelected = options[0].slug;
    }

    this.state = {
      selected: optionSelected,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = this.state;
    if (nextProps.selected !== selected) {
      this.setState({ selected: nextProps.selected });
    }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(
      pick(this.props, ['selected', 'disabled']),
      pick(nextProps, ['selected', 'disabled'])
    );
  }

  tooltipContent = ({ disabled: { tooltip } }) => {
    const { intl } = this.props;
    return isValidElement(tooltip) ? tooltip : intl.formatMessage(tooltip);
  };

  updateSelected(event, selected) {
    event.preventDefault();
    event.stopPropagation();

    const { onChange } = this.props;

    this.setState({ selected });
    onChange(selected);
  }

  prepareOptions() {
    const { selected } = this.state;
    const {
      intl,
      disabled,
      context: { options = [] },
      selectedMixin,
    } = this.props;

    return options.map((option) => {
      const { optionName, slug } = option;

      const isSelected = selected === slug;
      const isDisabled = disabled && option.disabled;
      const buttonLabel = isString(optionName)
        ? optionName
        : intl.formatMessage(optionName);
      const tooltipContent = get(option, 'disabled.tooltip')
        ? this.tooltipContent(option)
        : null;
      const isTooltip = isDisabled && tooltipContent;
      const WrapperEl = isTooltip ? Tooltip : Fragment;
      const iconColor = isSelected ? 'white' : 'textLighter';

      return (
        <WrapperEl
          key={`switch-option-${slug}`}
          {...(isTooltip ? { content: tooltipContent, position: 'S' } : {})}
        >
          <styled.Button
            selected={isSelected}
            selectedMixin={selectedMixin}
            disabled={isDisabled}
            onClick={(event) => this.updateSelected(event, slug)}
            activeIndicator={get(option, 'activeIndicator')}
          >
            {!isDisabled && get(option, 'icon') && (
              <styled.Icon icon={option.icon} color={iconColor} />
            )}
            {isDisabled && get(option, 'disabled.icon') && (
              <styled.DisabledIcon
                icon={option.disabled.icon}
                color="greyDarker"
              />
            )}
            {buttonLabel}
            {isDisabled && tooltipContent && (
              <styled.Icon icon="Info" color={iconColor} />
            )}
          </styled.Button>
        </WrapperEl>
      );
    });
  }

  render() {
    const { className } = this.props;

    return (
      <styled.Switch className={className}>
        {this.prepareOptions()}
      </styled.Switch>
    );
  }
}

SwitchComponent.propTypes = {
  className: PropTypes.string,
  intl: PropTypes.object,
  selected: PropTypes.string,
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  context: PropTypes.object,
  onChange: PropTypes.func,
  selectedMixin: PropTypes.array,
};

SwitchComponent.defaultProps = {
  allowEmpty: false,
};

export const Switch = injectIntl(SwitchComponent);
