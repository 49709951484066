import { defineMessages } from 'react-intl';

export default defineMessages({
  bulletedList: {
    id: 'app.components.form.rte.bulletedlist',
    defaultMessage: 'Bulleted list',
  },
  orderedList: {
    id: 'app.components.form.rte.orderedList',
    defaultMessage: 'Ordered list',
  },
  bold: {
    id: 'app.components.form.rte.bold',
    defaultMessage: 'Bold',
  },
  italic: {
    id: 'app.components.form.rte.italic',
    defaultMessage: 'Italic',
  },
  underline: {
    id: 'app.components.form.rte.underline',
    defaultMessage: 'Underline',
  },
  highlight: {
    id: 'app.components.form.rte.highlight',
    defaultMessage: 'Highlight',
  },
  charactersCounter: {
    id: 'app.components.form.rte.charactersCounter',
    defaultMessage: '{currentCount} / {maxCharacters} characters',
  },
});
