import styled from 'styled-components';

import { InputText } from 'components/form/input/styles/inputs';
import { Button as ButtonBase } from 'components/elements/button';

import { ButtonSet as ButtonSetBase } from 'components/misc/ButtonSet';
import { Icon as IconBase } from 'components/elements/icon';
import { selfIconElementsSelector } from 'components/elements/styles/icon';
export const TextareaBase = InputText.withComponent('div');

export const Wrapper = styled.div`
  position: relative;
`;

export const ButtonSet = styled(ButtonSetBase)`
  display: inline-flex;

  & ~ & {
    margin-left: 1rem;
  }
`;

export const Textarea = styled(TextareaBase)`
  height: auto;
  margin-top: 1rem;
  padding: 0;
  line-height: 2.2rem;
  resize: vertical;
  overflow: hidden;

  &,
  [class*='DraftEditor'] {
    height: inherit;
    min-height: ${({ minHeight = '17rem' }) => minHeight};
    max-height: ${({ maxHeight = '60rem' }) => maxHeight};
  }

  .public-DraftEditorPlaceholder-root {
    padding: 1.4rem;
  }

  .public-DraftEditor-content {
    outline: none;
    overflow: auto;
    resize: vertical;
    padding: 1.4rem;
    padding-top: 0.8rem;
  }

  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    padding-left: 2px;
    margin: 0.6rem 0;
  }

  .public-DraftStyleDefault-ltr {
    margin: 0.6rem 0;
  }

  ${({ focus, theme }) =>
    focus &&
    `
    outline: none;
    border: solid 1px ${theme.colors.primary} !important;
  `}
`;

export const Button = styled(ButtonBase)`
  border-width: 0.1rem;
`;

export const Icon = styled(IconBase)`
  ${selfIconElementsSelector} {
    stroke: #666 !important;
  }
`;

export const CharactersCounter = styled.div`
  margin-top: 1rem;

  ${({ theme }) => theme.typography.text.small};
`;
