import React from 'react';
import PropTypes from 'prop-types';
import { get, some, noop } from 'lodash';

import * as styled from './styles';

const Props = {
  checked: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func,
  defaultFiltered: PropTypes.array,
};

const DefaultProps = {
  options: { true: true, false: false },
  checked: false,
  disabled: false,
  onChange: noop,
};

export const CheckboxFilter = (props) => {
  const {
    label,
    checked,
    options,
    disabled,
    onChange,
    defaultFiltered,
  } = props;

  const isDefaultFilter = some(defaultFiltered, {
    id: get(props, 'column.id'),
  });

  return (
    <styled.Filter>
      <styled.CheckboxLabel>
        {label}
        <styled.CheckboxInput
          checked={options.true === checked}
          disabled={disabled || isDefaultFilter}
          onChange={(event) => onChange(options[event.checked])}
          shouldValidate={false}
        />
      </styled.CheckboxLabel>
    </styled.Filter>
  );
};

CheckboxFilter.propTypes = Props;
CheckboxFilter.defaultProps = DefaultProps;
