import React from 'react';
import PropTypes from 'prop-types';

import { JobsList } from '../JobsList/Loadable';
import { NoResultsQuery, NoResultsFilter } from './EmptyState';

import i18n from './utils/i18n';

export const Active = ({
  defaultFiltered,
  filtered,
  onFetchCounters,
  onChangeFilters,
  members,
}) => (
  <JobsList
    title={i18n.title}
    defaultFiltered={defaultFiltered}
    externalFiltered={filtered}
    members={members}
    onFetchCounters={onFetchCounters}
    emptyStateDefault={({ userType, differentUserFirstName }) =>
      NoResultsQuery({
        filtered,
        userType,
        differentUserFirstName,
        onChangeFilters,
      })
    }
    emptyStateFiltered={({ onFilteredChange, userType }) =>
      NoResultsFilter({
        resetFilter: () => onFilteredChange(defaultFiltered),
        onChangeFilters,
        userType,
      })
    }
  />
);

Active.propTypes = {
  members: PropTypes.array,
  onFetchCounters: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  defaultFiltered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ),
  filtered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ),
};
