import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Icon } from 'components/elements/icon';
import { Button } from 'components/elements/button';
import { List } from 'components/lists/InfiniteScrollableList/List';

import * as styled from './styles/searchResults';
import i18n from './utils/i18n';

const PAGE_SIZE = 10;

export const SearchResults = (props) => {
  const {
    shownSuppliers,
    searchResultsVisible,
    clearFiltersVisible,
    onChangeVisibility,
    onResetFilters,
    loading,
    context,
    yOffset,
    showCompleteProfileBanner,
    onClickSupplierCard,
    onOpenProfile,
  } = props;
  const [page, setPage] = useState(1);
  const [paginatedSuppliers, setPaginatedSuppliers] = useState(
    shownSuppliers.slice(0, PAGE_SIZE)
  );
  const hasNoData = shownSuppliers.length === 0 && !loading;

  const renderNextPage = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setPaginatedSuppliers(shownSuppliers.slice(0, nextPage * PAGE_SIZE));
  };

  const renderSupplier = (supplier) => (
    <styled.SupplierCard
      key={supplier.id}
      onClick={() => onClickSupplierCard(supplier)}
      supplier={supplier}
      onOpenProfile={() => onOpenProfile(supplier)}
      withRating={supplier.ratings.length > 0}
    />
  );

  useEffect(() => {
    setPaginatedSuppliers(shownSuppliers.slice(0, page * PAGE_SIZE));
  }, [shownSuppliers]);

  useEffect(() => {
    if (hasNoData) {
      document.documentElement.style.overflowY = 'scroll';
    }
    // eslint-disable-next-line no-return-assign
    return () => (document.documentElement.style.overflowY = '');
  }, [hasNoData]);

  return (
    <styled.SearchResultsContainer showResults={searchResultsVisible}>
      <styled.VisibilitySwitchWrapper
        onClick={onChangeVisibility}
        showCompleteProfileBanner={showCompleteProfileBanner}
        context={context}
      >
        <styled.VisibilitySwitch scrolled={yOffset >= 50}>
          <Icon
            icon={searchResultsVisible ? 'CaretLeft' : 'CaretRight'}
            size="large"
          />
        </styled.VisibilitySwitch>
      </styled.VisibilitySwitchWrapper>

      <styled.FixedWrapper context={context}>
        <styled.SuppliersCounter showResults={searchResultsVisible}>
          <FormattedMessage
            {...i18n.suppliersCounter}
            values={{ counter: shownSuppliers.length }}
          />
        </styled.SuppliersCounter>
        {clearFiltersVisible && (
          <styled.ClearFiltersButton onClick={onResetFilters}>
            <styled.ClearFilterIcon icon="X" size="normal" />
            <FormattedMessage {...i18n.clearAllFilters} />
          </styled.ClearFiltersButton>
        )}
      </styled.FixedWrapper>

      <styled.SuppliersContainer context={context}>
        {hasNoData ? (
          <styled.NoData
            title={<FormattedMessage {...i18n.noVendorsFoundTitle} />}
            actions={[
              <Button color="primary" onClick={onResetFilters}>
                <FormattedMessage {...i18n.deleteAllFilters} />
              </Button>,
            ]}
          >
            <FormattedMessage {...i18n.noVendorsFoundExplanation} />
          </styled.NoData>
        ) : (
          <List
            paginated={{
              page,
              pages: Math.ceil(shownSuppliers.length / PAGE_SIZE),
              pageSize: PAGE_SIZE,
              totalCount: shownSuppliers.length,
            }}
            pageChange={renderNextPage}
          >
            {paginatedSuppliers.map(renderSupplier)}
          </List>
        )}
      </styled.SuppliersContainer>
    </styled.SearchResultsContainer>
  );
};

SearchResults.propTypes = {
  yOffset: PropTypes.number,
  shownSuppliers: PropTypes.array,
  searchResultsVisible: PropTypes.bool,
  clearFiltersVisible: PropTypes.bool,
  loading: PropTypes.bool,
  onChangeVisibility: PropTypes.func,
  onClickSupplierCard: PropTypes.func,
  onResetFilters: PropTypes.func,
  onOpenProfile: PropTypes.func,
  context: PropTypes.string,
  showCompleteProfileBanner: PropTypes.bool,
};
