import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import canvasToBlob from 'blueimp-canvas-to-blob';

import 'cropperjs/dist/cropper.css';

import { Icon } from 'components/elements/icon';

import * as styled from './styles/index';
import i18n from './utils/i18n';

class ImageCropComponent extends Component {
  constructor(props) {
    super(props);

    this.cropper = null;
    this.croppedImage = this.croppedImage.bind(this);
    this.preview = URL.createObjectURL(props.image);
  }

  croppedImage() {
    const { image, onCrop, cropProps } = this.props;
    if (typeof this.cropper.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    const imgUrl = this.cropper.cropper.getCroppedCanvas(cropProps).toDataURL();
    const type = imgUrl.split(',')[0].replace(/(data:|;base64)/g, '');
    const ext = type.split('/')[1];
    const baseName = image.name.replace(/\.[^/.]+$/, '');
    const filename = `${baseName}.${ext}`;
    const blob = canvasToBlob(imgUrl);

    try {
      const imageFile = new File([blob], filename, { type });
      imageFile.preview = imgUrl;
      imageFile.filename = filename;

      onCrop(imageFile);
    } catch (e) {
      // this scenario works for IE (no words can describe my hate to IE)
      blob.preview = imgUrl;
      blob.name = filename;
      blob.filename = filename;
      blob.modifiedDate = new Date();
      blob.lastModifiedDate = new Date();
      blob.type = type;

      onCrop(blob);
    }
  }

  render() {
    const { aspectRatio, onCancel, intl } = this.props;
    return (
      <div>
        <styled.Cropper
          ref={(ref) => {
            this.cropper = ref;
          }}
          src={this.preview}
          aspectRatio={aspectRatio}
          guides={false}
        />
        <styled.Controls>
          <styled.Button color="mono" onClick={onCancel}>
            {intl.formatMessage(i18n.cancel)}
          </styled.Button>

          <styled.Button onClick={this.croppedImage}>
            <Icon icon="Crop" />
            {intl.formatMessage(i18n.crop)}
          </styled.Button>
        </styled.Controls>
      </div>
    );
  }
}

ImageCropComponent.defaultProps = {
  aspectRatio: 1,
  cropProps: { width: 512, height: 512 },
};

ImageCropComponent.propTypes = {
  intl: intlShape,
  image: PropTypes.shape({
    name: PropTypes.string,
  }),
  aspectRatio: PropTypes.number,
  cropProps: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  onCrop: PropTypes.func,
  onCancel: PropTypes.func,
};

export const ImageCrop = injectIntl(ImageCropComponent);
