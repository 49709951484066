import { isDevServer } from 'utils/checkEnvironment';
import { isTest } from 'utils/node-env-vars';
import { INTEGRATION_FRAME_HEIGHT } from 'utils/constants';

/*
 * Resolve whether a feature should be enabled in the UI. Disable everything by
 * default on prod. Enable everything by default on dev. Allow overriding with
 * environment variables.
 */

function resolveFeatureFlag(flagName) {
  if (isDevServer(window) || isTest()) {
    return process.env[flagName] || true;
  }
  return process.env[flagName] || false;
}

/*
 * App Config
 */

export const Config = {
  RecaptchaKey: '6Let9d4kAAAAAJDRWmhl7clj0rsjnfnki76IOIWE',
  BeamerApiKey: 'XiwUrukY34577',
  SegmentApiKey: 'FYPaR11DPXxoxSaL9iblRP3YDF7W6mVw',
  DelightedApiKey: 'N4ULdwCnV1hsZEcn',
  LogRocketAppId: 'kha6x8/gustav',
  MapsApiKey: 'AIzaSyDsflPMuPnRvsaEIJOapl5i2cAApPiTksk',
  ZapierDisposableEmailKey: '1024275/oi0q8wl',
  IPStackAccessKey: 'df1412ca1e7285ea7438981fbcd50400',
  TrackJsToken: '4dc7d3dc223a41588522a2116449db20',
  FirebaseConfig: {
    apiKey: 'AIzaSyCZUJhEYv2awEtsR4MmN2laaAuITMA0LyU',
    authDomain: 'gustav-fuse.firebaseapp.com',
    databaseURL: 'https://gustav-fuse.firebaseio.com',
    projectId: 'gustav-fuse',
    storageBucket: 'gustav-fuse.appspot.com',
    messagingSenderId: '503257434059',
    appId: '1:503257434059:web:4fd670a71cd99485a6984e',
  },
  FirebaseVapidKey:
    'BBE-Yg4Gsjl5YzV92aiYDnVJSsanzn0_pHhMx2JAo9pQEf5hMGvRRGlJYRsTfzLG5ICtGBGVdbBcsDghzCbCz7o',
  Mapbox: {
    username: 'gregscott',
    style: 'cjyirvris04jl1cqoshdghy7v',
    mobileStyle: 'ckm3lxqeo15jb17pghpq4fo0i',
    AccessToken:
      'pk.eyJ1IjoiZ3JlZ3Njb3R0IiwiYSI6ImNsZ2dqeXoweTA2M2UzZG82NnZyMmM2enUifQ.mNPzxjO6ClaJh9fy9e4SdQ',
  },
  Bullhorn: {
    clientId: '0b51ba02-e5f2-4094-b335-bb63a5efc031',
  },
  People20: {
    dev: {
      baseUrl: 'https://recruiter.people20.net/GatewayDEV',
      partnerGuid: '8c272ac7-7400-40e9-a065-7ed252d609b1',
      subscriptionGuid: 'adb42709-01f4-4074-8bd5-88c058f942ec',
      apiKey: 'c50bca66-cf9f-458c-bb83-313dab5fa5c6',
    },
    production: {
      baseUrl: 'https://recruiter.people20.net/Gateway',
      partnerGuid: '8c272ac7-7400-40e9-a065-7ed252d609b1',
      subscriptionGuid: 'adb42709-01f4-4074-8bd5-88c058f942ec',
      apiKey: 'B49526FE-F53B-41AD-A2BE-36C4D658FC0D',
    },
  },
  ITServe: {
    signupUrl: 'https://itserve.glueup.com/membership/3748/apply',
    forgotPasswordUrl: 'https://itserve.glueup.com/account/recover',
  },
  Chargebee: {
    dev: {
      site: 'gustav-test',
      publishableKey: 'test_jDonkbF6HmTyrxq6FS9EcdYuJXSB1kcuck',
    },
    prod: {
      site: 'gustav',
      publishableKey: 'live_Qhqcucdq5Y3aPY2SQoV9Zvjm52e3DvRbV',
    },
  },
  // Feature flags below
  CandidatesEnabled: resolveFeatureFlag('CANDIDATES_ENABLED'),
  WorkOrdersEnabled: resolveFeatureFlag('WORK_ORDERS_ENABLED'),
  IntegrationFrameHeight: INTEGRATION_FRAME_HEIGHT,
};
