import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsQueryTitle: {
    id: 'app.components.Jobs.Imported.EmptyState.noResultsQueryTitle',
    defaultMessage: 'No imported jobs found',
  },
  noResultsQuerySubtitle: {
    id: 'app.components.Jobs.Imported.EmptyState.noResultsQuerySubtitle',
    defaultMessage: "There aren't any imported jobs.",
  },

  noResultsFilterTitle: {
    id: 'app.components.Jobs.Imported.EmptyState.noResultsFilterTitle',
    defaultMessage: 'No matching jobs found',
  },
  noResultsFilterSubtitle: {
    id: 'app.components.Jobs.Imported.EmptyState.noResultsFilterSubtitle',
    defaultMessage:
      'The combination of filters you used has no results. Clear all filters or try a different criteria.',
  },

  goToArchivedJobs: {
    id: 'app.components.Jobs.Assigned.EmptyState.goToArchivedJobs',
    defaultMessage: 'Go to archived jobs',
  },
  resetFilters: {
    id: 'app.components.Jobs.Imported.EmptyState.resetFilters',
    defaultMessage: 'Clear all filters',
  },
});
