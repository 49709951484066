import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PremiumFeatureBadge } from 'components/misc/Badge';
import documentIllustration from 'images/illustrations/document.png';
import noteIllustration from 'images/illustrations/note1.png';
import { Icon } from 'components/elements/icon';

import * as styled from './styles';
import i18n from './utils/i18n';

export const PremiumFeatureScene = () => (
  <>
    <styled.Header>
      <FormattedMessage {...i18n.header} />
      <PremiumFeatureBadge type="feature" />
    </styled.Header>

    <styled.Container>
      <styled.Subheader>
        <FormattedMessage {...i18n.description} />
      </styled.Subheader>

      <styled.Columns>
        <styled.Col>
          <styled.Cell>
            <styled.Image src={documentIllustration} />
          </styled.Cell>
          <styled.Cell>
            <styled.Label>
              <FormattedMessage {...i18n.labelDocuments} />
            </styled.Label>
          </styled.Cell>
          <styled.Cell>
            <styled.List bordered>
              <li>
                <FormattedMessage {...i18n.feature1} />
              </li>
              <li>
                <FormattedMessage {...i18n.feature2} />
              </li>
            </styled.List>
          </styled.Cell>
        </styled.Col>
        <styled.Col>
          <styled.Cell>
            <styled.Image src={noteIllustration} />
          </styled.Cell>
          <styled.Cell>
            <styled.Label margin="1rem 3rem 0 0">
              <FormattedMessage {...i18n.labelNotes} />
            </styled.Label>
          </styled.Cell>
          <styled.Cell>
            <styled.List>
              <li>
                <FormattedMessage {...i18n.feature3} />
              </li>
              <li>
                <FormattedMessage {...i18n.feature4} />
              </li>
            </styled.List>
          </styled.Cell>
        </styled.Col>
      </styled.Columns>

      <styled.ButtonWrapper>
        <styled.Button to="/subscription-plan" color="tealDark">
          <Icon icon="ShieldFill" color="white" size="small" />
          <FormattedMessage {...i18n.buttonUpgrade} />
        </styled.Button>
      </styled.ButtonWrapper>
    </styled.Container>
  </>
);
