import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Colors } from 'components/utils/styles/ui';
import { PremiumFeature } from 'components/misc/PremiumFeature';
import imageSrc from 'images/PremiumFeatures/integrations.png';
import JDLogoSrc from 'images/logos/JD-color.png';

import * as styled from './styles';
import i18n from './utils/i18n';

export const PremiumFeatureScene = ({ hasBorder }) => (
  <styled.Wrapper>
    <styled.Container hasBorder={hasBorder}>
      <PremiumFeature
        type="feature"
        header={i18n.header}
        description={i18n.description}
        features={[i18n.feature1, i18n.feature2, i18n.feature3]}
        image={imageSrc}
      >
        <>
          <styled.Logos>
            <styled.Logo>
              <styled.SvgImage icon="Bullhorn" color={Colors.bhOrange} />
            </styled.Logo>
            <styled.Logo>
              <styled.Image src={JDLogoSrc} />
            </styled.Logo>
          </styled.Logos>
          <styled.Logos margin="1">
            <span>
              <FormattedMessage {...i18n.labelBullhorn} />
            </span>
            <span>
              <FormattedMessage {...i18n.labelJobdiva} />
            </span>
          </styled.Logos>
        </>
      </PremiumFeature>
    </styled.Container>
  </styled.Wrapper>
);

PremiumFeatureScene.propTypes = {
  hasBorder: PropTypes.bool,
};

PremiumFeatureScene.defaultProps = {
  hasBorder: true,
};
