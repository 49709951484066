import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';

import { UserTooltip } from 'components/misc/UserTooltip';
import { Tooltip } from 'components/overlay/Tooltip';
import { Icon } from 'components/elements/icon';
import { userContact } from 'containers/Clients/utils/helpers';
import { userName } from 'components/utils/name';

import * as styled from './styles';

export const UserBadge = function UserBadge(props) {
  const { className, user, tooltip, tooltipFixed, link, simple, icon } = props;
  const linkTo = () => {
    if (user.client_contact) {
      return `/clients/${user.client_contact.client_id}/contact/${user.client_contact.id}`;
    }
    if (user.id) {
      return `/user/profile/${user.id}`;
    }
    return '/settings/members';
  };
  const linkEnabled = link && user.client_contact;
  const record = userContact(user);

  const badgeEl = (
    <styled.User
      className={className}
      link={linkEnabled}
      target={null}
      {...(linkEnabled ? { to: linkTo() } : {})}
    >
      {simple ? (
        <styled.IconWrapper>
          <Icon color="white" icon={icon} />
        </styled.IconWrapper>
      ) : (
        <styled.Avatar size="xsmall" activity={false} user={record} />
      )}
      <styled.UserName>{userName(record)}</styled.UserName>
    </styled.User>
  );

  if (tooltip && simple) {
    return <Tooltip content={record.email}>{badgeEl}</Tooltip>;
  }
  if (tooltip || tooltipFixed) {
    return (
      <UserTooltip
        user={record}
        position={isString(tooltip) ? tooltip : null}
        fixed={tooltipFixed}
      >
        {badgeEl}
      </UserTooltip>
    );
  }

  return badgeEl;
};

UserBadge.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    color: PropTypes.string,
    client_contact: PropTypes.object,
  }),
  tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  tooltipFixed: PropTypes.bool,
  link: PropTypes.bool,
  simple: PropTypes.bool,
  icon: PropTypes.string,
};

UserBadge.defaultProps = {
  link: true,
  simple: false,
  icon: 'EnvelopeSimple',
};
