import qs from 'qs';
import { httpGet } from 'utils';

import {
  JOBS_COUNTERS_REQUEST,
  JOBS_COUNTERS_SUCCESS,
  JOBS_COUNTERS_ERROR,
} from './constants';

export function fetchCounters(params = {}) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: JOBS_COUNTERS_REQUEST });

      httpGet(`/jobs/-/counters?${qs.stringify(params)}`)
        .then((counters) => {
          dispatch({
            type: JOBS_COUNTERS_SUCCESS,
            payload: { counters },
          });

          resolve(counters);
        })
        .catch((error) => {
          dispatch({ type: JOBS_COUNTERS_ERROR, error });

          reject(error);
        });
    });
}
