import React from 'react';
import PropTypes from 'prop-types';

export const GustavLogo = function Gustav({
  className,
  incrementalFactor = 1,
}) {
  const width = 45 * incrementalFactor;
  const height = 65 * incrementalFactor;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="-8 0 35 27"
    >
      <g fill="#0AAFC4" fillRule="nonzero">
        <path d="M10.856 23.215c-2.994 0-5.554-.85-7.646-2.552L.289 24.137c2.92 2.374 6.491 3.58 10.567 3.58 4.147 0 7.718-1.206 10.675-3.58l-2.921-3.474c-2.092 1.702-4.689 2.552-7.754 2.552zM10.567.319c-2.633.07-4.869.992-6.672 2.765-1.911 1.843-2.885 4.11-2.885 6.77 0 2.657.974 4.926 2.885 6.769 1.912 1.843 4.256 2.764 6.997 2.764 2.74 0 5.085-.921 6.997-2.764 1.911-1.843 2.885-4.112 2.885-6.77 0-1.985-.541-3.721-1.551-5.245h2.705V.319h-11.36zm4.003 5.813c.938 1.027 1.407 2.268 1.407 3.721 0 1.453-.469 2.694-1.407 3.722-.937 1.028-2.163 1.524-3.714 1.524-1.551 0-2.777-.496-3.715-1.524-.938-1.028-1.407-2.269-1.407-3.722 0-1.453.47-2.694 1.407-3.721.938-1.028 2.164-1.524 3.715-1.524 1.55 0 2.777.496 3.714 1.524z" />
      </g>
    </svg>
  );
};

GustavLogo.propTypes = {
  className: PropTypes.string,
  incrementalFactor: PropTypes.number,
};
