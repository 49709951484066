import React from 'react';
import PropTypes from 'prop-types';

import { inputFor } from './utils/inputTypeFor';
import { inputProperties } from './utils/inputPropertiesForFieldset';
import * as styled from './styles/fieldset';

import { FormLabel } from '../label/label';

export const CheckboxListFieldset = function CheckboxListFieldset(props) {
  const { className, includeIndicator = true } = props;
  const input = inputProperties(props);
  const checkboxList = inputFor(input);

  return (
    <styled.Fieldset className={className}>
      <FormLabel
        includeIndicator={includeIndicator}
        value={input.label}
        required={input.required}
        elementToRefer={input.id}
      />
      <styled.Container>{checkboxList}</styled.Container>
    </styled.Fieldset>
  );
};

CheckboxListFieldset.propTypes = {
  className: PropTypes.string,
  includeIndicator: PropTypes.bool,
};
