import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, reject, some } from 'lodash';
import moment from 'moment';

import { Icon } from 'components/elements/icon';
import { UserBadge } from 'components/misc/UserBadge';
import { CardSection } from 'components/misc/Card';

import { EditModal as Modal } from './modal';
import { Form } from './form';

import i18n from '../utils/i18n';
import * as styled from '../styles/section';
import * as styledUI from '../styles/sidebar';
import { FormDefinitionSidebar } from '../utils/formDefinition';

export class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false };
  }

  handleSave = (company) => {
    const { saveForm } = this.props;

    saveForm(company).then(() => {
      this.toggleModal(false);
    });
  };

  isCompleted() {
    const { company } = this.props;

    return !some(
      [company.billing_address, company.website, company.contact_person],
      isEmpty
    );
  }

  toggleModal(modal) {
    this.setState({ modal });
  }

  renderDescriptionList() {
    const { edit, isPublic, canSeeUsers, company, rootPath } = this.props;
    const {
      website,
      billing_address: address,
      contact_person: contact,
      inserted_at: insertedAt,
      user_count: userCount,
    } = company;
    const na = (
      <styled.Message isMissing>
        <FormattedMessage {...i18n.na} />
      </styled.Message>
    );
    const span = (text) => (text ? <span>{text}</span> : edit && na);

    const items = [
      {
        term: <FormattedMessage {...i18n.labelHeadquarter} />,
        description: span(address && address.name),
        icon: 'MapPin',
      },
      {
        term: <FormattedMessage {...i18n.labelWebsite} />,
        description: isEmpty(website) ? (
          na
        ) : (
          <styledUI.SmartLink to={website} companyLink target="_blank">
            <styledUI.TruncateWithTooltip label={website} />
          </styledUI.SmartLink>
        ),
        icon: 'Link',
      },
      {
        term: <FormattedMessage {...i18n.labelContactPerson} />,
        description: contact ? (
          <UserBadge user={contact} link={!isPublic} />
        ) : (
          span(contact)
        ),
      },
      {
        term: <FormattedMessage {...i18n.labelPhone} />,
        description: span(contact && contact.phone),
        icon: 'Phone',
      },
      {
        term: <FormattedMessage {...i18n.labelTeamSize} />,
        description: canSeeUsers ? (
          <styledUI.SmartLink to={`${rootPath}/users`}>
            <FormattedMessage
              {...i18n.labelUserCount}
              values={{ count: userCount }}
            />
          </styledUI.SmartLink>
        ) : (
          <FormattedMessage
            {...i18n.labelUserCount}
            values={{ count: userCount }}
          />
        ),
        icon: 'User',
      },
      {
        term: <FormattedMessage {...i18n.labelJoined} />,
        description: span(moment(insertedAt).format('MMMM YYYY')),
        icon: 'Clock',
      },
    ];

    return (
      <styledUI.DescriptionList
        isVertical
        isBordered={false}
        items={reject(items, { description: false })}
      />
    );
  }

  renderModal() {
    const { company, members, onFetchPlaces } = this.props;
    const membersOptions = members.map((user) => ({
      id: user.id,
      name: user.name || `${user.first_name} ${user.last_name}`,
      phone_number: user.phone_number,
    }));

    return (
      <Modal
        title={i18n.modalTitleInfo}
        onClose={() => this.toggleModal(false)}
      >
        <Form
          editObject={company}
          members={membersOptions}
          saveForm={this.handleSave}
          formDefinition={FormDefinitionSidebar}
          onFetchPlaces={onFetchPlaces}
        />
      </Modal>
    );
  }

  render() {
    const { company, edit } = this.props;
    const { modal } = this.state;

    return (
      <>
        <styled.Card isSimple bordered data-manual="profile.facts">
          <CardSection title={<FormattedMessage {...i18n.detailsCardTitle} />}>
            {edit && (
              <styledUI.ButtonEdit
                color={this.isCompleted() ? 'primary' : 'danger'}
                onClick={() => this.toggleModal(true)}
                type="blank"
                size="large"
              >
                <Icon icon="NotePencil" />
                {isEmpty(company.website) && (
                  <styledUI.TooltipButton
                    onClick={() => this.toggleModal(true)}
                  >
                    <FormattedMessage {...i18n.tooltipWebsite} />
                  </styledUI.TooltipButton>
                )}
              </styledUI.ButtonEdit>
            )}
            {this.renderDescriptionList()}
          </CardSection>
        </styled.Card>
        {edit && modal && this.renderModal()}
      </>
    );
  }
}

Sidebar.propTypes = {
  edit: PropTypes.bool.isRequired,
  company: PropTypes.object.isRequired,
  members: PropTypes.array,
  saveForm: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
  canSeeUsers: PropTypes.bool,
  rootPath: PropTypes.string.isRequired,
  onFetchPlaces: PropTypes.func.isRequired,
};
