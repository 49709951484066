import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { IconCircle } from 'components/elements/icon';
import { SmartLink as SmartLinkBase } from 'components/navigation/SmartLink';
import { Button as ButtonBase, BUTTON_SIZES } from 'components/elements/button';

export const Wrapper = styled.div``;

export const Header = styled.h2`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid ${Colors.outline};
  text-align: center;
  white-space: pre-wrap;

  & > svg {
    margin-right: 1.6rem;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 0 1.6rem;
  margin: 2rem 0;
  border-radius: 0.3rem;
  border: 1px solid ${Colors.outline};
  border-top: 0.4rem solid ${({ color }) => Colors[color] || color};
  background-color: ${({ background }) => Colors[background] || background};
`;

export const BannerHeader = styled.h5`
  margin: 0;
`;

export const BannerIcon = styled(IconCircle)`
  margin-right: 1.6rem;
`;

export const BannerLink = styled(SmartLinkBase)`
  color: ${({ color }) => Colors[color] || color};
  text-decoration: underline;
`;

export const Description = styled.div`
  line-height: 2.8rem;
`;

export const Image = styled.img`
  width: 100%;
  margin: 2rem 0;
`;

export const FeaturesHeader = styled.div`
  color: ${({ color }) => Colors[color] || color};
  font-weight: 500;
  line-height: 2.8rem;
`;

export const Features = styled.ul`
  margin: 0;
  padding: 0 0 2rem 1.5rem;
  list-style: disc;
  line-height: 2.8rem;
`;

export const Feature = styled.li``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  border-top: 1px solid ${Colors.outline};
`;

export const Button = styled(ButtonBase).attrs({
  size: BUTTON_SIZES.normal,
})`
  &:only-child {
    margin: 0 auto;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;
