import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* eslint react/no-did-update-set-state: 0 */
export class CounterBadge extends Component {
  constructor(props) {
    super(props);

    this.state = { animate: false };
  }

  componentDidUpdate(prevProps) {
    const { counter } = this.props;
    if (counter > prevProps.counter) {
      this.setState({ animate: true });
      setTimeout(() => {
        this.setState({ animate: false });
      }, 1000);
    }
  }

  render() {
    const { counter, wrapper: WrapperEl } = this.props;
    const { animate } = this.state;

    if (counter === 0) {
      return null;
    }

    return <WrapperEl animate={animate}>{counter}</WrapperEl>;
  }
}

CounterBadge.propTypes = {
  counter: PropTypes.number,
  wrapper: PropTypes.object,
};
