import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { partition, isEmpty, get, find, escapeRegExp } from 'lodash';
import { ReactPageClick } from 'react-page-click';

import { userID } from 'utils/authorization/utils/auth';
import { Icon } from 'components/elements/icon';

import * as styled from './styles/scope';
import i18n from './utils/i18n';

export class ScopeFilter extends Component {
  constructor(props) {
    super(props);

    this.userID = userID();
    this.state = { expanded: false, input: '' };
  }

  handleChange(scope) {
    const { onChange } = this.props;

    onChange(scope);
    this.setState({ expanded: false });
  }

  filterMembers(members) {
    const { input } = this.state;
    const re = new RegExp(escapeRegExp(input), 'i');

    if (isEmpty(input)) {
      return members;
    }

    return members.filter((u) => re.test(`${u.first_name} ${u.last_name}`));
  }

  filterTeams(teams) {
    const { input } = this.state;
    const re = new RegExp(escapeRegExp(input), 'i');

    if (isEmpty(input)) {
      return teams;
    }

    return teams.filter((t) => re.test(t.name));
  }

  renderCompany(isActive) {
    const { totalCounter } = this.props;

    return (
      <>
        <styled.IconWrapper color="tealLighter">
          <Icon icon="Users" />
        </styled.IconWrapper>
        <FormattedMessage {...i18n.scopeEveryone} />
        &nbsp;<styled.Counter>({totalCounter})</styled.Counter>
        {isActive && <Icon icon="Check" color="tealDark" />}
      </>
    );
  }

  renderMember(user, isActive) {
    const avatarContext = {
      picture: user.avatar,
      initials: `${user.first_name[0]}${user.last_name[0]}`,
      color: user.color,
    };

    return (
      <>
        <styled.Avatar size="mmsmall" shape="rounded" context={avatarContext} />
        <styled.ItemName>
          {user.first_name} {user.last_name} &nbsp;
        </styled.ItemName>
        {(user.counter || user.counter === 0) && (
          <styled.Counter>({user.counter})</styled.Counter>
        )}
        {isActive && <Icon icon="Check" color="tealDark" />}
      </>
    );
  }

  renderTeam(team, isActive) {
    return (
      <>
        <styled.IconWrapper color="persianBlueLightest">
          <Icon icon="Handshake" />
        </styled.IconWrapper>

        <styled.ItemName>{team.name} &nbsp;</styled.ItemName>
        {(team.counter || team.counter === 0) && (
          <styled.Counter>({team.counter})</styled.Counter>
        )}
        {isActive && <Icon icon="Check" color="tealDark" />}
      </>
    );
  }

  renderSelection() {
    const { scope, members, teams } = this.props;

    if (scope === 'company') {
      return this.renderCompany();
    }

    const team = find(teams, { id: scope?.team });
    if (team) {
      return this.renderTeam(team);
    }

    const member = find(members, { id: scope?.member || this.userID });
    if (member) {
      return this.renderMember(member);
    }

    return null;
  }

  renderTeamsList(teams) {
    const { scope } = this.props;

    return teams.map((team) => {
      const isActive = scope?.team === team.id;

      return (
        <styled.Item
          key={`team-${team.id}`}
          active={isActive}
          onClick={() => this.handleChange({ team: team.id })}
        >
          {this.renderTeam(team, isActive)}
        </styled.Item>
      );
    });
  }

  renderMembersList(users) {
    const { scope } = this.props;

    return users.map((user) => {
      const isActive =
        scope?.member === user.id ||
        (isEmpty(scope) && user.id === this.userID);

      return (
        <styled.Item
          key={`user-${user.id}`}
          active={isActive}
          onClick={() => this.handleChange({ member: user.id })}
        >
          {this.renderMember(user, isActive)}
        </styled.Item>
      );
    });
  }

  render() {
    const { scope, members, teams, dataManual, className } = this.props;
    const { expanded, input } = this.state;
    const [currentMember, restMembers] = partition(members, {
      id: this.userID,
    });
    const isScopeCompany = scope === 'company';
    const restMembersFiltered = this.filterMembers(restMembers);
    const [primaryTeam, restTeams] = partition(teams, {
      id: get(currentMember, '0.primary_team.id'),
    });
    const restTeamsFiltered = this.filterTeams(restTeams);

    return (
      <ReactPageClick notify={() => this.setState({ expanded: false })}>
        <styled.Wrapper className={className}>
          <styled.Scope
            data-manual={dataManual}
            onClick={() => this.setState({ expanded: !expanded })}
          >
            {this.renderSelection()}
            <styled.Expander>
              <Icon size="small" icon={expanded ? 'CaretUp' : 'CaretDown'} />
            </styled.Expander>
          </styled.Scope>

          {expanded && (
            <styled.Content>
              <styled.Input
                size="small"
                value={input}
                placeholder={
                  isEmpty(teams)
                    ? i18n.scopeSearchUser
                    : i18n.scopeSearchUserTeam
                }
                onChange={(e) => this.setState({ input: e })}
              />
              <styled.List>
                <styled.Item
                  active={isScopeCompany}
                  onClick={() => this.handleChange('company')}
                >
                  {this.renderCompany(isScopeCompany)}
                </styled.Item>
                {this.renderMembersList(currentMember)}
                {this.renderTeamsList(primaryTeam)}
              </styled.List>
              {(restMembersFiltered.length > 0 ||
                restTeamsFiltered.length > 0) && (
                <styled.List scrollable>
                  {this.renderTeamsList(restTeamsFiltered)}
                  {restMembersFiltered.length > 0 &&
                    restTeamsFiltered.length > 0 && <styled.Divider />}
                  {this.renderMembersList(restMembersFiltered)}
                </styled.List>
              )}
            </styled.Content>
          )}
        </styled.Wrapper>
      </ReactPageClick>
    );
  }
}

ScopeFilter.propTypes = {
  scope: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf(['company'])]),
  members: PropTypes.array,
  teams: PropTypes.array,
  totalCounter: PropTypes.number,
  onChange: PropTypes.func,
  dataManual: PropTypes.string,
  className: PropTypes.string,
};
