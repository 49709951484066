import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject, identity } from 'lodash';

import { TextSmartLink } from 'components/navigation/TextSmartLink';

import * as styled from './styles';

export const Tags = (props) => {
  const { className, tags, size, onAdd, linkFn, formatFn, onRemove } = props;

  if (isEmpty(tags)) {
    return null;
  }

  return (
    <styled.TagsList className={className}>
      {tags.map((tag, index) => (
        <styled.Tag
          key={`tag-${isObject(tag) ? tag.name : tag}`}
          size={size}
          onClick={onAdd ? () => onAdd(tag) : null}
        >
          {onAdd && <styled.AddIcon icon="Plus" color="text" />}
          {linkFn ? (
            <TextSmartLink to={linkFn(tag)} target="_blank">
              {formatFn(tag)}
            </TextSmartLink>
          ) : (
            formatFn(tag)
          )}
          {onRemove && (
            <styled.RemoveIcon
              icon="X"
              color="tealDark"
              onClick={() => onRemove(index)}
            />
          )}
        </styled.Tag>
      ))}
    </styled.TagsList>
  );
};

Tags.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.array,
  size: PropTypes.oneOf(['small', 'normal']),
  onAdd: PropTypes.func,
  linkFn: PropTypes.func,
  formatFn: PropTypes.func,
  onRemove: PropTypes.func,
};

Tags.defaultProps = {
  tags: [],
  size: 'normal',
  formatFn: identity,
};
