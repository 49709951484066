import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { isEmpty, isString } from 'lodash';

import { Menu } from 'components/navigation/menu/menu';
import { Badge, WorkStatusBadge, WorkTypeBadge } from 'components/misc/Badge';

import * as styled from './styles/context-header';

const ContextHeaderComponent = function ContextHeader({
  intl,
  className,
  context,
  breadcrumb,
  title,
  badges,
}) {
  const breadcrumbEl = isEmpty(breadcrumb) ? null : (
    <styled.Breadcrumb routeTo={breadcrumb[0]} title={breadcrumb[1]} />
  );

  const titleEl = title ? (
    <styled.Label>
      {isString(title) || isValidElement(title)
        ? title
        : intl.formatMessage(title)}
    </styled.Label>
  ) : null;

  /* eslint-disable react/no-array-index-key */
  const badgesEl = badges.map((b, ind) =>
    React.cloneElement(b, { key: `badge${ind}` })
  );

  const menu = !isEmpty(context) ? (
    <styled.Menu>
      <Menu context={context} maxItems={9} />
    </styled.Menu>
  ) : null;

  return (
    <styled.Header className={className}>
      {breadcrumbEl}
      {titleEl}

      {!isEmpty(badgesEl) && <styled.Badges>{badgesEl}</styled.Badges>}

      {menu}
    </styled.Header>
  );
};

ContextHeaderComponent.propTypes = {
  intl: PropTypes.object,
  context: PropTypes.object,
  breadcrumb: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  badges: PropTypes.arrayOf(
    PropTypes.oneOfType([Badge, WorkStatusBadge, WorkTypeBadge])
  ),
  className: PropTypes.string,
};

ContextHeaderComponent.defaultProps = {
  title: null,
  context: {},
  badges: [],
};

export const ContextHeader = injectIntl(ContextHeaderComponent);
