import React from 'react';

import { IS_MOBILE } from 'utils/constants';

import { ReleaseUpdateComponent as ReleaseUpdateMobile } from './mobile';
import { ReleaseUpdateComponent as ReleaseUpdateDesktop } from './desktop';

const handleReloadApp = () => window.location.reload();

export const ReleaseUpdateComponent = () =>
  IS_MOBILE ? (
    <ReleaseUpdateMobile onClick={handleReloadApp} />
  ) : (
    <ReleaseUpdateDesktop onClick={handleReloadApp} />
  );
