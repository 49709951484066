import { find, last, reduce } from 'lodash';

const jwtProp = function jwtProp(prop) {
  const token = getJwt();

  if (!token) {
    return undefined;
  }

  const foundProp = find(token.payload.sub.split('+'), (part) =>
    part.includes(prop)
  );

  return foundProp ? last(foundProp.split(':')) : null;
};

export const decodeBase64 = function decodeBase64(base64) {
  return JSON.parse(atob(base64));
};

const getToken = (tokenKey) => {
  const item = localStorage.getItem(tokenKey);
  if (item === null) {
    return undefined;
  }
  const [header, payload] = item.split('.');

  return {
    header: decodeBase64(header),
    payload: decodeBase64(payload),
  };
};

export const getJwt = () => getToken('phoenixAuthToken');

export const getJwtExpiration = () => getToken('phoenixExpToken');

export const userRole = function userRole() {
  return jwtProp('Role');
};

export const companyType = function companyType() {
  return jwtProp('CompanyType');
};

export const companySpecialType = function companySpecialType() {
  return jwtProp('SpecialType');
};

export const companyFeatures = function companyFeatures() {
  const features = jwtProp('CompanyFeatures');

  return reduce(
    features ? features.split(',') : [],
    (acc, feat) => ({ ...acc, [feat]: true }),
    {}
  );
};

export const isEmployer = function isEmployer() {
  return !!companyFeatures().employer;
};

export const isSupplier = function isSupplier() {
  return !!companyFeatures().supplier;
};

export const isAdministrator = function isAdministrator() {
  return (
    companyType() === 'administrator' ||
    localStorage.getItem('adminMembershipId')
  );
};

export const companyID = function companyID() {
  return jwtProp('Company');
};

export const userID = function userID() {
  return jwtProp('User');
};

export const userEmail = function userEmail() {
  const email = jwtProp('Email');

  return email ? decodeURIComponent(email) : email;
};
