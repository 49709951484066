import {
  SETUP_P20_USER_SUCCESS,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  GET_SESSION_VIA_TOKEN_REQUEST,
  GET_SESSION_VIA_TOKEN_SUCCESS,
  GET_SESSION_VIA_TOKEN_ERROR,
} from 'containers/Login/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';

const initialState = {
  isFetching: false,
  authenticated: false,
  permissions: false,
  companyType: false,
  membershipRole: null,
  adminMembershipId: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
    case GET_SESSION_REQUEST:
    case GET_SESSION_VIA_TOKEN_REQUEST:
      return { ...state, isFetching: true };

    case SIGN_IN_ERROR:
    case GET_SESSION_ERROR:
    case GET_SESSION_VIA_TOKEN_ERROR:
      return { ...state, isFetching: false };

    case SIGN_IN_SUCCESS:
    case GET_SESSION_SUCCESS:
    case GET_SESSION_VIA_TOKEN_SUCCESS:
    case SETUP_P20_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticated: true,
        companyType: action.company.company_type,
        features: action.company.features,
        membershipRole: action.role,
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authenticated: true,
        companyType: action.payload.company.company_type,
        features: action.payload.company.features,
        membershipRole: action.payload.role,
      };

    case 'SET_ADMIN_MEMBERSHIP_ID':
      return {
        ...state,
        adminMembershipId: action.adminMembershipId,
      };

    case 'DELETE_ADMIN_MEMBERSHIP_ID':
      return {
        ...state,
        adminMembershipId: null,
      };

    default:
      return state;
  }
};
