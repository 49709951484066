import styled from 'styled-components';

import { Colors, lightShadow } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';

export const Wrapper = styled.div`
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ hasErrors }) => (hasErrors ? Colors.red : Colors.outline)};
`;

export const IconPicker = styled.div`
  position: absolute;
  z-index: 900;
  width: 34.8rem;
  border-radius: 0.4rem;
  top: 0;
  left: calc(100% + 0.6rem);
  box-shadow: ${lightShadow};
  background-color: ${Colors.white};
`;

export const Header = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: ${Colors.tealDarker};
  color: ${Colors.white};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background-color: ${Colors.white};
  padding: 0 1.4rem;

  & svg {
    flex-shrink: 0;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  text-overflow: ellipsis;
  padding-left: 0.9rem;

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: ${Colors.textLighter};
  }
`;

export const Icons = styled.div`
  max-height: 18.5rem;
  overflow-y: auto;
  background-color: ${Colors.headerBG};
  padding: 1.2rem 1rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 1.5rem;
  column-gap: 1rem;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
  border: 1px solid ${Colors.outline};
  background-color: ${Colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${Colors.tealLighter};
    box-shadow: 0 0 1.2rem rgba(19, 41, 61, 0.16);
  }
`;

export const ClearIcon = styled(IconBase)`
  cursor: pointer;
`;

export const NoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 18.5rem;
  background-color: ${Colors.headerBG};
  font-weight: 500;
`;

export const NoResultsIcon = styled.div`
  width: 4rem;
  height: 4rem;
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.white};
`;
