import React from 'react';
import PropTypes from 'prop-types';

import { ImportsList } from '../ImportsList/Loadable';
import { NoResultsQuery, NoResultsFilter } from './EmptyState';

import i18n from './utils/i18n';

const ARCHIVED_JOBS_FILTER = [
  { id: 'status', value: 'draft' },
  { id: 'imported', value: true },
  { id: 'archived', value: true },
  { id: 'ats_member_id', value: 'company' },
];

export const Archived = (props) => {
  const { match, onFetchCounters } = props;

  return (
    <ImportsList
      title={i18n.title}
      match={match}
      context="archived"
      defaultFiltered={ARCHIVED_JOBS_FILTER}
      onFetchCounters={onFetchCounters}
      emptyStateDefault={NoResultsQuery}
      emptyStateFiltered={({ onFilteredChange }) =>
        NoResultsFilter({
          resetFilter: () => onFilteredChange(ARCHIVED_JOBS_FILTER),
        })
      }
    />
  );
};

Archived.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  onFetchCounters: PropTypes.func,
};
