import React from 'react';
import PropTypes from 'prop-types';

import { SmartLink } from 'components/navigation/SmartLink';

import * as styled from './styles';

export const TableTabs = ({ items, active }) => (
  <styled.Container>
    <styled.Tabs>
      {items.map(({ slug, label, path }) => (
        <SmartLink to={path} key={slug}>
          <styled.Tab active={slug === active}>{label}</styled.Tab>
        </SmartLink>
      ))}
    </styled.Tabs>
  </styled.Container>
);

TableTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  active: PropTypes.string,
};
