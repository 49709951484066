import styled from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { tableTabsHeight } from 'components/structure/page/utils/calculations';

export const Container = styled.div`
  width: 100%;
  z-index: 1;
  padding-left: 2rem;
  margin: 2.4rem 0 -1.2rem 0;
  background: ${Colors.white};
`;

export const Tabs = styled.div`
  height: ${tableTabsHeight}rem;
  justify-content: flex-start;
  display: flex;
  margin-right: auto;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.grey};
`;

export const Tab = styled.div`
  padding: ${({ active }) => (active ? ' .7rem 2rem' : '1rem 2rem .7rem')};
  margin: 0.3rem 0 0;
  font-size: 1.3rem;
  color: ${Colors.text};
  border: ${({ active }) => (active ? `.1rem solid ${Colors.grey}` : 0)};
  border-top: ${({ active }) =>
    active ? `.3rem solid ${Colors.tealDarker}` : 0};
  border-bottom: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: pointer;
`;
