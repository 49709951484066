import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Overlay } from 'components/structure/overlay/overlay';

import * as styled from '../styles/modal';

export const EditModal = function EditModal(props) {
  const { title, children, size, onClose } = props;

  return (
    <Overlay closeOverlay={onClose}>
      <styled.ModalView size={size}>
        <styled.Wrapper>
          <styled.Title>
            <FormattedMessage {...title} />
          </styled.Title>
          {children}
        </styled.Wrapper>
      </styled.ModalView>
    </Overlay>
  );
};

EditModal.propTypes = {
  title: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  onClose: PropTypes.func.isRequired,
};
