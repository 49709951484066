import i18n from './i18n';

export const FlashDefinition = {
  credentialsValidationSuccess: {
    context: [{ detail: i18n.credentialsValidationSuccess }],
    status: 'success',
  },
  invalidCredentials: {
    context: [{ detail: i18n.invalidCredentials }],
    status: 'error',
  },
};
