import styled from 'styled-components';
import { Colors, mediaSizes } from 'components/utils/styles/ui';
import { InputSearch } from 'components/form/input/search';
import { Select } from 'components/form/select/select';
import { SmartLink } from 'components/navigation/SmartLink';

import { mediaMaxWidth } from '../utils/media';

const fieldsHeight = 'height: 3rem !important;';
const media = mediaMaxWidth(mediaSizes.medium);

export const SupplierHubContainer = styled.div``;

export const BottomContainer = styled.div`
  background-color: ${Colors.white};
  display: flex;
`;

const getSearchContainerTop = (showCompleteProfileBanner, context) => {
  if (context === 'external') return '0';
  if (showCompleteProfileBanner) return '10rem';
  return '5rem';
};
export const SearchContainer = styled.div`
  padding: 1.6rem 4rem 1.6rem 2rem;
  background-color: ${({ context }) =>
    context === 'external' ? Colors.tealDarker : Colors.white};
  box-shadow: 0 0.8rem 0.9rem 0 rgba(0, 0, 0, 0.07);
  top: ${({ showCompleteProfileBanner, context }) =>
    getSearchContainerTop(showCompleteProfileBanner, context)};
  position: sticky;
  z-index: 3;
  display: flex;
`;

export const SearchField = styled(InputSearch)`
  min-width: 16.5rem;
  width: 15vw;
  ${fieldsHeight}

  ${media`
    width: 60vw;
  `}
`;

export const LocationField = styled(InputSearch)`
  min-width: 25rem;
  width: 20vw;
  ${fieldsHeight}

  ${media`
    width: 60vw;
  `}
`;

export const SelectField = styled(Select)`
  min-width: 20rem;
  width: 15vw;
  ${fieldsHeight}

  & > div {
    z-index: 3;
  }
`;

const Side = styled.div`
  width: 100%;
  display: flex;
`;

export const LeftSide = styled(Side)`
  align-items: flex-start;
  justify-content: flex-start;

  & > * {
    margin-right: 2rem;
  }

  ${media`
    flex-wrap: wrap;

    & > * {
      margin-top: 1.6rem;
      width: 60vw;
    }
    margin-top: -1.6rem;
  `}
`;

export const RightSide = styled(Side)`
  ${fieldsHeight}
  align-items: center;
  justify-content: flex-end;
  margin: auto;
`;

export const StaffingAgencyLink = styled(SmartLink)`
  margin-left: 0.3rem;
  color: ${Colors.white};
  text-decoration: underline;
`;

export const StaffingAgencyInfo = styled.span`
  color: ${Colors.white};
`;
