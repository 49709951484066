import styled, { css } from 'styled-components';

import { Colors } from 'components/utils/styles/ui';
import { Icon } from 'components/elements/icon';
import { Tag as TagBase } from 'components/visual/tags/styles';

export const InputContainer = styled.div`
  position: relative;
`;

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: solid 1px ${Colors.inputOutline};
  border-radius: 0.3rem;
  width: 100%;
  min-height: 4rem;
`;

export const Placeholder = styled.div`
  padding: 0.8rem 1.2rem;
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled(Icon).attrs(({ expanded }) => ({
  icon: expanded ? 'CaretUp' : 'CaretDown',
  size: 'large',
  color: 'textLighter',
}))`
  margin: 0 1.3rem;
`;

export const IconSpacer = styled.div`
  width: 0;
  height: calc(100% - 1rem);
  margin: 0.5rem 0;
  border-left: 1px solid ${Colors.inputOutline};
`;

export const ClearIcon = styled(Icon).attrs({
  icon: 'X',
  size: 'large',
  color: 'textLighter',
})`
  margin: 0 1.3rem;
  cursor: pointer;
`;

export const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled(TagBase)`
  margin: 0.5rem;
  background-color: ${Colors.tealLighter};
  border-color: ${Colors.outline};
`;

export const UnselectIcon = styled(Icon).attrs({
  color: 'tealDark',
  icon: 'X',
})`
  position: relative;
  padding-left: 0.3rem;
  cursor: pointer;
`;

const optionsMixin = css`
  top: ${({ optionsPosition, height }) =>
    optionsPosition === 'top'
      ? `calc(-${height}rem - 0.4rem)`
      : 'calc(100% + 0.4rem)'};
`;

export const OptionsWrapper = styled.ul`
  position: absolute;
  width: 100%;
  max-height: ${({ maxOptionsHeight }) => `${maxOptionsHeight}rem`};
  overflow: auto;
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  padding: 0;
  margin: 0;
  border: solid 1px ${Colors.inputOutline};
  border-radius: 0.3rem;
  background: ${Colors.white};
  z-index: 1010;

  ${optionsMixin};
`;

export const Option = styled.li`
  height: 4rem;
  line-height: 4rem;
  vertical-align: middle;
  padding: 0 2.4rem;
  cursor: ${({ selected, disabled }) =>
    selected || disabled ? 'default' : 'pointer'};
  color: ${({ selected, disabled }) =>
    selected || disabled ? Colors.textLighter : Colors.text};
`;
