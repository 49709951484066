import { defineMessages } from 'react-intl';

export default defineMessages({
  scopeEveryone: {
    id: 'app.ScopeFilter.scopeEveryone',
    defaultMessage: 'Everyone',
  },
  scopeSearchUser: {
    id: 'app.ScopeFilter.scopeSearchUser',
    defaultMessage: 'Search by user',
  },
  scopeSearchUserTeam: {
    id: 'app.ScopeFilter.scopeSearchUserTeam',
    defaultMessage: 'Search by user or team',
  },
});
