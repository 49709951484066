import React from 'react';
import PropTypes from 'prop-types';

import { IconBase } from './iconBase';

export const CV = (props) => {
  const { size, color, className } = props;
  return (
    <IconBase className={className} viewBox={24} size={size}>
      <path
        d="M18.75 21H5.24927C5.05036 21 4.85959 20.921 4.71894 20.7803C4.57828 20.6397 4.49927 20.4489 4.49927 20.25V3.75C4.49927 3.55109 4.57828 3.36032 4.71894 3.21967C4.85959 3.07902 5.05036 3 5.24927 3H14.25L19.5 8.25V20.25C19.5 20.3485 19.4806 20.446 19.4429 20.537C19.4052 20.628 19.35 20.7107 19.2803 20.7803C19.2107 20.85 19.128 20.9052 19.037 20.9429C18.946 20.9806 18.8485 21 18.75 21Z"
        stroke={color}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3V8.25H19.5008"
        stroke={color}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6344 15.5C12.8771 15.5 13.8844 14.4926 13.8844 13.25C13.8844 12.0074 12.8771 11 11.6344 11C10.3918 11 9.38441 12.0074 9.38441 13.25C9.38441 14.4926 10.3918 15.5 11.6344 15.5Z"
        stroke={color}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.72893 17.75C8.89539 17.106 9.27107 16.5355 9.79697 16.1283C10.3229 15.721 10.9692 15.5 11.6343 15.5C12.2994 15.5 12.9458 15.7209 13.4717 16.1282C13.9976 16.5354 14.3733 17.1058 14.5398 17.7498"
        stroke={color}
        fill="transparent"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
};

CV.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
