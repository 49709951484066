import styled, { css } from 'styled-components';

import { Colors } from '../../../utils/styles/ui';

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ isOn, disabled, color, colorWhenDisabled, theme }) => {
    if ((isOn && !disabled) || colorWhenDisabled) {
      return color ? Colors[color] : theme.colors.primary;
    }
    return Colors.greyDark;
  }};
  transition: 0.4s;
  border-radius: 3rem;
  ${({ disabled }) => (disabled ? `background-color: ${Colors.grey};` : ``)}

  &:before {
    position: absolute;
    content: '';
    background-color: ${Colors.white};
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const smallSizeMixin = css`
  width: 3.2rem;
  height: 1.8rem;

  ${Slider} {
    &:before {
      height: 1.4rem;
      width: 1.4rem;
      left: 0.2rem;
      bottom: 0.2rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(1.4rem)' : 'none')};
    }
  }
`;

const normalSizeMixin = css`
  width: 4rem;
  height: 2rem;

  ${Slider} {
    &:before {
      height: 1.6rem;
      width: 1.6rem;
      left: 0.2rem;
      bottom: 0.2rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(2rem)' : 'none')};
    }
  }
`;

const largeSizeMixin = css`
  width: 5.6rem;
  height: 2.6rem;

  ${Slider} {
    &:before {
      height: 2rem;
      width: 2rem;
      left: 0.3rem;
      bottom: 0.3rem;
      transform: ${({ isOn }) => (isOn ? 'translateX(3rem)' : 'none')};
    }
  }
`;

export const Toggle = styled.label`
  position: relative;
  display: inline-block;
  ${({ size }) => {
    switch (size) {
      case 'large':
        return largeSizeMixin;
      case 'small':
        return smallSizeMixin;
      default:
        return normalSizeMixin;
    }
  }}

  & > input {
    display: none;
  }
`;

const smallSizeLabelMixin = css`
  ${({ theme }) => theme.typography.text.normal}
  top: -0.5rem;
  padding-left: 1.2rem;
`;

const defaultLabelMixin = css`
  ${({ theme }) => theme.typography.text.medium}
  top: -0.7rem;
  padding-left: 2rem;
`;

export const Label = styled.span`
  display: inline-block;
  height: 2.6rem;
  line-height: 2.6rem;
  position: relative;

  ${({ size }) => (size === 'small' ? smallSizeLabelMixin : defaultLabelMixin)}
`;
