import { JOBS_COUNTERS_SUCCESS } from './constants';

const initialState = {
  counters: {
    assigned: 0,
    active: 0,
    closed: 0,
    imported: 0,
    archived: 0,
    candidates: 0,
  },
};

export function jobsReducer(state = initialState, action) {
  switch (action.type) {
    case JOBS_COUNTERS_SUCCESS:
      return {
        ...state,
        counters: action.payload.counters,
      };

    default:
      return state;
  }
}
