import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, intlShape } from 'react-intl';
import { isEmpty, get } from 'lodash';

import { unimpersonate } from 'containers/Admin/actions';

import { Nag } from 'components/feedback/Nag';
import { confirmAlert } from 'components/overlay/Confirm';

import {
  userSelector,
  companySelector,
  adminMembershipIdSelector,
} from 'containers/App/selectors';

import i18n from './utils/i18n';

class AdminHeaderComponent extends PureComponent {
  render() {
    const { adminMembershipId } = this.props;

    if (isEmpty(adminMembershipId)) {
      return null;
    }

    const { intl, user, company, handleUnimpersonate } = this.props;

    const firstName = get(user, 'first_name');
    const lastName = get(user, 'last_name');
    const companyName = get(company, 'name');

    return firstName && lastName && companyName ? (
      <Nag
        message={intl.formatMessage(i18n.title, {
          firstName,
          lastName,
          companyName,
        })}
        leftAction={{
          content: intl.formatMessage(i18n.buttonBackToAdmin),
          onClick: () =>
            confirmAlert({
              title: intl.formatMessage(i18n.confirmTitle),
              message: intl.formatMessage(i18n.confirmMessage),
              confirmLabel: intl.formatMessage(i18n.confirmButtonConfirm),
              cancelLabel: intl.formatMessage(i18n.confirmButtonCancel),
              children: <input type="password" autoFocus />,
              onConfirm: (textarea) =>
                handleUnimpersonate({ password: textarea.value }),
            }),
        }}
      />
    ) : null;
  }
}

AdminHeaderComponent.propTypes = {
  intl: intlShape,

  user: PropTypes.object,
  company: PropTypes.object,
  adminMembershipId: PropTypes.string,

  handleUnimpersonate: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  user: userSelector,
  company: companySelector,
  adminMembershipId: adminMembershipIdSelector,
});

export function mapDispatchToProps(dispatch) {
  return {
    handleUnimpersonate: (params) => dispatch(unimpersonate(params)),
  };
}

export const AdminHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdminHeaderComponent));
