import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import { formattedTimeDifference } from 'components/utils/date';
import {
  candidateStatusColors,
  statusColors as workStatusColors,
  workTypeColors,
  companyProfileTypeColors,
  reviewStatusColors,
  workOrderStatusColors,
  affiliationRequestStatusColors,
  premiumFeatureColors,
  featureVersionColors,
  Colors,
  hlCandidateStatusColors,
} from 'components/utils/styles/ui';

import * as styled from './styles/badge';
import i18n from './utils/i18n';

export const Badge = function Badge({ children, dataManual, ...props }) {
  return (
    <styled.Badge {...props} data-manual={dataManual}>
      {children}
    </styled.Badge>
  );
};

Badge.propTypes = {
  color: PropTypes.oneOf(
    Object.keys(styled.BADGE_COLORS).concat(Object.keys(Colors))
  ),
  dataManual: PropTypes.string,
  fontColor: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
  header: PropTypes.bool,
  upperCase: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  inline: PropTypes.bool,
};

Badge.defaultProps = {
  color: 'primary',
  fontColor: 'white',
  size: 'normal',
  header: false,
  upperCase: false,
  inline: false,
};

export const IconBadge = function IconBadge({
  icon,
  iconColor,
  size,
  ...props
}) {
  return (
    <styled.IconBadge size={size} {...props}>
      <styled.Icon color={iconColor} icon={icon} size={size} />
    </styled.IconBadge>
  );
};

IconBadge.propTypes = {
  color: PropTypes.oneOf(
    Object.keys(styled.BADGE_COLORS).concat(Object.keys(Colors))
  ),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  withPadding: PropTypes.bool,
};

IconBadge.defaultProps = {
  color: 'primary',
  iconColor: 'white',
  size: 'normal',
  icon: 'Check',
  withPadding: false,
};

export const WorkTypeBadge = ({ workType, displayedFor, ...props }) => {
  if (i18n.workTypeBadge[workType]) {
    const content =
      displayedFor === 'owner'
        ? i18n.workTypeBadgeTooltipOwner[workType]
        : i18n.workTypeBadgeTooltipSupplier[workType];
    return (
      <Tooltip fixed size="small" content={<FormattedMessage {...content} />}>
        <styled.WorkTypeBadge workType={workType} {...props}>
          <FormattedMessage {...i18n.workTypeBadge[workType]} />
        </styled.WorkTypeBadge>
      </Tooltip>
    );
  }
  return null;
};

WorkTypeBadge.propTypes = {
  workType: PropTypes.oneOf(Object.keys(workTypeColors)),
  displayedFor: PropTypes.oneOf(['owner', 'supplier']),
};

export const CandidateStatusBadge = ({ candidateStatus, ...props }) => {
  if (i18n.candidateStatusBadge[candidateStatus]) {
    return (
      <styled.CandidateStatusBadge candidateStatus={candidateStatus} {...props}>
        <FormattedMessage {...i18n.candidateStatusBadge[candidateStatus]} />
      </styled.CandidateStatusBadge>
    );
  }
  return null;
};

CandidateStatusBadge.propTypes = {
  candidateStatus: PropTypes.oneOf(Object.keys(candidateStatusColors)),
};

const GlobalWorkStatusBadgeClosed = ({ closedAt, closingReason }) => {
  const validClosedAt =
    closedAt && closedAt.isValid() ? closedAt : moment.utc();
  const formattedTimeDiff = formattedTimeDifference(moment(), validClosedAt);

  if (closingReason !== 'expiration') {
    return (
      <styled.WorkStatusBadge displayedStatus="closed">
        <FormattedMessage {...i18n.workStatusBadge.closed} />
      </styled.WorkStatusBadge>
    );
  }

  return (
    <styled.ExpirationBadge displayedStatus="closed">
      <div>
        <Icon icon="XCircle" color="white" />
        <FormattedMessage {...i18n.workStatusBadge.expired} />
      </div>
      <div>
        <Icon icon="Clock" />
        <styled.TimeSinceClosed>
          <FormattedMessage
            {...i18n.globalWorkStatusBadge.ago}
            values={{ time: formattedTimeDiff }}
          />
        </styled.TimeSinceClosed>
      </div>
    </styled.ExpirationBadge>
  );
};

GlobalWorkStatusBadgeClosed.propTypes = {
  closedAt: momentPropTypes.momentObj,
  closingReason: PropTypes.oneOf(['user_action', 'expiration']),
};

const GlobalWorkStatusBadgeActive = ({ expiresAt, displayedStatus }) => {
  const iconByStatus = {
    open: 'PlayCircle',
    paused: 'PauseCircle',
  };

  const days = Math.round(moment.utc(expiresAt).diff(moment(), 'd', true));
  const formattedTimeDiff = formattedTimeDifference(expiresAt, moment());
  const tooltipContent = (
    <FormattedMessage
      {...i18n.sharedToBadge.jobWillCloseIn}
      values={{ time: formattedTimeDiff }}
    />
  );

  return (
    <Tooltip fixed content={tooltipContent}>
      <styled.ExpirationBadge displayedStatus={displayedStatus}>
        <div>
          <Icon icon={iconByStatus[displayedStatus]} color="white" />
          <FormattedMessage {...i18n.workStatusBadge[displayedStatus]} />
        </div>
        <div>
          <Icon icon="Clock" />
          <FormattedMessage {...i18n.globalWorkStatusBadge.closingIn} />
          <styled.TimeToExpire days={days}>
            {formattedTimeDiff}
          </styled.TimeToExpire>
        </div>
      </styled.ExpirationBadge>
    </Tooltip>
  );
};

GlobalWorkStatusBadgeActive.propTypes = {
  expiresAt: momentPropTypes.momentObj,
  displayedStatus: PropTypes.oneOf(['open', 'pauses']),
};

export const ExpirationBadge = ({
  displayedStatus,
  closingReason,
  expiresAt,
  closedAt,
}) =>
  displayedStatus === 'closed' ? (
    <GlobalWorkStatusBadgeClosed
      closedAt={closedAt}
      closingReason={closingReason}
    />
  ) : (
    <GlobalWorkStatusBadgeActive
      expiresAt={expiresAt}
      displayedStatus={displayedStatus}
    />
  );

ExpirationBadge.propTypes = {
  displayedStatus: PropTypes.oneOf(Object.keys(workStatusColors)),
  closingReason: PropTypes.oneOf(['user_action', 'expiration']),
  expiresAt: momentPropTypes.momentObj,
  closedAt: momentPropTypes.momentObj,
};

export const WorkStatusBadge = ({
  workStatus,
  reviewStatus,
  global,
  displayedFor,
  ...props
}) => {
  const displayedStatus =
    global && workStatus === 'open' && reviewStatus === 'pending'
      ? 'inReview'
      : workStatus;

  if (
    global &&
    workStatus === 'open' &&
    reviewStatus === 'pending' &&
    displayedFor === 'owner'
  ) {
    return (
      <styled.WorkStatusBadge displayedStatus="inReview" {...props}>
        <Icon icon="Review" color="white" />
        <FormattedMessage {...i18n.workStatusBadge[displayedStatus]} />
        <Tooltip
          fixed
          size="small"
          content={
            <FormattedMessage {...i18n.workStatusBadge.inReviewTooltip} />
          }
        >
          <Icon icon="Info" color="white" />
        </Tooltip>
      </styled.WorkStatusBadge>
    );
  }

  if (
    global &&
    workStatus !== 'draft' &&
    displayedFor === 'owner' &&
    i18n.workStatusBadge[displayedStatus]
  ) {
    return <ExpirationBadge displayedStatus={displayedStatus} {...props} />;
  }
  if (i18n.workStatusBadge[displayedStatus]) {
    return (
      <styled.WorkStatusBadge displayedStatus={displayedStatus} {...props}>
        <FormattedMessage {...i18n.workStatusBadge[displayedStatus]} />
      </styled.WorkStatusBadge>
    );
  }

  return null;
};

WorkStatusBadge.propTypes = {
  workStatus: PropTypes.oneOf(Object.keys(workStatusColors)),
  reviewStatus: PropTypes.oneOf(['pending', 'accepted']),
  global: PropTypes.bool,
  expiresAt: momentPropTypes.momentObj,
  closedAt: momentPropTypes.momentObj,
  closingReason: PropTypes.oneOf(['user_action', 'expiration']),
  displayedFor: PropTypes.oneOf(['owner', 'supplier']),
};

export const CompanyProfileTypeBadge = ({ companyType, ...props }) => {
  if (i18n.companyProfileTypeBadge[companyType]) {
    return (
      <styled.CompanyProfileTypeBadge companyType={companyType} {...props}>
        <FormattedMessage {...i18n.companyProfileTypeBadge[companyType]} />
      </styled.CompanyProfileTypeBadge>
    );
  }
  return null;
};

CompanyProfileTypeBadge.propTypes = {
  companyType: PropTypes.oneOf(Object.keys(companyProfileTypeColors)),
};

export const ReviewStatusBadge = ({ reviewStatus, ...props }) => {
  if (i18n.reviewStatusBadge[reviewStatus]) {
    return (
      <styled.ReviewStatusBadge reviewStatus={reviewStatus} {...props}>
        <FormattedMessage {...i18n.reviewStatusBadge[reviewStatus]} />
      </styled.ReviewStatusBadge>
    );
  }
  return null;
};

ReviewStatusBadge.propTypes = {
  reviewStatus: PropTypes.oneOf(Object.keys(reviewStatusColors)),
};

export const WorkOrderStatusBadge = ({ workOrderStatus, ...props }) => {
  if (i18n.workOrderStatusBadge[workOrderStatus]) {
    return (
      <styled.WorkOrderStatusBadge workOrderStatus={workOrderStatus} {...props}>
        <FormattedMessage {...i18n.workOrderStatusBadge[workOrderStatus]} />
      </styled.WorkOrderStatusBadge>
    );
  }
  return null;
};

WorkOrderStatusBadge.propTypes = {
  workOrderStatus: PropTypes.oneOf(Object.keys(workOrderStatusColors)),
};

export const AffiliationRequestStatusBadge = ({ status, ...props }) => {
  if (i18n.affiliationRequestStatusBadge[status]) {
    return (
      <styled.AffiliationRequestStatusBadge status={status} {...props}>
        <FormattedMessage {...i18n.affiliationRequestStatusBadge[status]} />
      </styled.AffiliationRequestStatusBadge>
    );
  }
  return null;
};

AffiliationRequestStatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.keys(affiliationRequestStatusColors)),
};

export const CommunityBadge = (props) => (
  <styled.CommunityBadge {...props}></styled.CommunityBadge>
);

export const SharedToBadge = ({
  internal,
  global,
  status,
  reviewStatus,
  expiresAt,
  ...props
}) => {
  if (global && (reviewStatus !== 'accepted' || status !== 'open')) {
    return (
      <styled.SharedToBadge badgeType="global" {...props}>
        <FormattedMessage {...i18n.sharedToBadge.global} />
      </styled.SharedToBadge>
    );
  }

  if (global) {
    const days = Math.round(moment.utc(expiresAt).diff(moment(), 'd', true));
    const formattedTimeDiff = formattedTimeDifference(
      moment.utc(expiresAt),
      moment()
    );
    const tooltipContent = (
      <FormattedMessage
        {...i18n.sharedToBadge.jobWillCloseIn}
        values={{ time: formattedTimeDiff }}
      />
    );

    return (
      <Tooltip content={tooltipContent} fixed position="S">
        <styled.SharedToBadgeExpiration>
          <div>
            <FormattedMessage {...i18n.sharedToBadge.global} />
          </div>
          <div>
            <Icon icon="Clock" size="small" />
            <styled.TimeToExpire days={days}>
              {formattedTimeDiff}
            </styled.TimeToExpire>
          </div>
        </styled.SharedToBadgeExpiration>
      </Tooltip>
    );
  }

  const badgeType = internal ? 'internal' : 'vendors';

  return (
    <styled.SharedToBadge badgeType={badgeType} {...props}>
      <FormattedMessage {...i18n.sharedToBadge[badgeType]} />
    </styled.SharedToBadge>
  );
};

SharedToBadge.propTypes = {
  internal: PropTypes.bool,
  global: PropTypes.bool,
  status: PropTypes.string,
  reviewStatus: PropTypes.string,
  expiresAt: PropTypes.string,
};

export const PremiumFeatureBadge = ({ type, ...props }) => {
  if (i18n.premiumFeatureBadge[type]) {
    const icon = type === 'addon' ? 'PuzzlePiece' : 'ShieldFill';
    const weight = type === 'addon' ? 'fill' : 'regular';

    return (
      <styled.PremiumFeatureBadge type={type} {...props}>
        <styled.Icon color="white" size="small" icon={icon} weight={weight} />
        <FormattedMessage {...i18n.premiumFeatureBadge[type]} />
      </styled.PremiumFeatureBadge>
    );
  }
  return null;
};

PremiumFeatureBadge.propTypes = {
  type: PropTypes.oneOf(Object.keys(premiumFeatureColors)),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
};

PremiumFeatureBadge.defaultProps = {
  size: 'normal',
  type: 'feature',
};

export const FeatureVersionBadge = ({ version, ...props }) => {
  if (i18n.featureVersionBadge[version]) {
    return (
      <styled.FeatureVersionBadge version={version} {...props}>
        <FormattedMessage {...i18n.featureVersionBadge[version]} />
      </styled.FeatureVersionBadge>
    );
  }
  return null;
};

FeatureVersionBadge.propTypes = {
  version: PropTypes.oneOf(Object.keys(featureVersionColors)),
  type: PropTypes.oneOf(Object.keys(featureVersionColors)),
  size: PropTypes.oneOf(Object.keys(styled.BADGE_SIZES)),
};

FeatureVersionBadge.defaultProps = {
  size: 'normal',
  type: 'beta',
};

export function InfoBadge({
  className,
  iconPreName,
  iconAffName,
  iconColor,
  text,
  fontColor,
  color,
}) {
  return (
    <styled.InfoBadge className={className} fontColor={fontColor} color={color}>
      {iconPreName && (
        <styled.InfoBadgeIcon icon={iconPreName} color={iconColor} />
      )}
      <FormattedMessage {...text} />
      {iconAffName && (
        <styled.InfoBadgeIcon icon={iconAffName} color={iconColor} />
      )}
    </styled.InfoBadge>
  );
}

InfoBadge.propTypes = {
  fontColor: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  iconPreName: PropTypes.string,
  iconAffName: PropTypes.string,
  text: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const cvIcons = {
  created: 'User',
  requested: 'FileText',
  contacted: 'Envelope',
  revealed: 'FileText',
  declined: 'XCircle',
  interview: 'ChatsCircle',
};

export const CvStatusBadge = (props) => {
  const {
    withIcon,
    className,
    status,
    size,
    colors,
    filled,
    rightIcon,
    withPlaceholder,
  } = props;
  const noStatus = isEmpty(status) || status === 'no_status';
  if (noStatus && !withPlaceholder) {
    return null;
  }

  const mainColor = (() => {
    if (filled) return Colors.white;
    return colors?.[status]?.main || colors[status];
  })();
  const bgColor = (() => {
    if (filled) return colors[status];
    return colors?.[status]?.bg || Colors.white;
  })();
  const borderColor = (() =>
    colors?.[status]?.border || colors?.[status]?.main || colors[status])();

  return (
    <styled.CvStatusBadge
      className={className}
      mainColor={mainColor}
      bgColor={bgColor}
      borderColor={borderColor}
      size={size}
      inline
    >
      {withIcon && !noStatus && (
        <styled.InfoBadgeIcon icon={cvIcons[status]} color={mainColor} />
      )}
      <FormattedMessage {...i18n.cvStatus[status]} />
      {rightIcon && (
        <styled.RightBadgeIcon icon={rightIcon} color={mainColor} />
      )}
    </styled.CvStatusBadge>
  );
};

CvStatusBadge.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
  colors: PropTypes.object,
  filled: PropTypes.bool,
  withIcon: PropTypes.bool,
  rightIcon: PropTypes.string,
  withPlaceholder: PropTypes.bool,
};

CvStatusBadge.defaultProps = {
  colors: hlCandidateStatusColors,
  filled: false,
  withPlaceholder: false,
  status: 'no_Status',
};
