import { defineMessages } from 'react-intl';

export default defineMessages({
  flashAvatarSaved: {
    id: 'app.components.YourAccount.Account.flashAvatarSaved',
    defaultMessage: 'Avatar saved successfully!',
  },
  flashAvatarError: {
    id: 'app.components.YourAccount.Account.flashAvatarError',
    defaultMessage: 'Avatar upload error!',
  },
  flashSavedSuccess: {
    id: 'app.components.YourAccount.Account.flashSavedSuccess',
    defaultMessage: 'Saved successfully!',
  },
  flashUpdatePasswordSuccess: {
    id: 'app.components.YourAccount.Account.flashSavedSuccess',
    defaultMessage: 'Password successfully updated',
  },
  flashOldPasswordWrong: {
    id: 'app.components.YourAccount.Account.flashOldPasswordWrong',
    defaultMessage: 'Old password is wrong!',
  },
  flashPasswordNotSame: {
    id: 'app.components.YourAccount.Account.flashPasswordNotSame',
    defaultMessage: 'Passwords cannot be identical!',
  },
  flashPasswordBlocked: {
    id: 'app.components.YourAccount.Account.flashPasswordBlocked',
    defaultMessage: 'Too many failed attempts. Please try again later.',
  },
  flashPasswordError: {
    id: 'app.components.YourAccount.Account.flashPasswordError',
    defaultMessage: 'Passwords update error',
  },
  flashEmailTaken: {
    id: 'app.components.YourAccount.Account.flashEmailTaken',
    defaultMessage: 'Email is already taken!',
  },

  flashNotificationsSettingsUpdated: {
    id:
      'app.components.YourAccount.Notifications.flashNotificationsSettingsUpdated',
    defaultMessage: 'Notification settings updated',
  },

  flashResendVerificationEmail: {
    id: 'app.components.YourAccount.Account.flashResendVerificationEmail',
    defaultMessage: 'Verification email was resend',
  },
  flashCancelChangeEmail: {
    id: 'app.components.YourAccount.Account.flashCancelChangeEmail',
    defaultMessage: 'Email change was canceled',
  },
  yourAccount: {
    id: 'app.components.YourAccount.Menu.yourAccount',
    defaultMessage: 'Your account',
  },
  yourDetails: {
    id: 'app.components.YourAccount.Menu.yourDetails',
    defaultMessage: 'Your details',
  },
  yourProfile: {
    id: 'app.components.YourAccount.Menu.yourProfile',
    defaultMessage: 'Your profile',
  },
  notifications: {
    id: 'app.components.YourAccount.Menu.notifications',
    defaultMessage: 'Notifications',
  },
});
