import React from 'react';
import { FormattedMessage } from 'react-intl';
import { compact, startsWith, get, includes, isEmpty, some } from 'lodash';

import { IntercomAPI } from 'components/scripts/intercom';
import { FeatureVersionBadge } from 'components/misc/Badge';
import { Config as AppConfig } from 'containers/App/config';
import i18n from 'scenes/utils/i18n';
import { userRole } from 'utils/authorization/utils/auth';

export const sidebarMenuAdmin = [
  {
    label: <FormattedMessage {...i18n.adminJobs} />,
    path: '/admin/jobs',
    slug: 'adminJobs',
    icon: 'Briefcase',
  },
  {
    label: <FormattedMessage {...i18n.adminCompanies} />,
    path: '/admin/companies',
    slug: 'adminCompanies',
    icon: 'Users',
  },
  {
    label: <FormattedMessage {...i18n.adminReview} />,
    path: '/admin/review',
    slug: 'adminReview',
    icon: 'Eye',
  },
  {
    label: <FormattedMessage {...i18n.adminIntegrations} />,
    path: '/admin/integrations',
    slug: 'adminIntegrations',
    icon: 'Gear',
  },
  {
    label: <FormattedMessage {...i18n.adminMessages} />,
    path: '/admin/messages',
    slug: 'adminMessages',
    icon: 'Chat',
  },
  {
    label: <FormattedMessage {...i18n.adminCommunities} />,
    path: '/admin/communities',
    slug: 'adminCommunities',
    icon: 'Users',
  },
  {
    label: <FormattedMessage {...i18n.adminSubscriptionPlans} />,
    path: '/admin/subscription-plans',
    slug: 'adminSubscriptionPlans',
    icon: 'Shield',
  },
  {
    label: <FormattedMessage {...i18n.adminHotlist} />,
    path: '/admin/hotlist',
    slug: 'adminHotlist',
    icon: 'Fire',
  },
  {
    label: <FormattedMessage {...i18n.adminDataAnaylsis} />,
    path: '/admin/data-analysis',
    slug: 'adminDataAnalysis',
    icon: 'Stack',
  },
];

const sidebarMenu = (company) => {
  const {
    company_type: companyType,
    features: companyFeatures,
    first_jobs_menu_item: firstJobsMenuItem,
    subscription_plan: plan,
    communities,
  } = company;
  const isEmployer =
    companyType === 'employer' || companyFeatures.employer === true;
  const isSupplier =
    companyType === 'supplier' || companyFeatures.supplier === true;
  const isCommunityMember = !isEmpty(communities);

  const marketplaceItem = isSupplier && {
    label: i18n.marketplaceJobs,
    path: '/marketplace',
    slug: 'marketplace',
    items: [
      {
        label: i18n.marketplacePartnerJobs,
        slug: 'marketplace-all-jobs',
        path: '/marketplace/discover',
        icon: 'Storefront',
        counter: {
          key: 'marketplace.partner_jobs',
          fallback: 'marketplace.global_jobs',
        },
      },
      {
        label: i18n.marketplaceSavedJobs,
        slug: 'marketplace-saved-jobs',
        path: '/marketplace/saved',
        icon: 'Heart',
        counter: { key: 'marketplace.saved_jobs' },
      },
      {
        label: i18n.marketplaceAssignedJobs,
        slug: 'marketplace-assigned-jobs',
        path: '/marketplace/assigned',
        icon: 'UserPlus',
        counter: { key: 'marketplace.assigned_jobs' },
        mappedQueryParameters: { supplier: 'assignee' },
      },
      {
        label: i18n.marketplaceCandidates,
        slug: 'marketplace-supplied-candidates',
        path: '/marketplace/supplied-candidates',
        icon: 'Users',
        counter: { key: 'marketplace.supplied_candidates' },
        mappedQueryParameters: { assignee: 'supplier' },
      },
    ],
  };

  const companyJobsItem = isEmployer && {
    label: i18n.yourCompanysJobs,
    path: '/jobs',
    isActive: (match, { pathname }) =>
      match ||
      startsWith(pathname, '/job') ||
      startsWith(pathname, '/jobs') ||
      startsWith(pathname, '/ats'),
    slug: 'jobs',
    items: compact([
      {
        label: i18n.yourCompanysJobsAll,
        slug: 'jobs-all',
        path: '/jobs/active',
        isActive: (match, { pathname }) =>
          match || includes(['/jobs/active'], pathname),
        icon: 'PlayCircle',
        counter: { key: 'jobs.active_jobs' },
        preservedQueryParameters: ['ats_member_id'],
      },
      {
        label: i18n.yourCompanysJobsDraft,
        slug: 'jobs-draft',
        path: '/jobs/draft',
        icon: 'PencilLine',
        counter: { key: 'jobs.draft_jobs' },
        preservedQueryParameters: ['ats_member_id'],
      },
      {
        label: i18n.yourCompanysJobsClosed,
        slug: 'jobs-closed',
        path: '/jobs/closed',
        icon: 'XCircle',
        counter: { key: 'jobs.closed_jobs' },
        preservedQueryParameters: ['ats_member_id'],
      },
      {
        label: i18n.yourCompanysJobsImported,
        slug: 'jobs-imported',
        path: '/jobs/imported',
        isActive: (match, { pathname }) =>
          match ||
          includes(['/jobs/imported', '/jobs/archived'], pathname) ||
          startsWith(pathname, '/job/imported'),
        icon: 'CloudArrowDown',
        counter: {
          key: 'jobs.imported_jobs',
          icon: !plan && { color: 'yellow', icon: 'ShieldFill' },
        },
        preservedQueryParameters: ['ats_member_id'],
      },
      {
        label: i18n.yourCompanysJobsCandidates,
        slug: 'jobs-received-candidates',
        path: '/jobs/candidates',
        icon: 'Users',
        counter: { key: 'jobs.received_candidates' },
        preservedQueryParameters: ['ats_member_id'],
      },
    ]),
  };

  const jobsItems =
    firstJobsMenuItem === 'marketplace'
      ? [marketplaceItem, companyJobsItem]
      : [companyJobsItem, marketplaceItem];

  const isActiveFn = (paths, excluded) => (match, { pathname }) => {
    const somePathMatches = some(paths, (path) => startsWith(pathname, path));
    const noneExcludedPathsMatch =
      isEmpty(excluded) || !some(excluded, (path) => pathname === path);

    return somePathMatches && noneExcludedPathsMatch;
  };

  return compact([
    {
      label: <FormattedMessage {...i18n.jobs} />,
      path:
        isSupplier && (firstJobsMenuItem === 'marketplace' || !isEmployer)
          ? '/marketplace'
          : '/jobs',
      icon: 'FileText',
      slug: 'jobs',
      isActive: (match, { pathname }) =>
        match ||
        startsWith(pathname, '/jobs') ||
        startsWith(pathname, '/ats') ||
        startsWith(pathname, '/marketplace'),
      subMenu: {
        title: <FormattedMessage {...i18n.jobs} />,
        slug: 'jobs',
        items: compact(jobsItems),
        bottomSection: 'jobsLimit',
      },
    },
    {
      label: <FormattedMessage {...i18n.partners} />,
      path: '/partners',
      icon: 'Buildings',
      slug: 'partners',
      counter: { key: 'partners.received_request' },
      isActive: isActiveFn(
        ['/company/profile/', '/partners/'],
        ['/company/profile', '/company/profile/about', '/company/profile/users']
      ),
      subMenu: {
        title: <FormattedMessage {...i18n.partners} />,
        slug: 'partners',
        items: compact([
          {
            label: i18n.partnersManage,
            path: '/partners',
            slug: 'partners',
            isActive: isActiveFn([
              '/partners/followers',
              '/partners/following',
              '/partners/circles',
              '/partners/profile',
            ]),
            items: compact([
              isEmployer && {
                label: i18n.partnersFollowers,
                slug: 'followers',
                path: '/partners/followers',
                icon: 'UserArrowLeft',
                counter: { key: 'partners.affiliated_suppliers' },
              },
              isEmployer && {
                label: i18n.partnersCircles,
                slug: 'circles',
                path: '/partners/circles',
                icon: 'Record',
                counter: {
                  key: 'partners.affiliation_circles',
                  icon: !plan && { color: 'yellow', icon: 'ShieldFill' },
                },
              },
              isSupplier && {
                label: i18n.partnersFollowing,
                slug: 'following',
                path: '/partners/following',
                icon: 'UserArrowRight',
                counter: { key: 'partners.affiliated_employers' },
              },
            ]),
          },
          {
            label: i18n.partnerRequests,
            path: '/partners/requests',
            slug: 'requests',
            counter: { key: 'partners.received_request', highlight: true },
            items: [
              {
                label: i18n.partnerRequestsReceived,
                slug: 'received',
                path: '/partners/requests/received',
                icon: 'Received',
                counter: { key: 'partners.received_request', highlight: true },
              },
              {
                label: i18n.partnerRequestsSent,
                slug: 'sent',
                path: '/partners/requests/sent',
                icon: 'SignOut',
                counter: { key: 'partners.sent_request' },
              },
            ],
          },
          isCommunityMember && {
            label: i18n.partnersCommunities,
            path: '/partners/communities',
            slug: 'communities',
          },
          {
            label: i18n.partnersHub,
            path: '/partners/hub',
            slug: 'hub',
          },
          {
            label: i18n.partnersBlocked,
            path: '/partners/blocked',
            slug: 'blocked',
          },
        ]),
      },
    },
    AppConfig.CandidatesEnabled && {
      label: <FormattedMessage {...i18n.candidates} />,
      path: '/candidates',
      icon: 'Users',
      slug: 'candidates',
    },
    AppConfig.WorkOrdersEnabled && {
      label: <FormattedMessage {...i18n.workOrders} />,
      path: '/work-orders',
      icon: 'Clipboard',
      slug: 'work_orders',
    },
    {
      label: <FormattedMessage {...i18n.hotlist} />,
      path: '/hotlist',
      icon: 'Fire',
      slug: 'hotlist',
      counter: { key: 'hotlist.total_unread' },
      subMenu: {
        title: <FormattedMessage {...i18n.hotlist} />,
        badge: <FeatureVersionBadge version="beta" />,
        slug: 'hotlist',
        bottomSection: 'hotlistLimit',
        items: [
          {
            label: i18n.hotlistFind,
            path: '/hotlist/find',
            slug: 'find',
            isActive: isActiveFn([
              '/hotlist/find',
              '/hotlist/saved',
              '/hotlist/requested',
            ]),
            isOpen: true,
            items: [
              {
                label: i18n.hotlistDiscover,
                slug: 'find',
                path: '/hotlist/find',
                icon: 'Fire',
              },
              {
                label: i18n.hotlistRequested,
                slug: 'requested',
                path: '/hotlist/requested',
                icon: 'EnvelopeSimple',
                counter: {
                  key: 'hotlist.requested',
                  highlight: 'hotlist.requested_unread',
                },
              },
              {
                label: i18n.hotlistSaved,
                slug: 'saved',
                path: '/hotlist/saved',
                icon: 'Heart',
                counter: { key: 'hotlist.saved' },
              },
              {
                label: i18n.hotlistArchived,
                slug: 'archived',
                path: '/hotlist/archived',
                icon: 'Book',
                counter: { key: 'hotlist.archived' },
              },
            ],
          },
          {
            label: i18n.hotlistCandidates,
            path: '/hotlist/active',
            slug: 'candidates',
            isActive: isActiveFn([
              '/hotlist/active',
              '/hotlist/draft',
              '/hotlist/ended',
            ]),
            isOpen: true,
            items: [
              {
                label: i18n.hotlistActive,
                slug: 'active',
                path: '/hotlist/active',
                icon: 'PlayCircle',
                counter: {
                  key: 'hotlist.active',
                  highlight: 'hotlist.active_unread',
                },
                preservedQueryParameters: ['filters.scope'],
              },
              {
                label: i18n.hotlistDraft,
                slug: 'draft',
                path: '/hotlist/draft',
                icon: 'PencilLine',
                counter: { key: 'hotlist.draft' },
                preservedQueryParameters: ['filters.scope'],
              },
              {
                label: i18n.hotlistEnded,
                slug: 'ended',
                path: '/hotlist/ended',
                icon: 'XCircle',
                counter: { key: 'hotlist.ended' },
                preservedQueryParameters: ['filters.scope'],
              },
            ],
          },
        ],
      },
    },
    {
      label: <FormattedMessage {...i18n.clients} />,
      path: '/clients',
      icon: 'Suitcase',
      slug: 'clients',
    },
    isEmployer && {
      label: <FormattedMessage {...i18n.reporting} />,
      path: '/reporting',
      icon: 'TrendUp',
      slug: 'reporting',
    },
  ]);
};

export const buildSidebarMenu = (company) => {
  if (company.company_type === 'administrator') {
    return sidebarMenuAdmin;
  }

  return sidebarMenu(company);
};

export const sidebarMenuProfile = (company) => {
  const companyType = company.company_type;
  const showCompanyProfile = companyType !== 'administrator';

  return compact([
    showCompanyProfile && {
      label: <FormattedMessage {...i18n.companyProfile} />,
      path: '/company/profile',
      icon: 'Briefcase',
    },
    companyType !== 'administrator' &&
      userRole() !== 'member' && {
        label: <FormattedMessage {...i18n.settings} />,
        path: '/settings/company',
        icon: 'Gear',
      },
    companyType !== 'administrator' && {
      label: <FormattedMessage {...i18n.yourAccount} />,
      path: '/account',
      icon: 'User',
    },
    companyType !== 'administrator' && {
      label: <FormattedMessage {...i18n.subscription} />,
      path: '/subscription-plan',
      icon: 'Shield',
      border: 'bottom',
    },
    {
      label: <FormattedMessage {...i18n.support} />,
      onAction: () => IntercomAPI('show'),
    },
    {
      label: <FormattedMessage {...i18n.helpCenter} />,
      onAction: () => {
        window.open('//help.hellogustav.com/en/', '_blank');
      },
      border: 'bottom',
    },
    {
      label: <FormattedMessage {...i18n.logout} />,
      path: '/logout',
    },
  ]);
};

export const ContextMenuCandidates = {
  parent: 'candidates',
  type: 'context',
  menuFor: 'candidates',
  links: [
    {
      linkName: i18n.allCandidates,
      linkUrl: '/candidates',
      icon: 'Users',
      slug: 'all',
    },
    {
      linkName: i18n.circles,
      linkUrl: '/candidates/circles',
      icon: 'Record',
      border: 'left',
      slug: 'circles',
    },
  ],
  active: 'all',
};

export const ContextMenuWorkOrders = (counters, intl) => ({
  parent: 'work-orders',
  type: 'context',
  menuFor: 'work-orders',
  links: [
    {
      linkName: intl.formatMessage(i18n.workOrdersPending, {
        count: get(counters, 'pending', 0),
      }),
      icon: 'Clock',
      slug: 'pending',
      linkUrl: '/work-orders/pending',
    },
    {
      linkName: intl.formatMessage(i18n.workOrdersUpcoming, {
        count: get(counters, 'upcoming', 0),
      }),
      icon: 'CalendarBlank',
      slug: 'upcoming',
      linkUrl: '/work-orders/upcoming',
    },
    {
      linkName: intl.formatMessage(i18n.workOrdersActive, {
        count: get(counters, 'active', 0),
      }),
      icon: 'PlayCircle',
      slug: 'active',
      linkUrl: '/work-orders/active',
    },
    {
      linkName: intl.formatMessage(i18n.workOrdersDeclined, {
        count: get(counters, 'declined', 0),
      }),
      icon: 'XCircle',
      slug: 'declined',
      linkUrl: '/work-orders/declined',
    },
    {
      linkName: intl.formatMessage(i18n.workOrdersArchived, {
        count: get(counters, 'archived', 0),
      }),
      icon: 'Stack',
      slug: 'archived',
      linkUrl: '/work-orders/archived',
    },
  ],
});
