/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import { createStructuredSelector } from 'reselect';

import {
  DEFAULT_DATE_FORMAT,
  updateMomentLongDateFormat,
} from 'components/utils/date';
import {
  isLoginOrSignupPath,
  isOnboarding,
  isWithSidebarPath,
} from 'containers/App/utils/helpers';
import {
  companySelector,
  isAuthenticatedSelector,
} from 'containers/App/selectors';
import { deleteLogo, uploadLogo } from 'containers/CompanyProfile/actions';

import { isAdministrator } from 'utils/authorization/utils/auth';

import { fetchCompanySettings, updateCompanySettings } from './actions';

const SettingsContext = React.createContext({});

const Props = {
  children: PropTypes.node.isRequired,
  handleFetchSettings: PropTypes.func,
  handleSaveSettings: PropTypes.func,
  handleUploadLogo: PropTypes.func,
  handleDeleteLogo: PropTypes.func,
  company: PropTypes.object,
  isAuthenticated: PropTypes.bool,
};

const DefaultState = {
  isFetching: false,
  companySettings: {},
};

class SettingsProviderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = DefaultState;
  }

  componentDidUpdate(prevProps, prevState) {
    const { company, isAuthenticated } = this.props;
    const { companySettings } = this.state;

    if ((!isAuthenticated || isEmpty(company)) && !isEmpty(companySettings)) {
      this.setState(DefaultState);
      updateMomentLongDateFormat({ format: DEFAULT_DATE_FORMAT });
      return;
    }

    if (
      !isAuthenticated ||
      isEqual(prevProps.company, company) ||
      !isEmpty(prevState.companySettings) ||
      !isWithSidebarPath(window.location) ||
      isOnboarding(window.location) ||
      isLoginOrSignupPath(window.location)
    ) {
      return;
    }

    this.fetchSettings();
  }

  fetchSettings = () => {
    const { handleFetchSettings } = this.props;

    this.setState({ isFetching: true }, () => {
      handleFetchSettings().then(this.afterSettingsChange);
    });
  };

  updateCurrentLocaleWithLongDateFormat = () => {
    if (isAdministrator()) return;

    const { companySettings } = this.state;
    updateMomentLongDateFormat({ format: companySettings?.date_format });
  };

  updateSettings = (settings) => {
    const { handleSaveSettings } = this.props;

    return handleSaveSettings(settings).then(this.afterSettingsChange);
  };

  afterSettingsChange = (settingsChanges) => {
    const { companySettings } = this.state;
    this.setState(
      {
        isFetching: false,
        companySettings: { ...companySettings, ...settingsChanges },
      },
      this.updateCurrentLocaleWithLongDateFormat
    );
    return settingsChanges;
  };

  onLogoUpload = (image, source) => {
    const { handleUploadLogo } = this.props;

    return handleUploadLogo(image, source).then((response) => {
      const { companySettings } = this.state;

      this.setState({
        companySettings: {
          ...companySettings,
          company_logo: response,
        },
      });

      return response;
    });
  };

  render() {
    const { children, handleDeleteLogo } = this.props;
    const { isFetching, companySettings } = this.state;

    return (
      <SettingsContext.Provider
        value={{
          isFetching,
          companySettings,
          handleSaveSettings: this.updateSettings,
          handleLogoUpload: this.onLogoUpload,
          handleLogoDelete: handleDeleteLogo,
          // TODO: temporary until remove completely the Settings/Company/Provider
          updateSettingsContext: this.afterSettingsChange,
        }}
      >
        {children}
      </SettingsContext.Provider>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  company: companySelector,
  isAuthenticated: isAuthenticatedSelector,
});

function mapDispatchToProps(dispatch) {
  return {
    handleFetchSettings: () => dispatch(fetchCompanySettings()),
    handleUploadLogo: (logo, source) => dispatch(uploadLogo(logo, source)),
    handleDeleteLogo: () => dispatch(deleteLogo()),
    handleSaveSettings: (settings) => dispatch(updateCompanySettings(settings)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

SettingsProviderComponent.propTypes = Props;

export const SettingsProvider = compose(withConnect)(SettingsProviderComponent);

export const SettingsConsumer = SettingsContext.Consumer;

export { DefaultState, Props };

export default SettingsContext;
