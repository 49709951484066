import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ImageCrop } from 'components/visual/imageCrop';
import { Modal } from 'components/overlay/Modal';
import * as styled from 'components/structure/form/styles/form';
import { returnOrNotifyUploadedFile } from 'components/utils/form-dropzone';

import i18n from './utils/i18n';

/* eslint react/no-did-update-set-state: 0 */
export class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cropImage: false,
      imageToCrop: null,
      isImageLoading: props.isImageLoading,
    };
  }

  componentDidUpdate(prevProps) {
    const { isImageLoading: stateIsImageLoading } = this.state;
    const { isImageLoading: propIsImageLoading, image } = this.props;
    if (
      stateIsImageLoading &&
      ((prevProps.isImageLoading && !propIsImageLoading) ||
        image !== prevProps.image)
    ) {
      this.setState({ isImageLoading: false });
    }
  }

  cancelCrop = () => {
    this.setState({
      cropImage: false,
      imageToCrop: null,
      isImageLoading: false,
    });
  };

  croppedImage = (image) => {
    const { onUpload } = this.props;

    this.setState({
      cropImage: false,
      imageToCrop: null,
      isImageLoading: true,
    });

    onUpload(image);
  };

  shouldCrop = (image) => {
    if (!image) {
      return;
    }

    this.setState({
      cropImage: true,
      imageToCrop: image,
    });
  };

  updateImage = (images) => {
    const accepted = returnOrNotifyUploadedFile(images);

    if (accepted) {
      this.shouldCrop(accepted);
    }
  };

  renderCropper() {
    const { cropImage, imageToCrop } = this.state;

    if (!cropImage) {
      return null;
    }

    const ImgCropper = (
      <ImageCrop
        image={imageToCrop}
        onCrop={this.croppedImage}
        onCancel={this.cancelCrop}
      />
    );

    return (
      <Modal
        item={{
          type: 'custom',
          content: ImgCropper,
        }}
        onClose={this.cancelCrop}
        noCloseOnBackgroundClick
      />
    );
  }

  render() {
    const {
      className,
      shouldValidate,
      formDefinition,
      image,
      initials,
    } = this.props;
    const { isImageLoading } = this.state;
    const subLabelEl = formDefinition.label ? (
      <styled.SubLabel>
        <FormattedMessage {...i18n.imageSizeLimit} />
      </styled.SubLabel>
    ) : null;

    return (
      <div className={className}>
        <styled.ImageFieldset
          context={formDefinition}
          picture={image}
          initials={initials}
          shouldValidate={shouldValidate}
          includeIndicator={false}
          isImageLoading={isImageLoading}
          fn={this.updateImage}
        >
          {subLabelEl}
        </styled.ImageFieldset>

        {this.renderCropper()}
      </div>
    );
  }
}

ImageUpload.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  initials: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isImageLoading: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  formDefinition: PropTypes.object,
  onUpload: PropTypes.func,
};

ImageUpload.defaultProps = {
  initials: false,
};
