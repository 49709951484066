import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { get, find, isEmpty, isObject, noop } from 'lodash';

import { Icon } from 'components/elements/icon';

import * as styled from './styles';

const Props = {
  intl: intlShape,
  filter: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      label: PropTypes.node,
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  defaultFiltered: PropTypes.array,
  disableWhenDefault: PropTypes.bool,
};

const DefaultProps = {
  filter: {},
  disabled: false,
  disableWhenDefault: true,
  onChange: noop,
};

const SelectFilterComponent = (props) => {
  const {
    intl,
    filter,
    options,
    disabled,
    onChange,
    defaultFiltered,
    disableWhenDefault,
  } = props;

  const defaultOption = find(defaultFiltered, { id: filter.id });
  const isDefaultFilter =
    filter.value && get(defaultOption, 'value') === filter.value;

  const handleResetFilter = () => onChange(get(defaultOption, 'value', ''));

  const renderOptionLabel = (label) => {
    if (isObject(label) && label.defaultMessage) {
      return intl.formatMessage(label);
    }
    if (React.isValidElement(label)) {
      return intl.formatMessage(label.props);
    }
    return label;
  };

  return (
    <styled.Filter>
      <styled.SelectInput
        value={get(filter, 'value', '')}
        disabled={disabled || (disableWhenDefault && isDefaultFilter)}
        onChange={(event) => onChange(event.target.value)}
        noAppearance
      >
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {renderOptionLabel(label)}
          </option>
        ))}
      </styled.SelectInput>
      {!(disableWhenDefault && isDefaultFilter) && (
        <styled.Action clickThrough={isEmpty(filter.value)}>
          {isEmpty(filter.value) || isDefaultFilter ? (
            <Icon size={1.15} icon="ChevronUpDown" />
          ) : (
            <Icon size={1.15} icon="X" onClick={handleResetFilter} />
          )}
        </styled.Action>
      )}
    </styled.Filter>
  );
};

SelectFilterComponent.propTypes = Props;
SelectFilterComponent.defaultProps = DefaultProps;

export const SelectFilter = injectIntl(SelectFilterComponent);
