import { CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS } from 'containers/ConfirmEmail/constants';
import { ONBOARDING_SUCCESS } from 'containers/Onboarding/constants';
import {
  GET_SESSION_SUCCESS,
  GET_SESSION_VIA_TOKEN_SUCCESS,
} from 'containers/Login/constants';

const initialState = {
  model: null,
  channel: null,
  error: null,
};
export function userReducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'USER_CHANNEL_JOINED':
      return {
        ...state,
        model: action.model,
        channel: action.channel,
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        model: { ...state.model, ...action.payload.user },
      };

    case 'UPDATE_USER':
      return {
        ...state,
        model: { ...state.model, ...action.data },
      };

    case CONFIRM_EMAIL_EMAIL_CONFIRMED_SUCCESS:
      return {
        ...state,
        model: { ...state.model, needs_confirm_email: false },
      };

    case 'USER_AVATAR_UPLOAD':
      return {
        ...state,
        model: { ...state.model, avatar: action.payload },
      };

    case GET_SESSION_SUCCESS:
    case GET_SESSION_VIA_TOKEN_SUCCESS:
      return {
        ...state,
        model: action.user,
      };

    case 'USER_CHANNEL_LEFT':
      return initialState;

    default:
      return state;
  }
}
