import { get } from 'lodash';

import { valueForProperty } from 'scenes/utils/form/parsing-json';

export const companyObject = function companyObject(company = {}) {
  const labels = [
    'diversity_supplier',
    'goverment_contracts',
    'small_business_certified',
    'security_clearance',
  ]
    .map((label) => ({
      id: `label-${label}`,
      value: label,
      checked: company.labels.includes(label),
    }))
    .filter((label) => label.checked);

  const { industries = [], departments = [], specializations = [] } = company;
  const { id: industry = 'none' } = industries[0] || {};

  return {
    companySize: valueForProperty('company_size', 'none', company),
    revenueSize: valueForProperty('revenue_size', 'none', company),
    about: valueForProperty('about', null, company),
    labels,
    website: valueForProperty('website', null, company),
    contactPerson: get(company, 'contact_person.id', 'none'),
    billingAddress: valueForProperty('billing_address', null, company),
    industry,
    industries,
    departments,
    specializations,
  };
};
