import { get, pick } from 'lodash';
import { css } from 'styled-components';

export const contactInitials = (contact, fallbackEmail = false) => {
  const first = get(contact, 'first_name');
  const last = get(contact, 'last_name');
  const email = get(contact, 'email');
  const fallback = fallbackEmail && email ? get(email, '0', '') : 'na';

  return first || last
    ? `${get(first, '0', '')}${get(last, '0', '')}`
    : fallback;
};

export const contactName = (contact, fallbackEmail = false) => {
  const first = get(contact, 'first_name');
  const last = get(contact, 'last_name');
  const email = get(contact, 'email');
  const fallback = fallbackEmail ? email : null;

  return first || last ? `${first || ''} ${last || ''}` : fallback;
};

export const userContact = (user) => {
  if (user.client_contact) {
    return {
      ...user,
      ...pick(user.client_contact, ['email', 'first_name', 'last_name']),
      color: null,
    };
  }

  return user;
};

export const shareUser = (share) => ({
  ...share,
  ...share.user,
});

export const widthMixin = css`
  width: 77rem;

  @media screen and (min-width: 1024px) {
    width: 95rem;
  }
`;
