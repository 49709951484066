import { defineMessages } from 'react-intl';

export default defineMessages({
  noResultsFilterTitle: {
    id: 'app.components.Jobs.Closed.EmptyState.noResultsFilterTitle',
    defaultMessage: 'No matching jobs found',
  },
  noResultsFilterSubtitle: {
    id: 'app.components.Jobs.Closed.EmptyState.noResultsFilterSubtitle',
    defaultMessage:
      'The combination of filters you used has no results. Clear all filters or try different search criteria.',
  },

  goToAllActiveJobsAction: {
    id: 'app.components.Jobs.Closed.EmptyState.goToAllActiveJobsAction',
    defaultMessage: 'Go to all active jobs',
  },
  postNewJob: {
    id: 'app.components.Jobs.Closed.EmptyState.postNewJob',
    defaultMessage: 'Post new job',
  },
  noClosedJobsTitle: {
    id: 'app.components.Jobs.Closed.EmptyState.noClosedJobsTitle',
    defaultMessage: 'You don’t have any closed jobs',
  },
  differentUserNoClosedJobsTitle: {
    id: 'app.components.Jobs.Closed.differentUserNoClosedJobsTitle',
    defaultMessage: "{first_name} doesn't have any draft jobs",
  },
  companyNoClosedJobsTitle: {
    id: 'app.components.Jobs.Closed.companyNoClosedJobsTitle',
    defaultMessage: "Your company doesn't have any closed jobs",
  },
  switchToEveryoneAction: {
    id: 'app.components.Jobs.Closed.EmptyState.switchToEveryoneAction',
    defaultMessage: 'Switch to Everyone',
  },
  goToActiveJobsOrPostNewJobSubtitle: {
    id:
      'app.components.Jobs.Closed.EmptyState.goToActiveJobsOrPostNewJobSubtitle',
    defaultMessage: 'Go to "Active" jobs or post a new job now.',
  },
  switchToAnotherUserSubtitle: {
    id: 'app.components.Jobs.Closed.EmptyState.switchToAnotherUserSubtitle',
    defaultMessage: 'Switch to another user or post a new job now.',
  },
  resetFilters: {
    id: 'app.components.Jobs.Closed.EmptyState.resetFilters',
    defaultMessage: 'Clear all filters',
  },
});
