import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { FormattedMessage } from 'react-intl';
import { get, find, meanBy } from 'lodash';

import { Icon } from 'components/elements/icon';
import { Tooltip } from 'components/overlay/Tooltip';
import { Colors } from 'components/utils/styles/ui';
import { CardSection } from 'components/misc/Card';

import i18n from '../utils/i18n';
import * as styled from '../styles/ratings';
import { isAffiliated as isAffiliatedFn } from '../utils/helpers';

export class Ratings extends Component {
  constructor(props) {
    super(props);
    this.state = { edit: false };
  }

  onRateCompany = (rating) => {
    const { rateCompany } = this.props;

    rateCompany(rating);
    this.setState({ edit: false });
  };

  formatRating(rating) {
    return (Number(rating || null) / 2.0).toFixed(1);
  }

  render() {
    const { company, currentUser } = this.props;
    const { edit } = this.state;

    const ratingOverall = meanBy(company.ratings, 'rating');
    const ratingYour = get(
      find(company.ratings, { user_id: get(currentUser, 'id') }),
      'rating'
    );
    const isAffiliated = isAffiliatedFn(company);

    return (
      <>
        <styled.Card bordered isSimple data-manual="profile.ratings">
          <CardSection title={<FormattedMessage {...i18n.ratingCardTitle} />}>
            <styled.Paragraph>
              <FormattedMessage {...i18n.ratingOverall} />
            </styled.Paragraph>
            <styled.RatingWrapper>
              <Tooltip
                content={
                  <FormattedMessage
                    {...i18n.ratedOverall}
                    values={{ rating: this.formatRating(ratingOverall) }}
                  />
                }
              >
                <Rating
                  readonly
                  stop={10}
                  step={2}
                  initialRating={Math.round(ratingOverall)}
                  emptySymbol={
                    <Icon
                      size="large"
                      icon="StarFill"
                      color={Colors.inputOutline}
                    />
                  }
                  fullSymbol={
                    <Icon size="large" icon="StarFill" color={Colors.yellow} />
                  }
                />
              </Tooltip>
              <styled.RatingCount>
                <FormattedMessage
                  {...i18n.ratingCount}
                  values={{ count: company.ratings.length }}
                />
              </styled.RatingCount>
            </styled.RatingWrapper>

            {isAffiliated && (
              <styled.Paragraph>
                <FormattedMessage
                  {...i18n.ratingYour}
                  values={{ rating: !edit && this.formatRating(ratingYour) }}
                />
                {!edit && ratingYour && (
                  <styled.ButtonEdit
                    color="primary"
                    onClick={() => this.setState({ edit: true })}
                    type="blank"
                  >
                    <FormattedMessage {...i18n.ratingEdit} />
                  </styled.ButtonEdit>
                )}
              </styled.Paragraph>
            )}
            {isAffiliated && (!ratingYour || edit) && (
              <styled.RatingWrapper>
                <Rating
                  stop={10}
                  step={2}
                  fractions={2}
                  initialRating={ratingYour}
                  onChange={this.onRateCompany}
                  emptySymbol={
                    <Icon
                      size="large"
                      icon="StarFill"
                      color={Colors.inputOutline}
                    />
                  }
                  fullSymbol={
                    <Icon size="large" icon="StarFill" color={Colors.yellow} />
                  }
                />
              </styled.RatingWrapper>
            )}
          </CardSection>
        </styled.Card>
      </>
    );
  }
}

Ratings.propTypes = {
  company: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  rateCompany: PropTypes.func,
};
