import { createSelector } from 'reselect';

const rootSelector = createSelector(
  (state) => state.get('jobs'),
  (substate) => substate
);

const jobsCountersSelector = createSelector(
  rootSelector,
  (substate) => substate.counters
);

export { jobsCountersSelector };
