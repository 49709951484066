import { defineMessages } from 'react-intl';

export default defineMessages({
  pageTitleJobs: {
    id: 'app.container.Jobs.ContextHeader.jobs',
    defaultMessage: "Your company's jobs ",
  },
  pageTitleCandidates: {
    id: 'app.container.Jobs.ContextHeader.candidates',
    defaultMessage: 'All candidates received',
  },

  badgeActive: {
    id: 'app.container.Jobs.ContextHeader.badgeActive',
    defaultMessage: 'Active',
  },
  badgeDraft: {
    id: 'app.container.Jobs.ContextHeader.badgeDraft',
    defaultMessage: 'Draft',
  },
  badgeClosed: {
    id: 'app.container.Jobs.ContextHeader.badgeClosed',
    defaultMessage: 'Closed',
  },
  badgeImported: {
    id: 'app.container.Jobs.ContextHeader.badgeImported',
    defaultMessage: 'Imported',
  },

  helpMessageAllCompanyJobs: {
    id: 'app.container.Jobs.ContextHeader.helpMessageAllCompanyJobs',
    defaultMessage: 'Here you can see all jobs posted by your company.',
  },
  helpMessageImportedJobs: {
    id: 'app.container.Jobs.ContextHeader.helpMessageImportedJobs',
    defaultMessage:
      'All jobs that have been imported successfully from your ATS.',
  },
  helpMessageCompanyDraftJobs: {
    id: 'app.container.Jobs.ContextHeader.helpMessageCompanyDraftJobs',
    defaultMessage: 'Here you can see all jobs that you have saved as drafts.',
  },
  helpMessageCompanyClosedJobs: {
    id: 'app.container.Jobs.ContextHeader.helpMessageCompanyClosedJobs',
    defaultMessage: 'Here you can see all closed jobs.',
  },
  helpMessageAllCandidates: {
    id: 'app.container.Jobs.ContextHeader.helpMessageAllCandidates',
    defaultMessage:
      'Candidates received on jobs that your company has shared with vendors.',
  },
  postNewJob: {
    id: 'app.container.Jobs.ContextHeader.postNewJob',
    defaultMessage: 'Post new job',
  },
  buttonUpgradePro: {
    id: 'app.container.Jobs.ContextHeader.buttonUpgradePro',
    defaultMessage: 'Upgrade to Pro',
  },

  helpLinkJobs: {
    id: 'app.container.Jobs.ContextHeader.helpLinkJobs',
    defaultMessage: 'Learn more about company jobs',
  },
  helpLinkDraftJobs: {
    id: 'app.container.Jobs.ContextHeader.helpLinkDraftJobs',
    defaultMessage: 'Learn more about draft jobs',
  },
  helpLinkClosedJobs: {
    id: 'app.container.Jobs.ContextHeader.helpLinkClosedJobs',
    defaultMessage: 'Learn more about closed jobs',
  },
  helpLinkImportedJobs: {
    id: 'app.container.Jobs.ContextHeader.helpLinkImportedJobs',
    defaultMessage: 'Learn more about imported jobs',
  },
  helpLinkCandidates: {
    id: 'app.container.Jobs.ContextHeader.helpLinkCandidates',
    defaultMessage: 'Learn more about candidates received',
  },

  menuJobsImported: {
    id: 'app.container.Jobs.ContextHeader.jobsImported',
    defaultMessage: 'Imported ({counter, number, integer})',
  },
  menuJobsArchived: {
    id: 'app.container.Jobs.ContextHeader.jobsArchived',
    defaultMessage: 'Archived ({counter, number, integer})',
  },
});
