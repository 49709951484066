import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { EmptyState } from 'components/misc/EmptyState';
import { Button } from 'components/elements/button';
import { SmartLink } from 'components/navigation/SmartLink';

import { isEmpty } from 'lodash';
import { userRole } from 'utils/authorization/utils/auth';

import { switchToEveryoneAction } from '../../EmptyStatesShared/sharedActions';
import i18n from './utils/i18n';

const NoResultsQuery = ({
  filtered,
  onChangeFilters,
  userType,
  differentUserFirstName,
}) => {
  const switchToAllFilter = [{ id: 'status', value: 'active' }];

  const bubbleFilterStatus = () => {
    const filterStatusArray = filtered.filter(
      ({ id, value }) =>
        id === 'status' && ['open', 'open_and_paused', 'paused'].includes(value)
    );

    if (isEmpty(filterStatusArray)) {
      return 'all';
    }
    return filterStatusArray[0].value;
  };

  const filterStatus = bubbleFilterStatus();
  const allOrOpenAndPausedStatus =
    filterStatus === 'all' || filterStatus === 'open_and_paused';
  const openOrPausedStatus =
    filterStatus === 'open' || filterStatus === 'paused';

  const createTitle = () => {
    const ownerTitle = () => {
      if (allOrOpenAndPausedStatus) {
        return i18n.noActiveJobsTitle;
      }
      if (filterStatus === 'open') {
        return i18n.noOpenJobsTitle;
      }
      return i18n.noPausedJobsTitle;
    };

    const differentUserTitle = () => {
      if (allOrOpenAndPausedStatus) {
        return i18n.differentUserNoActiveJobsTitle;
      }
      if (filterStatus === 'open') {
        return i18n.differentUserNoOpenJobsTitle;
      }
      return i18n.differentUserNoPausedJobsTitle;
    };

    const companyTitle = () => {
      if (allOrOpenAndPausedStatus) {
        return i18n.noActiveCompanyJobsTitle;
      }
      if (filterStatus === 'open') {
        return i18n.noOpenCompanyJobsTitle;
      }
      return i18n.noPausedCompanyJobsTitle;
    };

    switch (userType) {
      case 'owner':
        return ownerTitle();
      case 'differentUser':
        return differentUserTitle();
      default:
        return companyTitle();
    }
  };

  const createContent = () => {
    const isOwnerOrIsDifferentUser =
      userType === 'owner' || userType === 'differentUser';
    const title = createTitle();
    let subTitle;

    const actions = [
      <SmartLink to="/job/new">
        <Button color="primary">
          <FormattedMessage {...i18n.postNewJob} />
        </Button>
      </SmartLink>,
    ];

    const switchToAllAction = (
      <Button
        onClick={() => onChangeFilters(switchToAllFilter)}
        color="primary"
      >
        <FormattedMessage {...i18n.switchToAllAction} />
      </Button>
    );

    if (allOrOpenAndPausedStatus && isOwnerOrIsDifferentUser) {
      switch (userRole()) {
        case 'member':
          subTitle = i18n.postNewJobSubtitle;
          break;
        default:
          subTitle = i18n.switchToAnotherUserSubtitle;
          actions.unshift(switchToEveryoneAction(onChangeFilters));
      }

      return { title, subTitle, actions };
    }
    if (openOrPausedStatus && isOwnerOrIsDifferentUser) {
      subTitle = i18n.switchStatusSubtitle;
      actions.unshift(switchToAllAction);

      return { title, subTitle, actions };
    }
    if (allOrOpenAndPausedStatus && userType === 'everyone') {
      subTitle = i18n.noCompanyJobsSubtitle;

      return { title, subTitle, actions };
    }

    subTitle = i18n.switchStatusSubtitle;
    actions.unshift(switchToAllAction);

    return { title, subTitle, actions };
  };

  const { title, subTitle, actions } = createContent();
  return (
    <EmptyState
      title={
        <FormattedMessage
          {...title}
          values={{ first_name: differentUserFirstName }}
        />
      }
      actions={actions}
      illustration="clipboard"
    >
      <FormattedMessage {...subTitle} />
    </EmptyState>
  );
};

const NoResultsFilter = ({ resetFilter, onChangeFilters, userType }) => {
  const actions = [
    <Button color="primary" onClick={resetFilter}>
      <FormattedMessage {...i18n.resetFilters} />
    </Button>,
  ];

  if (userType !== 'everyone' && userRole() !== 'member') {
    actions.push(switchToEveryoneAction(onChangeFilters));
  }

  return (
    <EmptyState
      actions={actions}
      title={<FormattedMessage {...i18n.noResultsFilterTitle} />}
    >
      <FormattedMessage {...i18n.noResultsFilterSubtitle} />
    </EmptyState>
  );
};

NoResultsFilter.propTypes = {
  resetFilter: PropTypes.func.isRequired,
  onChangeFilters: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

NoResultsQuery.propTypes = {
  filtered: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    })
  ),
  userType: PropTypes.string.isRequired,
  differentUserFirstName: PropTypes.string,
  onChangeFilters: PropTypes.func.isRequired,
};

export { NoResultsQuery, NoResultsFilter };
