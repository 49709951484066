import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { isArray, noop } from 'lodash';
import { ThemeProvider } from 'styled-components';

import { LanguageProvider } from 'containers/LanguageProvider';
import { translationMessages } from 'i18n';
import { Provider } from 'react-redux';
import { store } from 'store';
import theme from 'themes';

import { Overlay } from 'components/structure/overlay/overlay';
import { GlobalScrollLock } from 'components/structure/overlay/styles/overlay';
import { Button } from 'components/elements/button';
import { Icon } from 'components/elements/icon';
import { toFormattedMessage } from 'components/utils/text';

import * as styled from './styles/confirm';

/* eslint react/no-danger: 0 */
export class ConfirmComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { displayed: true };
  }

  onLeave = () => {
    this.setState({ displayed: false }, () => {
      this.confirmElement.remove();
    });
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;
    onConfirm(this.childElement);
    this.onLeave();
  };

  handleSecondary = () => {
    const { onSecondary } = this.props;
    onSecondary(this.childElement);
    this.onLeave();
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel(this.childElement);
    this.onLeave();
  };

  handleClose = () => {
    const { onCancel, cancelOnClose } = this.props;
    if (cancelOnClose) {
      onCancel();
    }
    this.onLeave();
  };

  /* eslint-disable react/no-array-index-key */
  renderMessage = () => {
    const { intl, message } = this.props;

    if (isArray(message)) {
      return message.map((msg, i) => <p key={`msg-${i}`}>{msg}</p>);
    }

    return <styled.Message>{toFormattedMessage(message, intl)}</styled.Message>;
  };

  render() {
    const {
      width,
      className,
      title,
      message,
      children,
      confirmLabel,
      secondaryLabel,
      cancelLabel,
      titleCentered,
      titleColor,
      confirmColor,
      secondaryColor,
      cancelColor,
      confirmIcon,
      cancelIcon,
      buttonFullWidth,
      withClose,
      intl,
    } = this.props;

    const { displayed } = this.state;

    return (
      <div
        ref={(confirm) => {
          this.confirmElement = confirm;
        }}
      >
        {displayed && <GlobalScrollLock />}
        <Overlay
          closeOverlay={this.handleClose}
          noGlobalScrollLock
          noClose={!(withClose || cancelLabel)}
          className={className}
        >
          <styled.Confirm width={width}>
            {title && (
              <styled.Title color={titleColor} center={titleCentered}>
                {toFormattedMessage(title, intl)}
              </styled.Title>
            )}
            {message && this.renderMessage()}
            {children && (
              <styled.Message>
                {React.Children.map(children, (child) =>
                  React.cloneElement(child, {
                    ref: (childElement) => {
                      this.childElement = childElement;
                    },
                  })
                )}
              </styled.Message>
            )}
            <styled.ButtonGroup buttonFullWidth={buttonFullWidth}>
              {cancelLabel && (
                <Button
                  type="outline"
                  color={cancelColor}
                  onClick={this.handleCancel}
                >
                  {cancelIcon && <Icon icon={cancelIcon} color={cancelColor} />}
                  {toFormattedMessage(cancelLabel, intl)}
                </Button>
              )}
              <div>
                {secondaryLabel && (
                  <Button
                    type="outline"
                    color={secondaryColor}
                    onClick={this.handleSecondary}
                  >
                    {toFormattedMessage(secondaryLabel, intl)}
                  </Button>
                )}
                {confirmLabel && (
                  <Button
                    color={confirmColor}
                    onClick={this.handleConfirm}
                    fullWidth={buttonFullWidth}
                    dataTest="confirmModal.confirm"
                  >
                    {confirmIcon && (
                      <Icon icon={confirmIcon} size="small" color="white" />
                    )}
                    {toFormattedMessage(confirmLabel, intl)}
                  </Button>
                )}
              </div>
            </styled.ButtonGroup>
          </styled.Confirm>
        </Overlay>
      </div>
    );
  }
}

ConfirmComponent.propTypes = {
  width: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  children: PropTypes.node,
  confirmLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  secondaryLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  cancelLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  titleCentered: PropTypes.bool,
  titleColor: PropTypes.string,
  confirmColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  cancelColor: PropTypes.string,
  confirmIcon: PropTypes.node,
  cancelIcon: PropTypes.node,
  onConfirm: PropTypes.func,
  onSecondary: PropTypes.func,
  onCancel: PropTypes.func,
  buttonFullWidth: PropTypes.bool,
  withClose: PropTypes.bool,
  cancelOnClose: PropTypes.bool,
  intl: intlShape,
};

ConfirmComponent.defaultProps = {
  width: null,
  title: null,
  message: null,
  children: null,
  confirmLabel: null,
  secondaryLabel: null,
  cancelLabel: null,
  titleCentered: false,
  titleColor: 'primaryDark',
  confirmColor: 'primaryDark',
  secondaryColor: 'primaryDark',
  cancelColor: 'mono',
  confirmIcon: null,
  cancelIcon: null,
  onConfirm: noop,
  onSecondary: noop,
  onCancel: noop,
  cancelOnClose: true,
  buttonFullWidth: false,
};

function ConfirmProvider({ children }) {
  return (
    <Provider store={store}>
      <LanguageProvider messages={translationMessages}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </LanguageProvider>
    </Provider>
  );
}

ConfirmProvider.propTypes = {
  children: PropTypes.node,
};

const ConfirmWithIntl = injectIntl(ConfirmComponent);

export const Confirm = (props) => (
  <ConfirmProvider>
    <ConfirmWithIntl {...props} />
  </ConfirmProvider>
);
