import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';

import { LocationField } from './styles/supplierHub';

export class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.inputRef = null;
    this.autocomplete = null;
  }

  componentDidUpdate() {
    if (this.inputRef && !this.autocomplete && window.google) {
      const options = {
        types: ['geocode'],
        componentRestrictions: { country: 'us' },
      };
      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.inputRef,
        options
      );
      this.autocomplete.addListener('place_changed', this.onChangePlace);
    }
  }

  onChangeLocation = (location) => {
    const { onResetLocation, onChange } = this.props;
    if (isEmpty(location)) {
      onResetLocation();
    }
    onChange(location);
  };

  onChangePlace = () => {
    const { onChangeLocation, onChange } = this.props;
    const place = this.autocomplete.getPlace();

    onChangeLocation(place);
    onChange(place.formatted_address);
  };

  render() {
    return (
      <LocationField
        {...this.props}
        onInnerRef={(ref) => {
          this.inputRef = ref;
        }}
        onChange={this.onChangeLocation}
      />
    );
  }
}

LocationInput.propTypes = {
  onChangeLocation: PropTypes.func.isRequired,
  onResetLocation: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
