import equal from 'fast-deep-equal';
import { sortBy } from 'lodash';

/* eslint no-cond-assign: 0 */
/* eslint eqeqeq: 0 */
const alphanumComparison = function alphanumComparison(a, b) {
  function chunkify(t) {
    const tz = [];
    let x = 0;
    let y = -1;
    let n = 0;
    let i;
    let j;

    while ((i = (j = t.charAt(x)).charCodeAt(0))) {
      x += 1;
      const m = i === 46 || (i >= 48 && i <= 57);
      if (m !== n) {
        tz[(y += 1)] = '';
        n = m;
      }
      tz[y] += j;
    }
    return tz;
  }

  const aa = chunkify(a);
  const bb = chunkify(b);
  let x;

  for (x = 0; aa[x] && bb[x]; x += 1) {
    if (aa[x] !== bb[x]) {
      const c = Number(aa[x]);
      const d = Number(bb[x]);
      if (c == aa[x] && d == bb[x]) {
        return c - d;
      }
      return aa[x] > bb[x] ? 1 : -1;
    }
  }
  return aa.length - bb.length;
};

/**
 * @function alphanumSort
 * @param {Array} arr
 * @return {Array} sorted array of strings
 */
export const alphanumSort = (arr) => arr.sort(alphanumComparison);

/**
 * @function moveArrayItem
 * @param {Array} originalArray
 * @param {number} oldIndex
 * @param {number} newIndex
 * @return {Array} updatedArray
 */
export const moveArrayItem = function moveArrayItem(
  originalArray,
  oldIndex,
  newIndex
) {
  const arr = originalArray.slice(0);
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while ((k -= 1)) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

/**
 * @function sortedFastEqual
 * @param {Array} a
 * @param {Array} b
 * @param {string} by
 * @return {boolean} if arrays of objects are equal, disregarding order of objects
 */
export const sortedFastEqual = function sortedFastEqual(a, b, by) {
  return equal(sortBy(a, by), sortBy(b, by));
};
