/*
 *
 * Jobs constants
 *
 */

export const HOMEPAGE_NEW_JOB_FORM_REQUEST =
  'gustav/jobPosting/HOMEPAGE_NEW_JOB_FORM_REQUEST';
export const HOMEPAGE_NEW_JOB_FORM_SUCCESS =
  'gustav/jobPosting/HOMEPAGE_NEW_JOB_FORM_SUCCESS';
export const HOMEPAGE_NEW_JOB_FORM_ERROR =
  'gustav/jobPosting/HOMEPAGE_NEW_JOB_FORM_ERROR';

export const HOMEPAGE_EDIT_JOB_FORM_REQUEST =
  'gustav/jobPosting/HOMEPAGE_EDIT_JOB_FORM_REQUEST';
export const HOMEPAGE_EDIT_JOB_FORM_SUCCESS =
  'gustav/jobPosting/HOMEPAGE_EDIT_JOB_FORM_SUCCESS';
export const HOMEPAGE_EDIT_JOB_FORM_ERROR =
  'gustav/jobPosting/HOMEPAGE_EDIT_JOB_FORM_ERROR';

export const GET_EDIT_JOB_REQUEST = 'gustav/jobPosting/GET_EDIT_JOB_REQUEST';
export const GET_EDIT_JOB_SUCCESS = 'gustav/jobPosting/GET_EDIT_JOB_SUCCESS';
export const GET_EDIT_JOB_ERROR = 'gustav/jobPosting/GET_EDIT_JOB_ERROR';
