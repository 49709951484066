import styled, { keyframes, css } from 'styled-components';

import { Spinner } from 'images/generic-spinner';

import { Icon } from 'components/elements/icon';

import { Avatar as AvatarBase } from 'components/visual/avatar/avatar';

import { BOX_SIZES } from 'components/visual/avatar/constants/avatar';

import { Colors } from 'components/utils/styles/ui';

export const Input = styled.div`
  position: relative;
  width: auto;
`;

const roundedDropzoneMixin = css`
  margin-bottom: ${({ withLabel }) => (withLabel ? '1rem' : 0)};
  padding: 0 2rem;
`;

export const DropzoneBox = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 1.2rem;
  width: 100%;
  ${({ shape }) => (shape === 'rounded' ? roundedDropzoneMixin : null)}

  &:focus,
  &:hover {
    outline: none;
  }
`;

export const Avatar = styled(AvatarBase)``;

const radiusFor = function radiusFor(shape) {
  switch (shape) {
    case 'rounded':
      return '50%';
    case 'square-rounded':
      return '0.6rem';
    default:
      return 0;
  }
};

export const ImageInput = styled.div`
  ${({ isOpaque, theme }) =>
    isOpaque ? '' : `background-color: ${theme.colors.primary}`};
  border-radius: ${({ shape }) => radiusFor(shape)};
  cursor: pointer;
  height: ${({ size = 'x3large' }) => BOX_SIZES[size]}rem;
  position: absolute;
  top: 0;
  width: ${({ size = 'x3large' }) => BOX_SIZES[size]}rem;
  display: flex;
  align-items: center;

  ${DropzoneBox} {
    ${({ isOpaque }) =>
      isOpaque
        ? `
      opacity: 0;

      &:hover {
        opacity: 1;
      }
    `
        : `
      opacity: 1;
    `}
  }
`;

export const Detail = styled.span`
  padding: 0.4rem;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  border-radius: 0.3rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: ${Colors.white};
  margin-top: 0.9rem;
  text-align: center;
  width: 100%;
`;

const AnimateDot = keyframes`
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const CameraIcon = styled(Icon)`
  width: 4rem;
  padding: 0.2rem;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
  border-radius: 0.3rem;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const LoadingLogo = styled(Spinner)`
  width: 100%;

  & > .spinner {
    text-align: center;

    & > div {
      width: 25%;
      height: 0;
      padding-bottom: 25%;
      background-color: ${({ color }) => Colors[color]};

      border-radius: 100%;
      display: inline-block;
      -webkit-animation: ${AnimateDot} 1.4s infinite ease-in-out both;
      animation: ${AnimateDot} 1.4s infinite ease-in-out both;
    }

    & > .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    & > .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
`;
