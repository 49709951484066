import PropTypes from 'prop-types';
import React from 'react';

import * as styled from './styles/fieldset';

import { Replicate } from '../replicate/replicate';

export const ReplicateFieldset = function ReplicateFieldset(props) {
  const {
    className,
    label,
    inputDescription,
    includeIndicator,
    buttonLabel,
    noLabelCount,
    noSeparator,
    deleteButtonType,
    deleteButtonColor,
    required,
    shouldValidate,
    children,
    defaultValue,
    objValues,
    itemsLimit,
    minItems,
    fn,
    onDeleteFn,
  } = props;

  const description = inputDescription ? (
    <styled.DescriptionLabel
      value={inputDescription}
      required={false}
      includeIndicator={false}
    />
  ) : null;

  const formLabelMargin = inputDescription ? '0 0 1rem' : '0 0 2rem';

  return (
    <styled.Fieldset className={className}>
      <styled.FormLabel
        margin={formLabelMargin}
        value={label}
        required={required}
        shouldValidate={shouldValidate}
        includeIndicator={includeIndicator}
      />

      {description}

      <Replicate
        noLabelCount={noLabelCount}
        noSeparator={noSeparator}
        deleteButtonType={deleteButtonType}
        deleteIconColor={deleteButtonColor}
        buttonLabel={buttonLabel}
        required={required}
        shouldValidate={shouldValidate}
        defaultValue={defaultValue}
        objValues={objValues}
        itemsLimit={itemsLimit}
        minItems={minItems}
        onChange={(valueStates) => fn(valueStates)}
        onDelete={onDeleteFn}
      >
        {children}
      </Replicate>
    </styled.Fieldset>
  );
};

ReplicateFieldset.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.shape({
      index: PropTypes.number,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
    }),
  }),
  objValues: PropTypes.shape({
    key: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        value: PropTypes.oneOfType([
          PropTypes.array,
          PropTypes.object,
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      })
    ),
  }),
  className: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inputDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool,
  includeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  noLabelCount: PropTypes.bool,
  noSeparator: PropTypes.bool,
  deleteButtonType: PropTypes.string,
  deleteButtonColor: PropTypes.string,
  itemsLimit: PropTypes.number,
  minItems: PropTypes.number,
  fn: PropTypes.func,
  onDeleteFn: PropTypes.func,
};

ReplicateFieldset.defaultProps = {
  includeIndicator: true,
  buttonLabel: 'add another',
};
