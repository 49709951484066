import styled, { css } from 'styled-components';
import { darken } from 'polished';

import { Colors } from 'components/utils/styles/ui';
import { Icon as IconBase } from 'components/elements/icon';

export const Wrapper = styled.div`
  position: relative;
`;

const bubbleOpenMixin = css`
  &,
  &:hover {
    background-color: ${Colors.tealLighter};
  }
`;

const bubbleActiveMixin = css`
  & {
    background-color: ${Colors.tealDark};
    color: ${Colors.white};
  }

  &:hover {
    background-color: ${darken(0.05, Colors.tealDark)};
    color: ${Colors.white};
  }
`;

export const Bubble = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  max-width: 30rem;
  padding: 0 1.6rem;
  border: 1px solid ${Colors.outline};
  border-radius: 1.8rem;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${Colors.greyLight};
  }

  ${({ open }) => open && bubbleOpenMixin}
  ${({ active }) => active && bubbleActiveMixin}
`;

export const Icon = styled(IconBase).attrs(({ active }) => ({
  color: active ? Colors.white : Colors.text,
}))`
  width: 1.4rem;
  margin-right: 0.5rem;
`;

export const Value = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Clear = styled.div`
  display: flex;
  flex: 0 0 1.6rem;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 0.4rem;
  transform: translateX(0.6rem);
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.white};
`;

export const Modal = styled.div`
  position: absolute;
  z-index: 1;
  top: 4.3rem;
  left: 0;
  width: ${({ width }) => width || 'auto'};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.4rem;
  padding: 0 1.4rem;
  background-color: ${Colors.tealDark};
  border-radius: 0.3rem 0.3rem 0 0;
  color: ${Colors.white};
  font-weight: 600;
`;

export const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${Colors.teal};
`;

export const Content = styled.div`
  border-radius: 0 0 0.3rem 0.3rem;
  border: 1px solid ${Colors.outline};
  border-top: none;
  padding: 1.4rem;
  background-color: ${Colors.white};
`;

export const CounterCircle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  border-radius: 50%;
  background-color: ${Colors.red};
  color: ${Colors.white};
  font-size: 1.1rem;
`;
