import { defineMessages } from 'react-intl';

export default defineMessages({
  trialDays: {
    id: 'app.containers.Sidebar.trialDays',
    defaultMessage:
      '{days, plural, =0 {0 days} one {1 day} other {{days} days}} of free trial left',
  },
  subscriptionJobsLeft: {
    id: 'app.containers.Sidebar.subscriptionJobsLeft',
    defaultMessage: '{jobs}/{limit} jobs left to share',
  },
  subscriptionHotlistLeft: {
    id: 'app.containers.Sidebar.subscriptionHotlistLeft',
    defaultMessage: 'Hotlist credits: unlimited',
  },
  subscriptionHotlistTooltip: {
    id: 'app.containers.Sidebar.subscriptionHotlistTooltip',
    defaultMessage:
      'Hotlist credits will be needed to request \ncandidates listed by vendors on the \nGlobal Hotlist. You have unlimited \ncredits while the Hotlist is in Beta.',
  },
  subscriptionUpgrade: {
    id: 'app.containers.Sidebar.subscriptionUpgrade',
    defaultMessage: 'Upgrade your subscription to Pro',
  },
  beamerTooptip: {
    id: 'app.containers.Sidebar.beamerTooltip',
    defaultMessage: 'What’s new?',
  },
});
