import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'components/overlay/Tooltip';

import { StyleButton } from './StyleButton';

export const StyleControl = ({ type, onToggle, active }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip active={showTooltip} position="S" color="dark" content={type.text}>
      <StyleButton
        key={type.label}
        active={active}
        icon={type.icon}
        onToggle={onToggle}
        style={type.style}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
    </Tooltip>
  );
};

StyleControl.propTypes = {
  type: PropTypes.object,
  onToggle: PropTypes.func,
  active: PropTypes.bool,
};
