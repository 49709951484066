import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconBase } from './iconBase';

const UserArrowLeftFillSvg = styled(IconBase)`
  & path:first-child {
    stroke: none !important;
  }
`;

export const UserArrowLeftFill = (props) => {
  const { size, color, className } = props;
  return (
    <UserArrowLeftFillSvg className={className} viewBox={24} size={size}>
      <path
        d="M15.4516 14.7979C18.1034 15.6556 20.3446 17.4634 21.7441 19.8737C21.81 19.9877 21.8448 20.1171 21.8449 20.2488C21.845 20.3805 21.8104 20.5099 21.7446 20.624C21.6788 20.7381 21.5841 20.8329 21.4701 20.8988C21.356 20.9647 21.2266 20.9993 21.0949 20.9993L2.90525 20.9991C2.77354 20.9991 2.64415 20.9645 2.5301 20.8986C2.41605 20.8327 2.32136 20.7379 2.25556 20.6238C2.18976 20.5098 2.15516 20.3803 2.15526 20.2486C2.15535 20.1169 2.19013 19.9876 2.2561 19.8736C3.65555 17.4633 5.89666 15.6555 8.54847 14.7979C7.78444 14.3435 7.11773 13.7426 6.58664 13.0297C6.05554 12.3168 5.67054 11.5061 5.45374 10.644C5.23694 9.78186 5.19261 8.88541 5.32331 8.00612C5.45401 7.12684 5.75716 6.28204 6.21535 5.52027C6.67353 4.7585 7.27771 4.09476 7.99319 3.5672C8.70866 3.03964 9.52133 2.65864 10.3845 2.4461C11.2477 2.23356 12.1443 2.19367 13.0229 2.32872C13.0229 2.32872 12 4.99394 13.5 7.50003C15 10.0061 19 10 19 10C18.5 12.5 16.6787 14.0695 15.4516 14.7979Z"
        fill={color}
        stroke="none"
      />
      <path
        d="M16.5 5H21"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7L15 5L17 3"
        stroke={color}
        fill={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </UserArrowLeftFillSvg>
  );
};

UserArrowLeftFill.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
};
