import React from 'react';
import PropTypes from 'prop-types';

import {
  map,
  split,
  isEmpty,
  isString,
  flatten,
  trim,
  lowerCase,
} from 'lodash';

/**
 * @function formatTextWithMembersWithin
 * @param {object} property
 * @param {string} textToFormat
 * @param {string} parentKey
 * @param {node} ParentEl
 * @param {node} HighlightEl
 * @return {object} jsx element
 */
export const formatTextWithMembersWithin = function formatTextWithMembersWithin({
  textToFormat,
  parentKey,
  ParentEl,
  HighlightEl,
}) {
  const formattedStrings = map(split(textToFormat, ' '), (word, index) => {
    const key = `${parentKey}-mention-${index}`;
    if (word.startsWith('@')) {
      const hasExtraChars = word.match(/(,|\.|:|\?|;|'|")$/gi) || [];

      if (!isEmpty(hasExtraChars)) {
        const wordWithoutChar = (
          <HighlightEl key={key}>
            {word.replace(hasExtraChars[0], '')}
          </HighlightEl>
        );

        return [wordWithoutChar, `${hasExtraChars[0]} `];
      }

      return <HighlightEl key={key}>{word} </HighlightEl>;
    }

    return `${word} `;
  });

  return <ParentEl>{flatten(formattedStrings)}</ParentEl>;
};

formatTextWithMembersWithin.propTypes = {
  textToFormat: PropTypes.string,
  parentKey: PropTypes.string,
  ParentEl: PropTypes.node,
  HighlightEl: PropTypes.node,
};

/**
 * @function isEmpty
 * @param {string} value
 * @return {boolean}
 */
export const isEmptyString = function isEmptyString(value) {
  return !value || value.trim() === '';
};

/**
 * @function formatBreakline
 * @param {string} text
 * @return {object} every lien is converted into a <div>
 */
/* eslint-disable react/no-array-index-key */
export const formatBreakline = function formatBreakline(text) {
  return (
    <>
      {text.split(/(?:\r\n|\r|\n)/g).map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </>
  );
};

/**
 * @function toProperCase
 * @param {string} text
 * @return {string}
 * @description
 * Capitalizes all words in the given text, replacing also big letters that are not on first position in the word with small letters.
 * Works also for Irish surnames like "O'Brien".
 *  Examples:
 * "sOmE text" => "Some Text"
 * "o'brien" => "O'Brien"
 */
export const toProperCase = function toProperCase(text) {
  if (text === null) {
    return null;
  }

  return text.toLowerCase().replace(/(?:^|\s|')\S/g, (a) => a.toUpperCase());
};

export const toFormattedMessage = function toFormattedMessage(
  text,
  intl,
  values = {}
) {
  if (React.isValidElement(text) || isString(text)) {
    return text;
  }

  return intl.formatMessage(text, values);
};

export const caseCompare = function caseCompare(text1, text2) {
  return lowerCase(trim(text1)) === lowerCase(trim(text2));
};
