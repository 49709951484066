import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';

import { companySelector } from 'containers/App/selectors';
import { PremiumFeatureScene } from 'containers/Settings/Integrations/PremiumFeature';

import { NoResultsQuery, NoResultsFilter } from './EmptyState';
import i18n from './utils/i18n';

import { ImportsList } from '../ImportsList/Loadable';

const IMPORTED_JOBS_FILTER = [
  { id: 'status', value: 'draft' },
  { id: 'imported', value: true },
  { id: 'archived', value: false },
  { id: 'ats_member_id', value: 'company' },
];

const ImportedComponent = (props) => {
  const { match, company, onFetchCounters } = props;
  const premiumFeatures = get(company, 'subscription_plan.features', {});

  if (!premiumFeatures.integrations) {
    return <PremiumFeatureScene />;
  }

  return (
    <ImportsList
      title={i18n.title}
      match={match}
      context="imported"
      defaultFiltered={IMPORTED_JOBS_FILTER}
      onFetchCounters={onFetchCounters}
      emptyStateDefault={NoResultsQuery}
      emptyStateFiltered={({ onFilteredChange }) =>
        NoResultsFilter({
          resetFilter: () => onFilteredChange(IMPORTED_JOBS_FILTER),
        })
      }
    />
  );
};

ImportedComponent.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  company: PropTypes.object,
  onFetchCounters: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  company: companySelector,
});

const withConnect = connect(mapStateToProps, null);

export const Imported = compose(withConnect)(ImportedComponent);
