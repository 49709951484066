import { defineMessages } from 'react-intl';

export default defineMessages({
  filterAll: {
    id: 'app.Requisitions.Filters.filterAll',
    defaultMessage: 'All ({count})',
  },
  filterOpen: {
    id: 'app.Requisitions.Filters.filterOpen',
    defaultMessage: 'Open ({count})',
  },
  filterPaused: {
    id: 'app.Requisitions.Filters.filterPaused',
    defaultMessage: 'Paused ({count})',
  },
});
