import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isString } from 'lodash';

import { Overlay } from 'components/structure/overlay/overlay';
import { ContentList } from 'components/lists/ContentList';

import * as styled from './styles/modalList';

export const ModalList = function ModalList(props) {
  const { title, items, onClose } = props;

  return (
    <Overlay closeOverlay={onClose}>
      <styled.ModalView>
        <styled.Wrapper>
          <styled.Title>
            {isString(title) ? (
              title
            ) : (
              <FormattedMessage {...title} values={{ count: items.length }} />
            )}
          </styled.Title>
          {items.length >= 8 ? (
            <styled.Scrollbars vertical>
              <ContentList isBordered items={items} />
            </styled.Scrollbars>
          ) : (
            <ContentList isBordered items={items} />
          )}
        </styled.Wrapper>
      </styled.ModalView>
    </Overlay>
  );
};

ModalList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};
