import React from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles';

export const StyleButton = ({ icon, active, onToggle, style, ...rest }) => {
  const handleToggle = (event) => {
    event.preventDefault();
    onToggle(style);
  };

  return (
    <styled.Button
      htmlType="button"
      color="inputOutline"
      {...(!active && { type: 'outline' })}
      onClick={(event) => handleToggle(event)}
      {...rest}
    >
      {icon && <styled.Icon icon={icon} size="small" />}
    </styled.Button>
  );
};

StyleButton.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string,
  onToggle: PropTypes.func,
  style: PropTypes.string,
};
