import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from 'components/elements/button';
import i18n from './utils/i18n';

export const switchToEveryoneAction = (onChangeFilters) => {
  const switchToEveryoneFilter = [{ id: 'ats_member_id', value: 'company' }];
  return (
    <Button
      onClick={() => onChangeFilters(switchToEveryoneFilter)}
      color="primary"
    >
      <FormattedMessage {...i18n.switchToEveryoneAction} />
    </Button>
  );
};
