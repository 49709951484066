import PropTypes from 'prop-types';
import React from 'react';

import { BOX_SIZES } from '../../visual/avatar/constants/avatar';

import { ImageInput } from '../image/image';

import * as styled from './styles/fieldset';

export const ImageFieldset = function ImageFieldset(props) {
  const {
    className,
    context,
    picture,
    initials,
    includeIndicator,
    shouldValidate,
    isImageLoading,
    fn,
    children,
  } = props;
  const { id, detail, required, size, shape, label } = context;
  const labelEl = label ? (
    <styled.FormLabel
      margin="0 0 1rem"
      includeIndicator={includeIndicator}
      value={label}
      required={required}
      elementToRefer={id}
    />
  ) : null;

  return (
    <styled.Fieldset className={className}>
      {labelEl}
      {children}

      <ImageInput
        isImageLoading={isImageLoading}
        id={id}
        detail={detail}
        picture={picture}
        initials={initials}
        size={size}
        shape={shape}
        required={required}
        shouldValidate={shouldValidate}
        onChange={(value) => fn(value)}
      />
    </styled.Fieldset>
  );
};

ImageFieldset.propTypes = {
  className: PropTypes.string,
  isImageLoading: PropTypes.bool,
  context: PropTypes.shape({
    id: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    size: PropTypes.oneOf(Object.keys(BOX_SIZES)),
    shape: PropTypes.oneOf(['square-rounded', 'rounded']),
    required: PropTypes.bool,
    label: PropTypes.string,
  }),
  picture: PropTypes.string,
  initials: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  includeIndicator: PropTypes.bool,
  shouldValidate: PropTypes.bool,
  fn: PropTypes.func,
  children: PropTypes.node,
};
