export const BOX_SIZES = {
  xsmall: 2,
  mmsmall: 2.4,
  msmall: 2.6,
  small: 3,
  smedium: 3.6,
  medium: 4.3,
  lmedium: 4.8,
  xslarge: 6,
  slarge: 6.4,
  large: 7.3,
  mlarge: 8.4,
  xlarge: 9.6,
  x2large: 11.4,
  x3large: 13.5,
};

export const SHAPES = {
  squareRounded: 'square-rounded',
  rounded: 'rounded',
};
