import React from 'react';
import PropTypes from 'prop-types';

import * as styled from './styles/flag';

// 127462 - Letter A emoji code
// 65 - Letter A ascii code
const OFFSET = 127462 - 65;

/*
  Flag emoji is composed of 2 emoji letters - corresponing to country ISO 3166 code
  i.e. USA flag is :U: emoji + :S: emoji

  https://www.google.com/get/noto/help/emoji/flags
*/
export const Flag = function Flag({ className, country, ...props }) {
  const first = country.toUpperCase().charCodeAt(0) + OFFSET;
  const last = country.toUpperCase().charCodeAt(1) + OFFSET;

  return (
    <styled.Flag
      className={className}
      first={first.toString(16)}
      last={last.toString(16)}
      {...props}
    />
  );
};

Flag.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string.isRequired,
};
