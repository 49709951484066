import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { clone } from 'lodash';

import * as styled from './styles/named-dropdown';

export class NamedDropdown extends Component {
  constructor(props) {
    super(props);

    const { namedropdown: objValue } = this.props;

    const { named, dropdown } = objValue;

    this.state = {
      namedDropdown: {
        named,
        dropdown,
      },
    };
  }

  inputChanged(field, value) {
    this.setState((state, { onChange }) => {
      const namedDropdown = clone(state.namedDropdown);

      namedDropdown[field] = value;

      onChange(namedDropdown);

      return { namedDropdown };
    });
  }

  render() {
    const {
      className,
      named,
      dropdown,
      separator,
      shouldValidate,
      required,
      namedropdown: objValue,
    } = this.props;

    const { named: value, dropdown: selected } = objValue;
    const { placeholder, inputType = 'text' } = named;
    const { options } = dropdown;

    return (
      <styled.Container className={className}>
        <styled.Input>
          <styled.Text
            value={value}
            placeholder={placeholder}
            required={required}
            shouldValidate={shouldValidate}
            inputType={inputType}
            onChange={(inputValue) => this.inputChanged('named', inputValue)}
          />

          {separator}

          <styled.Select
            selected={selected}
            options={options}
            required={required}
            shouldValidate={shouldValidate}
            onChange={(inputValue) => this.inputChanged('dropdown', inputValue)}
            selectTag="button"
          />
        </styled.Input>
      </styled.Container>
    );
  }
}

NamedDropdown.propTypes = {
  className: PropTypes.string,
  shouldValidate: PropTypes.bool,
  required: PropTypes.bool,
  named: PropTypes.object,
  dropdown: PropTypes.object,
  separator: PropTypes.object,
  namedropdown: PropTypes.shape({
    dropdown: PropTypes.string,
    named: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onChange: PropTypes.func,
};
